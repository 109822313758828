/* eslint-disable no-new */
/* eslint-disable max-lines */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-use-before-define */
import { getLetterFromIndex, isNum, isObj, isStr, merge } from 'editor/core/highcharts-editor';
import { snackBar } from 'editor/editors/highed.init';
import { cloneDeep } from 'lodash';
import { parseCSV } from 'pages/ChartEditorPage/utils/chartEditorDataHelper';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actionTypes from '../../../redux/actions/action-types';
import { setAction as setProjectConfigAction, updateDataAndConfigAction } from '../../../redux/actions/projectConfig';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import { stripHtml } from '../../../utils/helper';
import { hideContextMenuAction, setAction as setTableAction } from '../actions/tableEditor';
import { getTableSpecificConfig } from '../selectors/tableEditor';

// eslint-disable-next-line require-await
export async function loadSheet(options) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    Everviz.parseGoogleSpreadsheet(
      options,
      (data) => {
        return resolve(data);
      },
      (e) => {
        return reject(e);
      }
    );
  });
}

export function* setData(params) {
  let { dataType, options, cb, csv, itemDelimiter } = params.data;
  const projectConfig = yield select(getProjectConfig);

  if (csv && !projectConfig.rawImportedCSV) {
    yield put(setProjectConfigAction({ rawImportedCSV: csv }));
  }

  const parsedCSV = parseCSV(csv, itemDelimiter ?? null, null);
  const filteredData = parsedCSV.filter((a) => a.length !== 0);

  const config = yield select(getProjectConfig);
  const { defaultAssignOption } = yield select(getTableSpecificConfig);
  const newCustomizedOptions = cloneDeep(config.customizedOptions);
  let dataConfig = {};

  let data = [];

  if (dataType === 'google') {
    try {
      data = yield call(loadSheet, options);
      dataConfig = options;
    } catch (e) {
      throw new Error(e);
    }
  } else if (options) data = options;
  else data = filteredData;

  try {
    if (!newCustomizedOptions.columns) {
      // New table
      newCustomizedOptions.columns = Array(data[0].length).fill({
        type: 'data',
        visible: true
      });
    } else {
      // Check if the columns still line up correctly to the data source
      let dataIndexes = [];
      let dataColumns = newCustomizedOptions.columns.filter((column, i) => {
        if (column.type === 'data') {
          dataIndexes.push(i);
          return true;
        }
        return false;
      });

      dataIndexes = dataIndexes.sort(function (a, b) {
        return b - a;
      });

      if (dataColumns.length < data[0].length) {
        const diff = data[0].length - dataColumns.length;

        newCustomizedOptions.columns.push(
          ...Array(diff).fill({
            type: 'data',
            visible: true
          })
        );
      } else {
        const diff = dataColumns.length - data[0].length;
        for (let i = 0; i < diff; i++) {
          newCustomizedOptions.columns.splice(dataIndexes[i], 1);
        }
      }
    }

    let seriesAssigns = (newCustomizedOptions.columns ?? []).map((_, index) => {
      const option = cloneDeep(defaultAssignOption);
      option.values.value = getLetterFromIndex(index);
      option.values.rawValue = [index];
      return option;
    });

    if (data.length > 10) {
      if (!newCustomizedOptions.table) newCustomizedOptions.table = {};
      newCustomizedOptions.table.pagination = {
        enabled: true
      };
    }

    const newDataOptions = data.map((data) => {
      return data.map((cell) => {
        const str = (cell || '').toString();
        if (str.indexOf('https://') > -1 || str.indexOf('http://') > -1 || str.indexOf('www.') > -1) {
          let tmp = document.createElement('DIV');
          tmp.innerHTML = cell;
          Array.from(tmp.querySelectorAll('a')).forEach((anchor) => {
            anchor.className = '';
            anchor.target = '_blank';
          });

          return tmp.innerHTML;
        }
        return cell;
      });
    });

    yield put(
      setProjectConfigAction({
        dataOptions: newDataOptions,
        customizedOptions: newCustomizedOptions,
        seriesAssigns,
        dataConfig
      })
    );

    yield put(
      setTableAction({
        isGSheet: dataType === 'google'
      })
    );

    // eslint-disable-next-line callback-return
    if (cb) cb();

    yield call(updateBufferData, {
      dataOptions: newDataOptions
    });
  } catch (e) {
    throw new Error(e);
  }
}

export function* resetData(params) {
  let { dataOptions, customizedOptions } = params.data;

  let newDataOptions = cloneDeep(dataOptions);
  let newCustomizedOptions = cloneDeep(customizedOptions);

  if (newCustomizedOptions && newCustomizedOptions.columns) delete newCustomizedOptions.columns;
  if (newCustomizedOptions && newCustomizedOptions.charts) delete newCustomizedOptions.charts;

  if (newDataOptions) newDataOptions = [[]];

  try {
    yield put(
      setProjectConfigAction({
        dataOptions: newDataOptions,
        customizedOptions: newCustomizedOptions
      })
    );

    yield call(updateBufferData, {
      dataOptions: newDataOptions,
      customizedOptions: newCustomizedOptions
    });
  } catch (e) {
    throw new Error(e);
  }
}

export function* updateData(params) {
  let { dataOptions } = params.data;

  try {
    yield put(
      setProjectConfigAction({
        dataOptions,
        changeMade: true
      })
    );

    yield call(updateBufferData, {
      dataOptions
    });
  } catch (e) {
    throw new Error(e);
  }
}

export function* searchData(params) {
  let { dataOptions } = params.data;

  try {
    yield put(
      setProjectConfigAction({
        dataOptions
      })
    );

    yield call(updateBufferData, {
      dataOptions
    });
  } catch (e) {
    throw new Error(e);
  }
}

function arrayMove(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

export function* updateBufferData(params) {
  const projectConfig = yield select(getProjectConfig);
  const tableSpecificConfig = yield select(getTableSpecificConfig);
  const { showWizard } = projectConfig;
  let {
    dataOptions = projectConfig.dataOptions,
    customizedOptions = projectConfig.customizedOptions,
    searchValue = tableSpecificConfig.searchValue
  } = params;

  customizedOptions = cloneDeep(customizedOptions);
  if (showWizard) {
    // In wizard, use seriesAssigns for parsing data
    const { seriesAssigns } = projectConfig;
    const columnsUsed = seriesAssigns.map((option) => option.values.rawValue[0]);
    dataOptions = cloneDeep(dataOptions).map((row) => {
      return row.filter((cell, index) => columnsUsed.includes(index));
    });
  }

  let pageAmount = 1;

  const aggregatedOptions = merge(merge({}, projectConfig.themeOptions.options), merge({}, customizedOptions));

  const table = aggregatedOptions.table;
  let bufferDataOptions = false;
  let searchDataIndex = [];
  let headerRowLength = 1;

  if (aggregatedOptions && aggregatedOptions.header && aggregatedOptions.header.headerRows) {
    headerRowLength = aggregatedOptions.header.headerRows;
  }

  if (searchValue && searchValue !== '') {
    bufferDataOptions = dataOptions.slice(0, headerRowLength);
    // bufferDataOptions = [dataOptions[0]];
    dataOptions.forEach((option, i) => {
      if (i < headerRowLength) return;
      const found = option.some((cellValue) => {
        const val = isObj(cellValue) ? cellValue.value || '' : cellValue;
        const isValid = isStr(val) || isNum(val);
        const stringValue = (val ?? '').toString().toLowerCase();
        return isValid && stringValue.indexOf(searchValue.toLowerCase()) > -1;
      });
      if (found) {
        bufferDataOptions.push(option);
        searchDataIndex.push(i);
      }
    });
  }

  if (table && table.pagination && table.pagination.enabled) {
    let range = 10;
    if (table.pagination.hasOwnProperty('resultsPerPage')) {
      range = table.pagination.resultsPerPage;
    }

    if (bufferDataOptions) {
      pageAmount = bufferDataOptions.length - 1;
      bufferDataOptions = bufferDataOptions.slice(
        // Not the most elegant way of doing this. Headers shouldnt really factor into the options at this point. TODO: Fix later
        tableSpecificConfig.page * range + (tableSpecificConfig.page === 0 ? 0 : headerRowLength),
        (tableSpecificConfig.page + 1) * range + headerRowLength
      );
      if (tableSpecificConfig.page !== 0) {
        bufferDataOptions.unshift(...dataOptions.slice(0, headerRowLength));
      }
    } else {
      pageAmount = dataOptions.length - 1;
      bufferDataOptions = dataOptions.slice(
        tableSpecificConfig.page * range + (tableSpecificConfig.page === 0 ? 0 : headerRowLength),
        (tableSpecificConfig.page + 1) * range + headerRowLength
      );
      if (tableSpecificConfig.page !== 0) {
        bufferDataOptions.unshift(...dataOptions.slice(0, headerRowLength));
      }
    }
    pageAmount = Math.ceil(pageAmount / range);
  } else {
    if (bufferDataOptions) bufferDataOptions = bufferDataOptions.slice();
    else bufferDataOptions = cloneDeep(dataOptions);
    pageAmount = bufferDataOptions.length - 1;
  }

  bufferDataOptions = bufferDataOptions.map(function (arr) {
    return arr.slice();
  });

  const columns = aggregatedOptions.columns;
  if (columns && columns.length > 0) {
    const parseVal = (d) => {
      const val = isObj(d) ? d.value : d;
      return parseFloat(typeof val === 'string' ? stripHtml(val).replace(/ /g, '').replace(/,/g, '.') : val);
    };

    if (columns.some((column) => column.type === 'chart')) {
      const preBufferDataOptions = bufferDataOptions.map(function (arr) {
        return arr.slice();
      });
      columns.forEach((column, columnIndex) => {
        if (column.type === 'chart') {
          const allColumns = column.columns;
          let max = -Infinity,
            min = Infinity;

          dataOptions.forEach((data, i) => {
            const options = data.slice(allColumns[0], allColumns[allColumns.length - 1] + 1);
            if (i !== 0) {
              min = Math.min(min, ...options.map(parseVal));
              max = Math.max(max, ...options.map(parseVal));
            }
          });

          bufferDataOptions.forEach((data, i) => {
            const options = preBufferDataOptions[i].slice(allColumns[0], allColumns[allColumns.length - 1] + 1);
            data.splice(
              columnIndex,
              0,
              options.map((opt) => stripHtml(isObj(opt) ? opt.value : opt))
            );
          });

          customizedOptions.columns[columnIndex].max = max;
          customizedOptions.columns[columnIndex].min = min;
        }
      });
    }
  }

  // if (destroyCharts) Highcharts.charts.forEach((chart) => { if (chart) chart.destroy(); })
  yield all([
    put(
      setProjectConfigAction({
        customizedOptions
      })
    ),
    put(
      setTableAction({
        bufferDataOptions,
        searchDataIndex,
        shouldUpdateTableComponent: true,
        pageAmount
      })
    )
  ]);
}

export function* changeColumnOrder(params) {
  let { from, to, updateData } = params.data;
  const { dataOptions, customizedOptions } = yield select(getProjectConfig);

  let newDataOptions = cloneDeep(dataOptions);
  let newCustomizedOptions = cloneDeep(customizedOptions);
  if (updateData) {
    newDataOptions.forEach((data) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      data = arrayMove(data, from, to);
    });
  }
  newCustomizedOptions.columns = arrayMove(
    newCustomizedOptions.columns.map((a) => merge({}, a)),
    from,
    to
  );

  yield put(
    setProjectConfigAction({
      dataOptions: newDataOptions,
      customizedOptions: newCustomizedOptions,
      changeMade: true
    })
  );

  yield call(updateBufferData, {
    dataOptions: newDataOptions,
    customizedOptions: newCustomizedOptions
  });
}

export function* changeRowOrder(params) {
  let { dataOptions, from, to } = params.data;

  if (isNaN(from) || isNaN(to)) return;

  let newDataOptions = cloneDeep(dataOptions);
  newDataOptions = arrayMove(newDataOptions, from, to || 1);

  yield put(
    setProjectConfigAction({
      dataOptions: newDataOptions,
      changeMade: true
    })
  );

  yield call(updateBufferData, {
    dataOptions: newDataOptions
  });
}

export function* deleteRow(params) {
  let { dataOptions, index, customizedOptions } = params.data;

  let newDataOptions = cloneDeep(dataOptions);
  let newCustomizedOptions = cloneDeep(customizedOptions);
  let headerRowLength = 1;

  if (newCustomizedOptions && newCustomizedOptions.header && newCustomizedOptions.header.headerRows) {
    headerRowLength = newCustomizedOptions.header.headerRows;
  }

  if (index < headerRowLength) {
    // Adding new row to header, increase header count
    if (!newCustomizedOptions) newCustomizedOptions = {};
    if (!newCustomizedOptions.header) newCustomizedOptions.header = {};
    newCustomizedOptions.header.headerRows = headerRowLength - 1;
  }

  newDataOptions.splice(index, 1);

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      dataOptions: newDataOptions,
      changeMade: true
    })
  );

  yield put(
    setTableAction({
      activeCellColumn: null,
      activeCellRow: null,
      activeBufferCellColumn: null,
      activeBufferCellRow: null
    })
  );

  yield put(hideContextMenuAction({}));
  yield call(updateBufferData, {
    dataOptions: newDataOptions,
    customizedOptions: newCustomizedOptions
  });
  snackBar('Row deleted');
}

export function* deleteColumn(params) {
  let { dataOptions, customizedOptions, index } = params.data;
  let newDataOptions = cloneDeep(dataOptions);
  let newCustomizedOptions = cloneDeep(customizedOptions);

  if (newCustomizedOptions.columns[index].type !== 'chart') {
    let count = -1;
    // eslint-disable-next-line array-callback-return
    (newCustomizedOptions.columns || []).some((element, i) => {
      if (element.type === 'data') count++;
      if (i === index) return true;
    });

    newDataOptions.forEach((data) => {
      data.splice(count, 1);
    });
  }

  newCustomizedOptions.columns.splice(index, 1);

  yield put(
    setProjectConfigAction({
      dataOptions: newDataOptions,
      customizedOptions: newCustomizedOptions,
      changeMade: true
    })
  );

  yield put(hideContextMenuAction({}));

  yield call(updateBufferData, {
    dataOptions: newDataOptions
  });
  snackBar('Column deleted');
}

export function* addColumn(params) {
  let { customizedOptions, dataOptions, index, dir } = params.data;
  let newCustomizedOptions = cloneDeep(customizedOptions);
  let newDataOptions = cloneDeep(dataOptions);

  let dirIndex = dir === 'before' ? 0 : 1;

  // Cant just add a new column in the dataOptions array
  // Need to find out where the index passed in fits in with
  // the customizedOptions columns
  let count = -1;
  // eslint-disable-next-line array-callback-return
  (newCustomizedOptions.columns || []).some((element, i) => {
    if (element.type === 'data') count++;
    if (i === index) return true;
  });

  newDataOptions.forEach((data) => {
    data.splice(count + dirIndex, 0, '');
  });

  newDataOptions[0][count + dirIndex] = 'New Column';

  newCustomizedOptions.columns.splice(index + dirIndex, 0, {
    type: 'data',
    visible: true
  });

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      dataOptions: newDataOptions,
      changeMade: true
    })
  );

  yield put(hideContextMenuAction({}));
  yield call(updateBufferData, {
    customizedOptions: newCustomizedOptions,
    dataOptions: newDataOptions
  });
}

export function* addRow(params) {
  let { dataOptions, customizedOptions, index, dir } = params.data;
  let newDataOptions = cloneDeep(dataOptions);
  let headerRowLength = 1;
  let newCustomizedOptions = cloneDeep(customizedOptions);

  let dirIndex = dir === 'before' ? 0 : 1;

  if (newCustomizedOptions && newCustomizedOptions.header && newCustomizedOptions.header.headerRows) {
    headerRowLength = newCustomizedOptions.header.headerRows;
  }

  if (index < headerRowLength) {
    // Adding new row to header, increase header count
    if (!newCustomizedOptions) newCustomizedOptions = {};
    if (!newCustomizedOptions.header) newCustomizedOptions.header = {};
    newCustomizedOptions.header.headerRows = headerRowLength + 1;
  }

  newDataOptions.splice(index + dirIndex, 0, Array(customizedOptions.columns.length).fill(''));

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      dataOptions: newDataOptions,
      changeMade: true
    })
  );

  yield put(hideContextMenuAction({}));
  yield call(updateBufferData, {
    customizedOptions: newCustomizedOptions,
    dataOptions: newDataOptions
  });
}

export function* deleteAssignRawOption(params) {
  const { index } = params.data;
  const config = yield select(getProjectConfig);
  let customizedOptions = cloneDeep(config.customizedOptions);
  let seriesAssigns = cloneDeep(config.seriesAssigns);
  seriesAssigns.splice(index, 1);

  customizedOptions.columns.splice(customizedOptions.columns.length - 1, 1);

  yield put(
    setProjectConfigAction({
      seriesAssigns,
      customizedOptions
    })
  );

  yield call(updateBufferData, {});
}

export function* addAssignRawOption() {
  const config = yield select(getProjectConfig);
  const tableConfig = yield select(getTableSpecificConfig);
  let defaultOption = cloneDeep(tableConfig.defaultAssignOption);
  let seriesAssigns = cloneDeep(config.seriesAssigns);
  let dataOptions = cloneDeep(config.dataOptions);
  let customizedOptions = cloneDeep(config.customizedOptions);

  try {
    const lastOption = seriesAssigns[seriesAssigns.length - 1];
    const lastIndex = lastOption.values.rawValue[0];
    defaultOption.values.value = getLetterFromIndex(lastIndex + 1);
    defaultOption.values.rawValue = [lastIndex + 1];

    customizedOptions.columns.push({
      type: 'data',
      visible: true
    });

    if (dataOptions[0].length < lastIndex + 1) {
      yield put(
        updateDataAndConfigAction({
          row: 0,
          column: lastIndex + 1,
          skipSeriesMapping: true
        })
      );
    }

    seriesAssigns.push(defaultOption);

    yield put(
      setProjectConfigAction({
        seriesAssigns,
        customizedOptions
      })
    );

    yield call(updateBufferData, {});
  } catch (e) {
    throw new Error(e);
  }
}

export function* updateAssignRawOption(params) {
  const { newSeries, newIndex, seriesIndex } = params.data;

  const config = yield select(getProjectConfig);
  let seriesAssigns = cloneDeep(config.seriesAssigns);
  let dataOptions = cloneDeep(config.dataOptions);
  let customizedOptions = cloneDeep(config.customizedOptions);

  try {
    seriesAssigns[seriesIndex].values.value = newSeries;
    seriesAssigns[seriesIndex].values.rawValue = [newIndex];

    if (dataOptions[0].length < newIndex) {
      yield put(
        updateDataAndConfigAction({
          row: 0,
          column: newIndex,
          skipSeriesMapping: true
        })
      );
    }

    yield put(
      setProjectConfigAction({
        seriesAssigns,
        customizedOptions
      })
    );

    yield call(updateBufferData, {});
  } catch (e) {
    throw new Error(e);
  }
}

export function* filterData() {
  const config = yield select(getProjectConfig);
  let seriesAssigns = cloneDeep(config.seriesAssigns);
  let dataOptions = cloneDeep(config.dataOptions);

  const columnsUsed = seriesAssigns.map((option) => option.values.rawValue[0]);
  dataOptions = dataOptions.map((row) => {
    return row.filter((cell, index) => columnsUsed.includes(index));
  });

  yield put(
    setProjectConfigAction({
      seriesAssigns: [],
      dataOptions
    })
  );
}

/** Watch functions */
export function* watchResetData() {
  yield takeEvery(actionTypes.tableEditor.resetData, resetData);
}
export function* watchSetData() {
  yield takeEvery(actionTypes.tableEditor.setData, setData);
}
export function* watchUpdateData() {
  yield takeEvery(actionTypes.tableEditor.updateData, updateData);
}
export function* watchChangeColumnOrder() {
  yield takeEvery(actionTypes.tableEditor.changeColumnOrder, changeColumnOrder);
}
export function* watchChangeRowOrder() {
  yield takeEvery(actionTypes.tableEditor.changeRowOrder, changeRowOrder);
}
export function* watchDeleteRow() {
  yield takeEvery(actionTypes.tableEditor.deleteRow, deleteRow);
}
export function* watchDeleteColumn() {
  yield takeEvery(actionTypes.tableEditor.deleteColumn, deleteColumn);
}
export function* watchAddColumn() {
  yield takeEvery(actionTypes.tableEditor.addColumn, addColumn);
}
export function* watchAddRow() {
  yield takeEvery(actionTypes.tableEditor.addRow, addRow);
}
export function* watchDeleteAssignRawOption() {
  yield takeEvery(actionTypes.tableEditor.deleteAssignRawOption, deleteAssignRawOption);
}
export function* watchAddAssignRawOption() {
  yield takeEvery(actionTypes.tableEditor.addAssignRawOption, addAssignRawOption);
}
export function* watchUpdateAssignRawOption() {
  yield takeEvery(actionTypes.tableEditor.updateAssignRawOption, updateAssignRawOption);
}
export function* watchFilterData() {
  yield takeEvery(actionTypes.tableEditor.filterData, filterData);
}

export default function* rootSaga() {
  yield all([
    watchSetData(),
    watchResetData(),
    watchUpdateData(),
    watchChangeColumnOrder(),
    watchChangeRowOrder(),
    watchDeleteRow(),
    watchDeleteColumn(),
    watchAddColumn(),
    watchAddRow(),
    watchDeleteAssignRawOption(),
    watchAddAssignRawOption(),
    watchUpdateAssignRawOption(),
    watchFilterData()
  ]);
}
