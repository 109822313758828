import PublishExport from 'shared/editor/generic/PublishExport';
import { PanelOptionProps } from 'shared/editor/generic/OptionsPanel';

const options: Array<PanelOptionProps> = [
  {
    id: 'publish',
    name: 'Publish & Export',
    element: PublishExport as unknown as React.ReactNode,
    permission: 'publish'
  }
];

export default options;
