import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { Provider } from 'react-redux';
// TODO Use the new create root method
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom';
import store, { initMiddleware } from './redux/store';

const Highstock = require('highcharts/highstock');
require('highcharts/modules/map')(Highstock);
require('highcharts/modules/accessibility')(Highstock);
require('highcharts/highcharts-more')(Highstock);
require('highcharts/highcharts-3d')(Highstock);
require('highcharts/modules/tilemap')(Highstock);
require('highcharts/modules/pattern-fill')(Highstock);
require('highcharts/modules/data')(Highstock);
require('highcharts/modules/exporting')(Highstock);
require('highcharts/modules/funnel')(Highstock);
require('highcharts/modules/solid-gauge')(Highstock);
require('highcharts/modules/export-data')(Highstock);
require('highcharts/modules/sankey')(Highstock);
require('highcharts/modules/dependency-wheel')(Highstock);
require('highcharts/modules/item-series')(Highstock);
require('highcharts/modules/timeline')(Highstock);
require('highcharts/modules/streamgraph')(Highstock);
require('highcharts/modules/series-label')(Highstock);
require('highcharts/modules/wordcloud')(Highstock);
require('highcharts/modules/cylinder')(Highstock);
require('highcharts/modules/funnel3d')(Highstock);
require('highcharts/modules/pyramid3d')(Highstock);
require('highcharts/modules/vector')(Highstock);
require('highcharts/modules/sunburst')(Highstock);
require('highcharts/modules/heatmap')(Highstock);
require('highcharts/modules/treemap')(Highstock);
require('highcharts/modules/annotations')(Highstock);
require('highcharts/modules/annotations-advanced')(Highstock);
require('highcharts/modules/stock-tools')(Highstock);

window.Highcharts = Highstock;

// Everviz Highcharts plugins
require('./resources/highcharts/10.2/modules/index.js');
require('./resources/js/everviz-utils.js');
require('./resources/js/highcharts-resize');
require('./resources/js/highcharts-overrides');
require('./resources/js/everviz-tooltip-helper');
require('./resources/js/highcharts-small-screen-annotations');
require('./resources/js/everviz-plotband-helper');
require('./resources/js/everviz-tooltip-helper');
require('./resources/js/highcharts-live-data-override');
require('./resources/js/highcharts-sparkline');
require('./resources/js/highcharts-font-awesome');
require('./resources/js/responsive-iframe');
require('./resources/js/highcharts-addons');

export const Highcharts = Highstock;

// Styling
import './stylesheets/main.scss';

// Components
import App from './containers/App';
import { Helmet } from 'react-helmet';

initMiddleware();

window.inEverviz = true;

render(
  <Provider store={store}>
    <>
      <Helmet>
        <script src="https://code.highcharts.com/mapdata/countries/us/us-all.js" />
      </Helmet>
      <App />
    </>
  </Provider>,
  document.getElementById('app')
);
