const ANGLE_BIAS = 90;

export const defaultColorPalette = [
  '#FF6900',
  '#FCB900',
  '#7BDCB5',
  '#00D084',
  '#8ED1FC',
  '#0693E3',
  '#ABB8C3',
  '#607d8b',
  '#EB144C',
  '#F78DA7',
  '#ba68c8',
  '#9900EF'
];

const blackStop = '#000000bf';
const whiteStop = '#ffffff00';
const transparentStop = '#00000000';

export const getFinalColorStop = (input: string) => {
  if (input.startsWith('#')) {
    return input;
  } else {
    const regex = /#([0-9a-fA-F]+)[^#]+?(\d+(\.\d+)?)%/g;
    const matches = [...input.matchAll(regex)];

    if (matches.length > 0) {
      const lastMatch = matches[matches.length - 1];
      const color = `#${lastMatch[1]}`;
      return color;
    } else {
      return null; // Return null if no match is found
    }
  }
};

export const generateGradientPatterns = (
  baseColor: string,
  setDefaultColors: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const isWhite = baseColor.startsWith('#ffffff');
  setDefaultColors((prevColors) => [
    ...prevColors.slice(0, 12), // Keep the first 12 items intact
    `linear-gradient(0deg, ${isWhite ? blackStop : whiteStop} 0.00%,${baseColor} 100.00%)`,
    `linear-gradient(180deg, ${isWhite ? blackStop : transparentStop} 0.00%,${baseColor} 100.00%)`,
    `linear-gradient(270deg, ${blackStop} 0.00%,${baseColor} 100.00%)`,
    `linear-gradient(90deg, ${isWhite ? blackStop : whiteStop} 0.00%,${baseColor} 100.00%)`,
    `linear-gradient(45deg, ${isWhite ? blackStop : transparentStop} 0.00%,${baseColor} 100.00%)`,
    `linear-gradient(135deg, ${blackStop} 0.00%,${baseColor} 100.00%)`
  ]);
};

export const getLastStopColor = (data: any) => {
  if (data.stops && Array.isArray(data.stops) && data.stops.length > 0) {
    const lastStop = data.stops[data.stops.length - 1];
    if (Array.isArray(lastStop) && lastStop.length === 2) {
      const color = lastStop[1];
      return color;
    }
  }
  return data;
};

export const isStringGradient = (input: string) => {
  if (input) return input.includes('linear-gradient');
};

export const modifyColorPalette = (colors: string[]) => {
  const modifiedPalette = [...defaultColorPalette];

  // Replace the first 12 colors in the palette with the provided colors
  for (let i = 0; i < Math.min(colors.length, 12); i++) {
    modifiedPalette[i] = getLastStopColor(colors[i]);
  }
  // If there are fewer colors provided, replace the remaining default colors with empty strings
  for (let i = Math.min(colors.length, 12); i < 12; i++) {
    modifiedPalette[i] = '';
  }

  return modifiedPalette;
};

export const createLinearGradientValues = (angleInDegrees: any) => {
  // Normalize the angle to be between 0 and 360 degrees
  angleInDegrees = ((angleInDegrees % 360) + 360) % 360;
  const x1 = angleInDegrees >= 45 && angleInDegrees <= 135 ? 0 : 1;
  const y1 = angleInDegrees >= 135 && angleInDegrees <= 225 ? 0 : 1;
  const x2 = angleInDegrees >= 225 && angleInDegrees <= 315 ? 0 : 1;
  const y2 = (angleInDegrees >= 315 && angleInDegrees <= 360) || (angleInDegrees >= 0 && angleInDegrees <= 45) ? 0 : 1;

  return { x1, y1, x2, y2 };
};

export const calculateAngleFromLinearGradient = (linearGradientValues: any) => {
  const { x1, y1, x2, y2 } = linearGradientValues;

  const angleInDegrees = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI);
  const positiveAngle = angleInDegrees + ANGLE_BIAS;

  return positiveAngle;
};

export const createColorPickerValue = (data: any) => {
  if (data && data.linearGradient && data.stops) {
    const stops = data.stops;
    const angle = calculateAngleFromLinearGradient(data.linearGradient);

    const stopsString = stops.map(([position, color]: [number, string]) => `${color} ${position * 100}%`).join(',');
    const linearGradientString = `linear-gradient(${angle}deg, ${stopsString})`;

    return linearGradientString;
  }
  return data; // Return solid
};

export const parseColorPickerValue = (color: string) => {
  const regex = /linear-gradient\(([\d.]+)deg,\s*([^)]+)\)/;
  const matches = color.match(regex);

  if (matches) {
    const angle = parseFloat(matches[1]);
    const colorStops = matches[2].split(',');

    const linearGradient = createLinearGradientValues(angle);
    const stops = [];
    for (let i = 0; i < colorStops.length; i++) {
      const colorStop = colorStops[i].trim().split(' ');
      const positionPercentage = parseFloat(colorStop[1]);
      const position = positionPercentage / 100; // Convert percentage to a decimal between 0 and 1
      const color = colorStop[0];
      stops.push([position, color]);
    }

    return {
      linearGradient,
      stops
    };
  }
  return color;
};
