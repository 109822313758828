export default {
  dataReturned: false,
  activeDivision: null,
  editDivisionModalOpen: false,
  unsavedChanges: false,
  isUsersDeleteModalOpen: false,
  isUsersSubteamAddModalOpen: false,
  isSubteamCreateModalOpen: false,
  selectedUser: null,
  membersQuery: '',
  allMembers: {
    data: [],
    loading: false,
    error: null
  },
  members: {
    data: [],
    page: 1,
    pageSize: 10,
    pageCount: 1,
    loading: false,
    error: null
  },
  subteamMembers: {
    data: [],
    page: 1,
    pageSize: 10,
    pageCount: 1,
    loading: false,
    error: null
  }
};
