import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../../../shared/message/ErrorMessage';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import InputWidget from 'shared/widgets/InputWidget';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';

const TeamInvitationsForm = ({
  value,
  onSave,
  onChange,
  onClose,
  groups,
  groupId,
  inviteError,
  unitPrice,
  priceTotal,
  subscription,
  teamName
}) => {
  const parsedGroups = (groups ?? []).map((group) => ({ label: group.name, value: group.id }));
  let parsedValue = parsedGroups.filter((g) => g.value == groupId);
  if (!parsedValue.length) parsedValue = null;

  return (
    <div>
      <ErrorMessage error={inviteError} />
      <div>
        {subscription && subscription.pay_per_user === 1 && (
          <>
            <div className="alert alert-danger">
              {' '}
              On removing members, please consider your credit will be processed on the next billing cycle and your
              account will be credited. If you have any questions please get in touch with us via sales@everviz.com
            </div>
            <div>
              {' '}
              <b>Current Price:</b> $ {priceTotal}{' '}
            </div>
            <div>
              {' '}
              <b>New Price after invites:</b> ${priceTotal + unitPrice * (value.match(/@/g) || []).length}{' '}
            </div>
            <br />
          </>
        )}
        <p className="mb-4">
          This will invite new member(s) to: <b>{teamName}</b>.
        </p>
      </div>

      <InputWidget
        placeholder="email_1@example.com, email_2@example.com"
        value={value}
        onChange={onChange}
        label="Separated by ','"
        className="h-full w-full"
      />

      <hr />
      <div className="mt-8 flex gap-4">
        <PrimaryButton onClick={onSave} text="Confirm" />
        <PrimaryButton onClick={onClose} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
      </div>
    </div>
  );
};

TeamInvitationsForm.propTypes = {
  value: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  groups: PropTypes.array,
  groupId: PropTypes.string,
  updateActiveGroup: PropTypes.func,
  inviteError: PropTypes.string,
  unitPrice: PropTypes.number,
  priceTotal: PropTypes.number,
  subscription: PropTypes.object,
  teamName: PropTypes.string
};

export default TeamInvitationsForm;
