import { cloneDeep, isString, set } from 'lodash';
import { getChartConfig } from 'pages/ChartEditorPage/selectors/chartEditor';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actionTypes from 'redux/actions/action-types';
import { getProjectConfig } from 'redux/selectors/projectConfig';
import { setAction as setProjectConfigAction } from '../../../../redux/actions/projectConfig';
import { updateAggregated } from '../ChartEditor';
import { GenericPayload } from '../ChartEditorPointMap';
import { LayerOptions } from '@visual-elements/location-map';
import { ProjectConfigLocationMapProps } from '../../../Editor/reducers/locationMapConfigTypes';
import { LayerSpecification, StyleSpecification } from 'maplibre-gl';

type UpdateCustomizedPayload = GenericPayload & {
  data: {
    optionProps: { id: string } | string;
    val: any;
    skipEmit?: boolean;
  };
};

type UpdateConfigBasedOnMapPayload = GenericPayload & {
  data: {
    options: {
      key: string;
      value: any;
    }[];
  };
};

export function* updateCustomized(params: UpdateCustomizedPayload) {
  try {
    const { optionProps, val, skipEmit } = params.data;
    const { inlineEditor } = yield select(getChartConfig);
    const { customizedOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newCustomizedOptions = cloneDeep(customizedOptions);

    if (inlineEditor) inlineEditor.destroy();
    const prop = isString(optionProps) ? optionProps : optionProps?.id;
    set(newCustomizedOptions, prop, val);

    yield put(
      setProjectConfigAction({
        customizedOptions: newCustomizedOptions,
        changeMade: true
      })
    );

    // yield call(updateDataOptions);
    if (!skipEmit) {
      yield call(updateAggregated, true);
    }
  } catch (e) {
    console.log(e);
  }
}

export function* updateConfigBasedOnMap(params: UpdateConfigBasedOnMapPayload) {
  const { options } = params.data;
  const { customizedOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
  const newCustomizedOptions = cloneDeep(customizedOptions);

  options.forEach(({ key, value }) => {
    set(newCustomizedOptions, key, value);
  });

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      changeMade: true
    })
  );

  yield call(updateAggregated, true);
}

type Overwrite<T, NewT> = Omit<T, keyof NewT> & NewT;
export type EvervizLayerSpecification = Overwrite<
  LayerSpecification,
  { metadata: Partial<{ 'everviz:group': string; 'mapbox:group': string }> }
>;

export type LayerGroupMetadata = { id: string; advanced: boolean; defaultVisibility: 'hidden' | 'visible' };
export type CustomEvervizStyleSpecification = Overwrite<
  StyleSpecification,
  {
    layers: Array<EvervizLayerSpecification>;
    metadata: Partial<{
      'everviz:groups': LayerGroupMetadata[];
    }>;
  }
>;

type UpdateStyledMapLayersPayload = GenericPayload & {
  data: {
    id: string;
    visible: boolean;
    group: boolean;
  };
};

export function* updateStyledMapLayers(params: UpdateStyledMapLayersPayload) {
  const { id, group, visible } = params.data;
  const { customizedOptions, locationMapOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);

  try {
    if (!group) throw new Error('Implementation is not done for styling individual layers');
    const style = locationMapOptions.editorMapRef?.mapRef?.getMap()?.getStyle() as
      | CustomEvervizStyleSpecification
      | undefined;

    if (!style) throw new Error('Style could not be found');

    // Get all layers that have the "group" metadata set to the name of the chosen group
    const layersToStyle = style.layers.filter((x) => x.metadata && x.metadata['everviz:group'] === id);
    if (layersToStyle.length <= 0) return;

    const newCustomizedOptions = cloneDeep(customizedOptions);
    const newLocationMapOptions = cloneDeep(locationMapOptions);

    if (newLocationMapOptions.layerOptions[id]) {
      newLocationMapOptions.layerOptions[id].visible = visible;
    } else {
      newLocationMapOptions.layerOptions[id] = { visible: visible };
    }

    newCustomizedOptions.layers ??= [];

    for (const layerToStyle of layersToStyle) {
      const layerToStyleIndex = newCustomizedOptions.layers.findIndex((x) => x?.id === layerToStyle.id);

      if (layerToStyleIndex >= 0) {
        const layer = newCustomizedOptions.layers[layerToStyleIndex] as LayerOptions;
        layer.visible = visible;
      } else {
        newCustomizedOptions.layers.push({ id: layerToStyle.id, visible: visible });
      }
    }

    yield put(
      setProjectConfigAction({
        customizedOptions: newCustomizedOptions,
        locationMapOptions: newLocationMapOptions,
        changeMade: true
      })
    );

    yield call(updateAggregated, true);
  } catch (err) {
    console.log(err);
  }
}

export function* resetStyledMapLayers() {
  const { locationMapOptions, customizedOptions, aggregatedOptions }: ProjectConfigLocationMapProps = yield select(
    getProjectConfig
  );

  const newLocationMapOptions = cloneDeep(locationMapOptions);
  const newCustomizedOptions = cloneDeep(customizedOptions);

  newLocationMapOptions.layerOptions = {};
  if (newCustomizedOptions.layers) {
    delete newCustomizedOptions.layers;
  }
  yield put(
    setProjectConfigAction({
      locationMapOptions: newLocationMapOptions,
      customizedOptions: newCustomizedOptions,
      changeMade: true
    })
  );

  // Reset the map style to revert the changes that location map has done to the layers
  newLocationMapOptions.editorMapRef?.mapRef
    ?.getMap()
    ?.setStyle(aggregatedOptions.theme.type === 'dynamic' ? aggregatedOptions.theme.url : aggregatedOptions.theme.data);

  yield call(updateAggregated, true);
}

export function* watchUpdateStyledMapLayers() {
  yield takeEvery(actionTypes.locationMap.updateStyledMapLayers, updateStyledMapLayers);
}

export function* watchUpdateCustomized() {
  yield takeEvery(actionTypes.locationMap.updateCustomized, updateCustomized);
}

export function* watchResetStyledMapLayers() {
  yield takeEvery(actionTypes.locationMap.resetStyledMapLayers, resetStyledMapLayers);
}

export default function* rootSaga() {
  yield all([watchUpdateCustomized(), watchResetStyledMapLayers(), watchUpdateStyledMapLayers()]);
}
