const actionTypes = {
  team: {
    updateActiveTeam: 'team/updateActiveTeam',
    updateTeams: 'team/updateTeams'
  },
  division: {
    updateDivisions: 'team/updateDivisions',
    updateActiveDivision: 'team/updateActiveDivision'
  },
  chart: {
    updateChart: 'chart/updateChart'
  },
  projectsPage: {
    setSelectFilter: 'projectsPage/setSelectFilter',
    getCharts: 'projectsPage/getCharts',
    getTags: 'projectsPage/getTags',
    deleteTag: 'projectsPage/deleteTags',
    pageUpdate: 'projectsPage/pageUpdated',
    getCreators: 'projectsPage/getCreators',
    deleteChart: 'projectsPage/deleteChart',
    recoverChart: 'projectsPage/recoverChart',
    convertToTheme: 'projectsPage/convertToTheme',
    duplicateToTheme: 'projectsPage/duplicateToTheme',
    duplicateChart: 'projectsPage/duplicateChart',
    setChartMeta: 'projectsPage/setChartMeta',
    set: 'projectsPage/set',
    updateCharts: 'projectsPage/updateCharts'
  },
  customiseEditorPage: {
    getEditorConfig: 'customiseEditorPage/getEditorConfig',
    set: 'customiseEditorPage/set',
    getUserEditorConfig: 'customiseEditorPage/getUserEditorConfig',
    setUserEditorConfig: 'customiseEditorPage/setUserEditorConfig',
    getFonts: 'customiseEditorPage/getFonts',
    deleteFont: 'customiseEditorPage/deleteFont',
    deleteLogo: 'customiseEditorPage/deleteLogo',
    getLogos: 'customiseEditorPage/getLogos',
    addCustomFont: 'customiseEditorPage/addCustomFont',
    editCustomFont: 'customiseEditorPage/editCustomFont'
  },
  plansPage: {
    set: 'plansPage/set',
    updatePlan: 'plansPage/updatePlan',
    getSummary: 'plansPage/getSummary',
    getAdjustments: 'plansPage/getAdjustments',
    downloadInvoice: 'plansPage/downloadInvoice',
    getBillingDetails: 'plansPage/getBillingDetails'
  },
  settingsPage: {
    set: 'settingsPage/set',
    getTeamProfile: 'settingsPage/getTeamProfile',
    setTeamSettings: 'settingsPage/setTeamSettings',
    deleteTeam: 'settingsPage/deleteTeam',
    verifyEmail: 'settingsPage/verifyEmail',
    setUserSettings: 'settingsPage/setUserSettings',
    generateLicense: 'settingsPage/generateLicense',
    getCMSLicense: 'settingsPage/getCMSLicense',
    deleteCMSLicense: 'settingsPage/deleteCMSLicense'
  },
  teamMembersPage: {
    set: 'teamMembersPage/set',
    updateUsersGroup: 'teamMembersPage/updateUsersGroup',
    updateDivisionMembers: 'teamMembersPage/updateDivisionMembers',
    getMembers: 'teamMembersPage/getMembers',
    getDivisionMembers: 'teamMembersPage/getDivisionMembers',
    deleteTeamUsers: 'teamMembersPage/deleteTeamUsers',
    createDivision: 'teamMembersPage/createDivision',
    updateDivisionName: 'teamMembersPage/updateDivisionName',
    deleteDivision: 'teamMembersPage/deleteDivision'
  },
  showChartPage: {
    set: 'showChartPage/set',
    loadChart: 'showChartPage/loadChart',
    editChart: 'showChartPage/editChart',
    duplicateChart: 'showChartPage/duplicateChart',
    loadPackage: 'showChartPage/loadPackage'
  },
  teamInvitationsPage: {
    set: 'teamInvitationsPage/set',
    getInvitations: 'teamInvitationsPage/getInvitations',
    deleteInvitation: 'teamInvitationsPage/deleteInvitation',
    deleteInvitations: 'teamInvitationsPage/deleteInvitations',
    setInvitations: 'teamInvitationsPage/setInvitations',
    resendInvitations: 'teamInvitationsPage/resendInvitations',
    getPlanDetails: 'teamInvitationsPage/getPlanDetails'
  },
  teamWebhooksPage: {
    set: 'teamWebhooksPage/set',
    getWebhooks: 'teamWebhooksPage/getWebhooks',
    getSupportedWebhooks: 'teamWebhooksPage/getSupportedWebhooks',
    setWebhook: 'teamWebhooksPage/setWebhook',
    updateWebhook: 'teamWebhooksPage/updateWebhook',
    deleteWebhooks: 'teamWebhooksPage/deleteWebhooks'
  },
  rolesPage: {
    set: 'rolesPage/set',
    getPermissions: 'rolesPage/getPermissions',
    getRolePermissions: 'rolesPage/getRolePermissions',
    addRole: 'rolesPage/addRole',
    updateRole: 'rolesPage/updateRole',
    deleteRole: 'rolesPage/deleteRole',
    setRolePermissions: 'rolesPage/setRolePermissions'
  },
  apiPage: {
    set: 'apiPage/set',
    getTeamKeys: 'apiPage/getTeamKeys',
    updateKey: 'apiPage/updateKey',
    deleteKeys: 'apiPage/deleteKeys',
    setTeamKey: 'apiPage/setTeamKey'
  },
  themesPage: {
    set: 'themesPage/set',
    getTeamThemes: 'themesPage/getTeamThemes',
    getCompanyTheme: 'themesPage/getCompanyTheme',
    updateThemeData: 'themesPage/updateThemeData'
  },
  layoutEditor: {
    set: 'layoutEditor/set',
    initEditor: 'layoutEditor/initEditor',
    getPublishedCharts: 'layoutEditor/getPublishedCharts',
    setPageTemplate: 'layoutEditor/setPageTemplate',
    updatePageTemplate: 'layoutEditor/updatePageTemplate',
    setResolution: 'layoutEditor/setResolution',
    saveLayout: 'layoutEditor/saveLayout',
    addChartToGridSuccess: 'layoutEditor/addChartToGridSuccess',
    loadLayout: 'layoutEditor/loadLayout',
    toggleChartModal: 'layoutEditor/toggleChartModal',
    addChartToGrid: 'layoutEditor/addChartToGrid',
    addImageToGrid: 'layoutEditor/addImageToGrid',
    addToGrid: 'layoutEditor/addToGrid',
    changeTitleText: 'layoutEditor/changeTitleText',
    updateCustomizedProp: 'layoutEditor/updateCustomizedProp',
    updateDataProp: 'layoutEditor/updateDataProp',
    openPublishModal: 'layoutEditor/openPublishModal',
    closePublishModal: 'layoutEditor/closePublishModal',
    publishLayout: 'layoutEditor/publishLayout',
    assignTheme: 'layoutEditor/assignTheme',
    removeChartFromDataOptions: 'layoutEditor/removeChartFromDataOptions',
    getFonts: 'layoutEditor/getFonts',
    getLogos: 'layoutEditor/getLogos',
    resetState: 'layoutEditor/resetState',
    toggleCMSModal: 'layoutEditor/toggleCMSModal',
    redrawProject: 'layoutEditor/redrawProject',
    remove: 'layoutEditor/remove'
  },
  tableEditor: {
    set: 'tableEditor/set',
    initEditor: 'tableEditor/initEditor',
    saveTable: 'tableEditor/saveTable',
    loadTable: 'tableEditor/loadTable',
    changeTitleText: 'tableEditor/changeTitleText',
    updateCustomizedProp: 'tableEditor/updateCustomizedProp',
    updateDataProp: 'tableEditor/updateDataProp',
    publishTable: 'tableEditor/publishTable',
    assignTheme: 'tableEditor/assignTheme',
    setData: 'tableEditor/setData',
    resetData: 'tableEditor/resetData',
    loadPaginated: 'tableEditor/loadPaginated',
    goToNextPage: 'tableEditor/goToNextPage',
    goToPreviousPage: 'tableEditor/goToPreviousPage',
    search: 'tableEditor/search',
    updateData: 'tableEditor/updateData',
    changeColumnOrder: 'tableEditor/changeColumnOrder',
    processConditionalFormatting: 'tableEditor/processConditionalFormatting',
    changeRowOrder: 'tableEditor/changeRowOrder',
    deleteRow: 'tableEditor/deleteRow',
    deleteColumn: 'tableEditor/deleteColumn',
    addChartToTable: 'tableEditor/addChartToTable',
    updateChartHeader: 'tableEditor/updateChartHeader',
    updateCustomizedArrayProp: 'tableEditor/updateCustomizedArrayProp',
    updateColumnHiddenValue: 'tableEditor/updateColumnHiddenValue',
    addRow: 'tableEditor/addRow',
    addColumn: 'tableEditor/addColumn',
    resetTableState: 'tableEditor/resetTableState',
    setCellSetting: 'tableEditor/setCellSetting',
    mergeCells: 'tableEditor/mergeCells',
    setDataStyle: 'tableEditor/setDataStyle',
    removeDataStyle: 'tableEditor/removeDataStyle',
    toggleDataStyle: 'tableEditor/toggleDataStyle',
    setBordersAllEdges: 'tableEditor/setBordersAllEdges',
    deleteBulkCellValues: 'tableEditor/deleteBulkCellValues',
    setBorderStyle: 'tableEditor/setBorderStyle',
    openExportModal: 'tableEditor/openExportModal',
    closeExportModal: 'tableEditor/closeExportModal',
    showContextMenu: 'tableEditor/showContextMenu',
    hideContextMenu: 'tableEditor/hideContextMenu',
    redrawTable: 'tableEditor/redrawTable',
    editDataGrid: 'tableEditor/editDataGrid',
    deleteAssignRawOption: 'tableEditor/deleteAssignRawOption',
    addAssignRawOption: 'tableEditor/addAssignRawOption',
    updateAssignRawOption: 'tableEditor/updateAssignRawOption',
    filterData: 'tableEditor/filterData'
  },
  chartEditor: {
    initSettings: 'chartEditor/initSettings',
    set: 'chartEditor/set',
    saveChart: 'chartEditor/saveChart',
    publishChart: 'chartEditor/publishChart',
    assignTheme: 'chartEditor/assignTheme',
    initEditor: 'chartEditor/initEditor',
    setTemplate: 'chartEditor/setTemplate',
    loadCSV: 'chartEditor/loadCSV',
    loadMapCSV: 'chartEditor/loadMapCSV',
    setCustomizedOptions: 'chartEditor/setCustomizedOptions',
    addBlankSeries: 'chartEditor/addBlankSeries',
    deleteSeries: 'chartEditor/deleteSeries',
    loadProject: 'chartEditor/loadProject',
    deleteSingleSeries: 'chartEditor/deleteSingleSeries',
    clearData: 'chartEditor/clearData',
    loadGoogleSheet: 'chartEditor/loadGoogleSheet',
    loadLiveData: 'chartEditor/loadLiveData',
    updateCustomized: 'chartEditor/updateCustomized',
    updateCustomizedBulk: 'chartEditor/updateCustomizedBulk',
    deleteCustomized: 'chartEditor/deleteCustomized',
    loadChart: 'chartEditor/loadChart',
    setMap: 'chartEditor/setMap',
    setCustomCode: 'chartEditor/setCustomCode',
    redrawProject: 'chartEditor/redrawProject',
    resetState: 'chartEditor/resetState',
    setData: 'chartEditor/setData',
    addAnnotation: 'chartEditor/addAnnotation',
    removeAnnotation: 'chartEditor/removeAnnotation',
    removeHighlight: 'chartEditor/removeHighlight',
    onChangeActiveAnnotation: 'chartEditor/onChangeActiveAnnotation',
    updateLabelAnnotation: 'chartEditor/updateLabelAnnotation',
    updateLineAnnotation: 'chartEditor/updateLineAnnotation',
    updateAnnotations: 'chartEditor/updateAnnotations',
    showAnnotationInMenu: 'chartEditor/showAnnotationInMenu',
    addHighlight: 'chartEditor/addHighlight',
    updateHighlightAnnotation: 'chartEditor/updateHighlightAnnotation',
    useGeoJSONMap: 'chartEditor/useGeoJSONMap',
    showGeoJSONModal: 'chartEditor/showGeoJSONModal',
    hideGeoJSONModal: 'chartEditor/hideGeoJSONModal',
    updateMapData: 'chartEditor/updateMapData',
    setGeoJSONMap: 'chartEditor/setGeoJSONMap',
    closePublishModal: 'chartEditor/closePublishModal',
    openPublishModal: 'chartEditor/openPublishModal',
    closeCMSModal: 'chartEditor/closeCMSModal',
    loadChartFromUUID: 'chartEditor/loadChartFromUUID',
    addItemArray: 'chartEditor/addItemArray',
    deleteItemArray: 'chartEditor/deleteItemArray',
    updateDataLabel: 'chartEditor/updateDataLabel',
    updateDataRelatedProp: 'chartEditor/updateDataRelatedProp',
    updateBasedOnDataProp: 'chartEditor/updateBasedOnDataProp',
    editDataGrid: 'chartEditor/editDataGrid',
    addDataGridSeries: 'chartEditor/addDataGridSeries',
    deleteDataGridSeries: 'chartEditor/deleteDataGridSeries',
    sortData: 'chartEditor/sortData',
    updateChartCategories: 'chartEditor/updateChartCategories',
    updateChartSeries: 'chartEditor/updateChartSeries',
    addChartSeries: 'chartEditor/addChartSeries',
    deleteChartSeries: 'chartEditor/deleteChartSeries',
    transposeData: 'chartEditor/transposeData',
    moveCells: 'chartEditor/moveCells',
    clearColumn: 'chartEditor/clearColumn',
    remap: 'chartEditor/remap',
    adjustSeriesAssigns: 'chartEditor/adjustSeriesAssigns',
    searchApi: 'chartEditor/searchApi',
    linkExternalData: 'chartEditor/linkExternalData'
  },
  pointMap: {
    deletePoint: 'pointMap/deletePoint',
    addPoint: 'pointMap/addPoint'
  },
  locationMap: {
    storeLocationMapRef: 'locationMap/storeLocationMapRef',
    fitMapToBoundingBox: 'locationMap/fitMapToBoundingBox',
    fitMapToFeatures: 'locationMap/fitMapToFeatures',
    updateViewState: 'locationMap/updateViewState',
    toggleViewBox: 'locationMap/toggleViewBox',
    updateReferencePointResolution: 'locationMap/updateReferencePointResolution',
    updateStyledMapLayers: 'locationMap/updateStyledMapLayers',
    updateCustomized: 'locationMap/updateCustomized',
    addLocationMapMarker: 'locationMap/addLocationMapMarker',
    beginAddMarkerWorkflow: 'locationMap/beginAddMarkerWorkflow',
    endAddMarkerWorkflow: 'locationMap/endAddMarkerWorkflow',
    removeLocationMapMarker: 'locationMap/removeLocationMapMarker',
    inlineUpdateMarker: 'locationMap/inlineUpdateMarker',
    updateLocationMapMarker: 'locationMap/updateLocationMapMarker',
    focusLocationMapMarker: 'locationMap/focusLocationMapMarker',
    resetStyledMapLayers: 'locationMap/resetStyledMapLayers'
  },
  projectConfig: {
    set: 'projectConfig/set',
    reset: 'projectConfig/reset',
    changeProjectName: 'projectConfig/changeProjectName',
    resetTheme: 'projectConfig/resetTheme',
    registerChangeMade: 'projectConfig/registerChangeMade',
    unsetChangeMade: 'projectConfig/unsetChangeMade',
    toggleInspired: 'projectConfig/toggleInspired',
    toggleUnsavedChangesModal: 'projectConfig/toggleUnsavedChangesModal',
    unpublish: 'projectConfig/unpublish',
    toggleUnpublishModal: 'projectconfig/toggleUnpublishModal',
    showSignupModal: 'projectconfig/showSignupModal',
    getThemes: 'projectconfig/getThemes',
    getCompanyThemes: 'projectconfig/getCompanyThemes',
    assignCompanyTheme: 'projectconfig/assignCompanyTheme',
    updateDataAndConfig: 'projectConfig/updateDataAndConfig',
    addDataSeries: 'projectConfig/addDataSeries',
    sortData: 'projectConfig/sortData',
    deleteSeries: 'projectConfig/deleteSeries',
    updateSeriesMapping: 'projectConfig/updateSeriesMapping',
    updateDataAndConfigBulk: 'projectConfig/updateDataAndConfigBulk',
    clearDataGrid: 'projectConfig/clearDataGrid',
    movedColumns: 'projectConfig/movedColumns',
    movedRows: 'projectConfig/movedRows',
    handleDeletedColumn: 'projectConfig/handleDeletedColumn',
    clearColumn: 'projectConfig/clearColumn',
    deleteColumnFromOptions: 'projectConfig/deleteColumnFromOptions',
    updateCSSModules: 'projectConfig/updateCSSModules',
    loadData: 'projectConfig/loadData',
    closeWizard: 'projectConfig/closeWizard'
  },
  themeEditor: {
    saveTheme: 'themeEditor/saveTheme',
    loadTheme: 'themeEditor/loadTheme'
  },
  companyThemeEditor: {
    set: 'companyThemeEditorPage/set',
    saveCompanyTheme: 'companyThemeEditorPage/saveCompanyTheme',
    loadCompanyTheme: 'companyThemeEditorPage/loadCompanyTheme',
    switchProjectType: 'companyThemeEditor/switchProjectType',
    initCompanyThemeEditor: 'companyThemeEditor/initCompanyThemeEditor',
    updateThemeConfig: 'companyThemeEditor/updateThemeConfig',
    resetThemePageSettings: 'companyThemeEditor/resetThemePageSettings',
    changeType: 'companyThemeEditor/changeType'
  },
  app: {
    set: 'app/set',
    openPaidPlanModal: 'app/openPaidPlanModal',
    openPremiumFeatureModal: 'app/openPremiumFeatureModal'
  },
  profile: {
    getProfile: 'profile/getProfile',
    getGroups: 'profile/getGroups',
    getSubscription: 'profile/getSubscription',
    setTeam: 'profile/setTeam',
    setTeams: 'profile/setTeams',
    setSubteam: 'profile/setSubteam',
    setSubteams: 'profile/setSubteams',
    setAll: 'profile/setAll',
    setLoading: 'profile/setLoading',
    setAnnouncements: 'profile/setAnnouncements',
    updateTeams: 'profile/updateTeams',
    deleteTeam: 'profile/deleteTeam',
    reloadDivisions: 'profile/reloadDivisions',
    logout: 'profile/logout',
    updateChartCount: 'profile/updateChartCount',
    getRecentExportHistory: 'profile/getRecentExportHistory',
    deleteRecentExport: 'profile/deleteRecentExport'
  },
  redeemInvite: {
    acceptInvitation: 'account/invitation'
  },
  packagesPage: {
    set: 'packagesPage/set',
    getTeamPackages: 'packagesPage/getTeamPackages',
    loadPackageProject: 'packagesPage/loadPackageProject'
  }
};

export default actionTypes;
