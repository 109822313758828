import { get, isObject } from 'lodash';
import React, { useState } from 'react';
import { Quill } from 'react-quill';
import IconButton from 'shared/buttons/IconButton';
import { customSelectColors } from 'shared/inputs/CustomSelect';
import ColorPickerModal from 'shared/modal/ColorPickerModal';
import { customStyles, richtextStyles } from 'shared/utils/selectStylesHelper';
import useToggle from 'shared/utils/useToggle';
import FontPickerWidget from '../FontPickerWidget';
import SelectWidget from '../SelectWidget';
import {
  defaultFontSizes,
  mapping,
  fontSizes,
  tableMapping,
  alignValues,
  axisAlignValues,
  alignValuePath
} from 'shared/meta/widgets/richtextgroup/richtextGroup';

let fontSizeStyle = Quill.import('attributors/style/size');
fontSizeStyle.whitelist = fontSizes.map((c) => c.value);
Quill.register(fontSizeStyle, true);

export const generateToolbarSettings = ({
  provider,
  type,
  settings,
  id,
  options,
  ref,
  onChange,
  inlineSettings,
  setInlineSettings,
  aggregatedOptions,
  fields,
  className,
  noHighchartOptions
}) => {
  const isChart = type === 'chart';
  const useStyle = !isChart || provider === 'locationMap';
  const useAxisTitleAlignValue =
    settings?.id?.includes('Axis[0].title.text') || settings?.id?.includes('Axis[1].title.text');
  const alignArray = useAxisTitleAlignValue ? axisAlignValues : alignValues;

  const [showColorPickerModal, toggleColorPickerModal] = useToggle();
  const [value, setValue] = useState('');
  const [position, setPosition] = useState({ xPos: 0, yPos: 0 });

  const editConfigProp = (key, value, suffix, isStyle = true, clearProp = true) => {
    const editor = ref.current.getEditor();
    key = useStyle ? tableMapping[key] ?? key : key;
    let newValue = value;
    if (isStyle) {
      if (options?.style?.[key] && clearProp) newValue = null;
      key = `style.${key}`;
    }

    onChange(key, newValue, settings.isOptionalArray);

    if (!options?.style?.[key]) {
      editor.setSelection(0, editor.getText().replace('\n', '').length);
      editor.format(suffix, false, 'user');
    }
  };

  const editInlineProp = (key, value, suffix, isDynamic) => {
    const editor = ref.current.getEditor();
    const hasHighchartsStyling = options?.style?.[key];
    onChange(`style.${key}`, null);
    let selection = editor.selection.savedRange;
    let effectStatus = !editor.getFormat(selection)[suffix];

    if (hasHighchartsStyling) {
      editor.setSelection(0, editor.getText().replace('\n', '').length);
      editor.format(suffix, hasHighchartsStyling, 'user');
      if (selection) {
        editor.setSelection(selection.index, selection.length);
      }
      effectStatus = false;
    }

    if (isDynamic) editor.format(suffix, value, 'user');
    else {
      editor.format(suffix, effectStatus, 'user');
    }
  };

  const handleAlignmentChange = (key, value, suffix) => {
    const adjustValueForQuill = value === 'left' ? '' : value;
    if (settings.id === 'tooltip') editInlineProp(key, adjustValueForQuill, suffix, true);
    else editConfigProp(key, value, suffix, useStyle, false);
  };

  const onClickOption = (overrideOption, changeValue, clearProp) => {
    const editor = ref.current.getEditor();
    let selection = editor.getSelection();
    const allText = editor.getText().replace('\n', '');
    let mappedValue, suffix;

    if (isObject(overrideOption)) {
      mappedValue = overrideOption;
      suffix = overrideOption.suffix;
    } else {
      suffix = overrideOption;
      mappedValue = mapping[suffix];
    }

    if (!noHighchartOptions && (!selection || selection.length === allText.length || selection?.length === 0)) {
      // Selected all text, edit highcharts prop instead so it can be used in a theme
      editConfigProp(mappedValue.key, mappedValue.value, suffix, true, clearProp ?? true);
    } else {
      // Only selected partial text, edit inline and clear the highcharts prop
      if ((!noHighchartOptions && !selection) || selection?.length === 0 || selection === null) {
        editor.setSelection(0, editor.getText().replace('\n', '').length);
      }
      editInlineProp(mappedValue.key, mappedValue.value, suffix, changeValue);
      return false;
    }
  };

  const onClickColor = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setPosition({ xPos: e.clientX, yPos: e.pageY });
    toggleColorPickerModal(true);

    return false;
  };

  const onColorChange = (color) => {
    const editor = ref.current.getEditor();
    let selection = editor.selection.savedRange;

    if (selection) editor.setSelection(selection.index, selection.length);
    const allText = editor.getText().replace('\n', '');
    if (!noHighchartOptions && (!selection || selection.length === allText.length || selection?.length === 0)) {
      // Selected all text, edit highcharts prop instead so it can be used in a theme
      editConfigProp('color', color, 'color', true, false);
    } else {
      // Only selected partial text, edit inline and clear the highcharts prop
      editInlineProp('color', color, 'color', true, false);
    }
    setInlineSettings({
      ...inlineSettings,
      color: color
    });
  };

  // eslint-disable-next-line func-style
  const onSizeChange = function (value) {
    if (value) {
      if (window?.everviz?.responsiveFontOptions) {
        window.everviz.responsiveFontOptions = {};
      }

      const editor = ref.current.getEditor();
      let selection = editor.getSelection() ?? editor.selection.savedRange;
      editor.setSelection(selection.index, selection.length);
      onClickOption(
        {
          key: 'fontSize',
          value: value.val,
          suffix: 'size'
        },
        true,
        false
      );
    }
  };

  const onChangeFont = (value) => editConfigProp('fontFamily', value.val, 'fontFamily', true, false);

  const getLocationMapDefaultFontSize = () => {
    const id = settings?.id?.replace('.text', '.style.fontSize');
    const fontSize = get(aggregatedOptions, id);
    return fontSize ?? '12px';
  };

  const getHighchartsDefaultFontSize = () => {
    const fieldId = settings?.richtext?.rawId.split('.')[0];
    const getFromAggregatedOptions = () => {
      switch (fieldId) {
        case 'title':
        case 'subtitle':
        case 'caption':
        case 'credits':
          return aggregatedOptions[fieldId]?.style?.fontSize || '';
        case 'legend':
          return aggregatedOptions[fieldId]?.itemStyle.fontSize || '';
        case 'xAxis':
        case 'yAxis':
          return aggregatedOptions[fieldId]?.title?.style?.fontSize || '';
      }
    };
    return getFromAggregatedOptions() || defaultFontSizes[fieldId] || '12px';
  };

  const getDefaultFontSizeMap = {
    locationMap: getLocationMapDefaultFontSize,
    highcharts: getHighchartsDefaultFontSize
  };

  const toolbarOptions = {
    toolbar: {
      container: `#${id}`
    }
  };

  const alignValue = get(options, alignValuePath(provider, type));
  const toolbar = (
    <div className={`ql-toolbar ql-snow border-none pb-0 ${className}`} id={`${id}`}>
      <div className="gap-y-2 flex flex-wrap mb-2">
        <div className="flex space-x-1 items-center flex-wrap">
          {fields.includes('bold') && (
            <IconButton
              icon={'bold'}
              onClick={() => onClickOption('bold')}
              className="rounded h-8 w-8"
              active={inlineSettings.fontWeight || options?.style?.fontWeight === 'bold'}
            />
          )}

          {fields.includes('underline') && (
            <IconButton
              icon={'underline'}
              onClick={() => onClickOption('underline')}
              className="rounded h-8 w-8"
              active={inlineSettings.textDecoration || options?.style?.textDecoration === 'underline'}
            />
          )}

          {fields.includes('italic') && (
            <IconButton
              icon={'italic'}
              onClick={() => onClickOption('italic')}
              className="rounded h-8 w-8"
              active={inlineSettings.fontStyle || options?.style?.fontStyle === 'italic'}
            />
          )}

          {fields.includes('fontSize') && (
            <SelectWidget
              options={{}}
              selectOptions={fontSizes}
              onChange={onSizeChange}
              customStyles={{ ...richtextStyles }}
              value={getDefaultFontSizeMap[provider ?? 'highcharts']()}
              inlineSelect={true}
            />
          )}

          {fields.includes('align') && (
            <span className="ql-formats alignment flex space-x-1">
              <IconButton
                icon={alignArray[0].icon}
                onClick={() => handleAlignmentChange('align', alignArray[0].value, 'align')}
                className="rounded h-8 w-8"
                active={alignValue === alignArray[0].value}
              />
              <IconButton
                icon={alignArray[1].icon}
                onClick={() => handleAlignmentChange('align', alignArray[1].value, 'align')}
                className="rounded h-8 w-8"
                active={alignValue === alignArray[1].value}
              />
              <IconButton
                icon={alignArray[2].icon}
                onClick={() => handleAlignmentChange('align', alignArray[2].value, 'align')}
                className="rounded h-8 w-8"
                active={alignValue === alignArray[2].value}
              />
            </span>
          )}

          {fields.includes('color') && (
            <span>
              <button
                onClick={onClickColor}
                className="color-picker-widget border-4 border-solid border-white drop-shadow-color-widget-shadow w-[30px] h-[30px]"
                style={{
                  backgroundColor: (inlineSettings.color || options?.style?.color) ?? 'black'
                }}
              />
            </span>
          )}
        </div>
        {fields.includes('font') && (
          <div className="w-full">
            <FontPickerWidget
              option={{
                ...(settings ?? {}),
                custom: {
                  simple: true
                },
                id: `${settings?.richtext?.rawId ?? 'title'}.style`
              }}
              customStyles={customStyles}
              aggregatedOptions={aggregatedOptions}
              onChange={onChangeFont}
              value={options?.style?.fontFamily ?? 'default'}
              backgroundColor={customSelectColors.grey}
            />
          </div>
        )}

        {showColorPickerModal && (
          <ColorPickerModal
            value={value}
            setValue={setValue}
            isOpen={showColorPickerModal}
            onClose={toggleColorPickerModal}
            onChange={onColorChange}
            showGradientColor={false}
            colors={[]}
            position={position}
          />
        )}
      </div>
    </div>
  );

  return {
    toolbar,
    toolbarOptions
  };
};

export const onChangeSelection = (range, _source, quill, inlineSettings, setInlineSettings) => {
  const delta = quill.getContents(range.index, range.length || 1);
  if (delta.ops && delta.ops.length) {
    // Extract colour and make the toolbar picker the same colour
    const colorProps = delta.ops.find((option) => option.attributes && option.attributes.color);
    const boldProps = delta.ops.find((option) => option.attributes && option.attributes.bold);
    const italicProps = delta.ops.find((option) => option.attributes && option.attributes.italic);
    const underlineProps = delta.ops.find((option) => option.attributes && option.attributes.underline);
    const fontSizeProps = delta.ops.find((option) => option.attributes && option.attributes.size);
    const settings = {
      ...inlineSettings
    };

    settings.color = colorProps ? colorProps.attributes.color : false;
    settings.fontWeight = boldProps ? boldProps.attributes.bold : false;
    settings.fontStyle = italicProps ? italicProps.attributes.italic : false;
    settings.textDecoration = underlineProps ? underlineProps.attributes.underline : false;
    settings.fontSize = fontSizeProps ? fontSizeProps.attributes.size : false;

    setInlineSettings(settings);
  }
};

export const parseValue = (value) => {
  let getNodes = (str) => new DOMParser().parseFromString(str, 'text/html').body;
  let form = getNodes(value);
  const lastElement = form.children[form.children.length - 1];

  if (
    (lastElement.tagName === 'DIV' && lastElement.children.length === 1 && lastElement.children[0].tagName === 'BR') ||
    lastElement.tagName === 'BR'
  ) {
    lastElement.remove();
  }

  return form.innerHTML;
};
