import { SubscriptionPlans } from '../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../types';

const Patternfill: TemplateProps = {
  title: 'Pattern fill',
  description: [
    'Pattern Fill Maps display divided geographical areas or regions that are patterned in relation to an image source. This provides a way to visualise values over a geographical area.'
  ],
  constructor: 'Map',
  thumbnail: 'mappatternfill.png',
  icon: '',
  sampleSets: [],
  validator: false,
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Pro),
  config: {
    chart: {},
    xAxis: {
      minRange: 3500,
      visible: false
    },
    yAxis: {
      visible: false
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            color: ''
          }
        }
      }
    },
    tooltip: {
      useHTML: true,
      borderColor: '#aaa',
      headerFormat: '<b>{point.point.name}</b><br>',
      pointFormat: '<img style="width: 150px; height: 100px;" src=\'{point.options.color.pattern.image}\'>'
    }
  },
  sampleSet: 'mappattern',
  dataValidator: false,
  type: 'patternfill',
  isMap: true,
  advancedParent: 'Pattern fill',
  defaultDataType: 'patternfill',
  basic: {
    order: 6,
    thumbnail: 'pattern_fill.svg',
    icon: 'PatternFill'
  }
};

export default Patternfill;
