import { MarkerClickCallback, MarkerConfig } from '@visual-elements/location-map';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BinIcon from 'static/icons/bin.svg';
import MarkerIcon from 'static/icons/marker.svg';
import {
  UpdateLocationMapMarkerAction,
  removeLocationMapMarkerAction
} from '../../../../pages/ChartEditorPage/actions/locationMap';
import { ProjectConfigLocationMapProps } from '../../../../pages/Editor/reducers/locationMapConfigTypes';
import { getProjectConfig } from '../../../../redux/selectors/projectConfig';
import { ButtonColor } from '../../../buttons/types/ButtonModels';
import AccordionButton from '../../../widgets/AccordionButton';
import SelectWidget from '../../../widgets/SelectWidget';
import TextWidget from '../../../widgets/TextWidget';
import LabelPlacementWidget from '../../../wizard/editor/LabelPlacementWidget';
import { MarkerIconPicker } from '../../../wizard/editor/MarkerIconPicker';
import { SectionNode } from '../SectionNode';

type MarkerSectionHeaderTextProps = { labelText: string | undefined; iconEnabled: boolean };
const MarkerSectionHeaderText = ({ labelText, iconEnabled }: MarkerSectionHeaderTextProps) => {
  const headerText = labelText ?? 'Untitled';
  return (
    <div className="flex flex-row items-center justify-center gap-2">
      {iconEnabled ? (
        <MarkerIcon height="16px" width="16px" />
      ) : (
        <i className="h-4 w-4 text-center fa fa-solid fa-text" />
      )}
      <div>{headerText}</div>
    </div>
  );
};
export type MarkerSectionProps = {
  marker: MarkerConfig;
  sectionKey: string;
  parentSectionKey?: string;
};

export const MarkerSection = ({ marker, sectionKey, parentSectionKey }: MarkerSectionProps) => {
  const { locationMapOptions }: ProjectConfigLocationMapProps = useSelector(getProjectConfig);

  const { markerPresets, editorMapRef: primaryMapRef, markerMetadata } = locationMapOptions;
  const dispatch = useDispatch();

  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!primaryMapRef) return;
    const onMarkerClick: MarkerClickCallback = (id, _, isDouble) => {
      if (id === marker.id && isDouble) inputRef.current?.focus();
    };

    primaryMapRef.on('markerClick', onMarkerClick);

    return () => {
      primaryMapRef?.off('markerClick', onMarkerClick);
    };
  }, [primaryMapRef]);

  const onSelect = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const metadata = markerMetadata[marker.id];

  const markerPreset = markerPresets.find((x) => x.id === metadata.markerPresetId);

  return (
    <SectionNode
      sectionKey={sectionKey}
      parentSectionKey={parentSectionKey}
      showContainer={true}
      smallHeaderText={true}
      headerText={<MarkerSectionHeaderText labelText={marker.label.text} iconEnabled={marker.icon.enabled} />}
      accordionClasses={'bg-white'}
      openedByDefault={false}
      onSelect={onSelect}
    >
      {markerPreset ? (
        <>
          {markerPreset.label.enabled && marker.label.enabled && (
            <TextWidget
              ref={inputRef}
              className="bg-ev-grey"
              onChange={(e) =>
                dispatch(UpdateLocationMapMarkerAction({ field: 'text', text: e.val, markerId: marker.id }))
              }
              value={marker.label.text}
            />
          )}
          <SelectWidget
            label={'Preset'}
            className="bg-ev-grey"
            backgroundColor="bg-ev-grey"
            onChange={(e) =>
              dispatch(
                UpdateLocationMapMarkerAction({ field: 'markerPreset', markerPresetId: e.val, markerId: marker.id })
              )
            }
            selectOptions={Object.values(markerPresets).map((x) => ({ value: x.id, label: x.name }))}
            value={{ label: markerPreset.name, value: markerPreset.id }}
          />
          {markerPreset.label.enabled && marker.label.enabled && (
            <>
              {Object.keys(markerPreset.label.presets).length > 1 && (
                <SelectWidget
                  label={'Style'}
                  className="bg-ev-grey"
                  backgroundColor="bg-ev-grey"
                  onChange={(e) =>
                    dispatch(
                      UpdateLocationMapMarkerAction({ field: 'labelPreset', labelPresetId: e.val, markerId: marker.id })
                    )
                  }
                  selectOptions={markerPreset.label.presets.map((x) => ({ value: x.id, label: x.name }))}
                  value={{ label: metadata.labelPresetName, value: metadata.labelPresetId }}
                />
              )}
              {markerPreset.label.anchors.length > 1 && (
                <LabelPlacementWidget
                  anchor={marker.label.anchor}
                  className="bg-ev-grey"
                  onChange={(e) =>
                    dispatch(
                      UpdateLocationMapMarkerAction({ field: 'labelPlacement', placement: e, markerId: marker.id })
                    )
                  }
                  anchorOptions={markerPreset.label.anchors}
                />
              )}
            </>
          )}

          {metadata.iconSrc && metadata.iconPresetName && markerPreset.icon.enabled && (
            <>
              {markerPreset.icon.presets.length > 1 && (
                <MarkerIconPicker
                  icons={markerPreset.icon.presets}
                  className="bg-ev-grey"
                  selectedIcon={{ name: metadata.iconPresetName, src: metadata.iconSrc }}
                  onSelectIcon={(e) =>
                    dispatch(UpdateLocationMapMarkerAction({ field: 'icon', iconPresetId: e, markerId: marker.id }))
                  }
                />
              )}
            </>
          )}
        </>
      ) : (
        // if marker preset has been deleted
        <>
          {marker.label.enabled && (
            <TextWidget
              ref={inputRef}
              className="bg-ev-grey"
              onChange={(e) =>
                dispatch(UpdateLocationMapMarkerAction({ field: 'text', text: e.val, markerId: marker.id }))
              }
              value={marker.label.text}
            />
          )}
          <SelectWidget
            label={'Preset'}
            className="bg-ev-grey"
            backgroundColor="bg-ev-grey"
            onChange={(e) =>
              dispatch(
                UpdateLocationMapMarkerAction({ field: 'markerPreset', markerPresetId: e.val, markerId: marker.id })
              )
            }
            selectOptions={Object.values(markerPresets).map((x) => ({ value: x.id, label: x.name }))}
            value={{ label: metadata.markerPresetName, value: metadata.markerPresetId }}
          />
        </>
      )}

      <AccordionButton
        handleClick={() => dispatch(removeLocationMapMarkerAction({ id: marker.id }))}
        label="Delete this marker"
        size={16}
        buttonIcon={BinIcon}
        buttonColor={ButtonColor.White}
        iconClasses="p-2 rounded-full mx-2"
      />
    </SectionNode>
  );
};
