import { SubscriptionPlans } from '../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from './../types';

const Stock: TemplateProps = {
  title: 'Stock',
  description: '',
  constructor: 'StockChart',
  thumbnail: 'awuhad.svg',
  icon: '',
  sampleSets: ['line-series-dates'],
  validator: false,
  config: {
    chart: {
      type: 'line',
      polar: false
    },
    rangeSelector: {
      enabled: true
    }
  },
  sampleSet: 'stock',
  defaultDataType: 'stock-line',
  advancedParent: 'Stock',
  basic: {
    order: 15,
    thumbnail: 'stacked_area_graph.svg',
    icon: 'StackedArea'
  },
  dataValidator: false,
  parent: 'Stock',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Pro)
};

export default Stock;
