import { getBillingDetailsAction, setPlansPageAction } from 'pages/SubscriptionPage/actions/plansPage';
import BillingHistorySection from 'pages/SubscriptionPage/components/BillingHistorySection';
import paths from 'paths';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from 'redux/store';
import TextButton from 'shared/buttons/TextButton';
import SignupModal from 'shared/modal/SignupModal';
import ProfilePageContainer from 'shared/profile/ProfilePageContainer';
import ProfilePageSectionContainer from 'shared/profile/ProfilePageSectionContainer';
import ProfilePageWhiteSectionContainer from 'shared/profile/ProfilePageWhiteSectionContainer';
import Subheader from 'shared/profile/Subheader';
import useToggle from 'shared/utils/useToggle';
import { goToPage } from 'utils/profileHelper';
import ErrorMessage from '../../shared/message/ErrorMessage';
import BillingDetailsSection from './components/BillingDetailsSection';
import PlansSection from './components/PlansSection';
import { subscriptionPlans } from './meta/subscriptionPlans';

const SubscriptionPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { subscription, team, chartsCreated } = useSelector((state: RootState) => state.profile);
  const { error } = useSelector((state: RootState) => state.plansPage);
  const [checkout, toggleCheckout] = useToggle(false);
  const [editingPaymentMethod, setEditingPaymentMethod] = useState(false);
  const isLoggedIn = !!team;
  const dispatch = useDispatch();

  useEffect(() => {
    toggleCheckout(false);
    if (team) {
      dispatch(getBillingDetailsAction({ team }));
      history.push({ pathname: paths.profileSubscription, search: location.search });
    }
  }, [team]);

  useEffect(() => {
    if (location.search.includes('plan_id') && !checkout) {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      openCheckout(Number(query.plan_id));
    }
  }, [location]);

  const editPaymentMethod = () => {
    setEditingPaymentMethod(true);
    toggleCheckout(true);
  };

  const setPlan = (planCode: number) => {
    dispatch(setPlansPageAction({ activeSubscription: subscriptionPlans[planCode], planCode }));
  };

  const openCheckout = (planCode: number) => {
    setEditingPaymentMethod(false);
    toggleCheckout(true);
    setPlan(planCode);
  };

  const closeCheckout = () => {
    toggleCheckout(false);
    history.push({ search: '' });
  };

  return (
    <ProfilePageContainer
      title="Subscription"
      header={isLoggedIn && <>Subscription</>}
      description={
        isLoggedIn && (
          <>
            Use this page to upgrade your account, get a summary of your current plan, or download one of your previous
            invoices.{' '}
            <TextButton
              onClick={() => goToPage('https://help.everviz.com/article/825-subscription-settings')}
              text="More information about this page can be found in our knowledge base."
            />
          </>
        )
      }
    >
      {!team && <SignupModal headerText="Subscription" />}
      <div id="subscription-page">
        <ErrorMessage error={error} />
        {isLoggedIn && subscription && (
          <ProfilePageSectionContainer>
            <Subheader>Summary</Subheader>
            <BillingDetailsSection
              subscription={subscription}
              team={team}
              chartsCreated={chartsCreated}
              openCheckout={openCheckout}
              editPaymentMethod={editPaymentMethod}
            />
          </ProfilePageSectionContainer>
        )}
        <ProfilePageWhiteSectionContainer id="plans-container" className="pb-8 mt-16" header="Plans">
          <PlansSection
            checkout={checkout}
            setPlan={setPlan}
            closeCheckout={closeCheckout}
            openCheckout={openCheckout}
            closeEditing={() => setEditingPaymentMethod(false)}
            editingPaymentMethod={editingPaymentMethod}
          />
        </ProfilePageWhiteSectionContainer>
        {isLoggedIn && (
          <ProfilePageSectionContainer className="mt-16">
            <Subheader>Billing history</Subheader>
            <BillingHistorySection />
          </ProfilePageSectionContainer>
        )}
      </div>
    </ProfilePageContainer>
  );
};

export default SubscriptionPage;
