import { redrawProjectAction } from 'pages/ChartEditorPage/actions/chartEditor';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Accordion from 'shared/Accordion';
import IconButton from 'shared/buttons/IconButton';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { InputChangeParams } from 'shared/types/commonPropTypes';
import MapApi, { LocatorResult } from '../../../editor/core/highed.mapapi';
import InputWidget from '../../widgets/InputWidget';
import { extractPoints, handlerMap } from '../utils/pointMapSearchHelper';
import LocatorModal from './LocatorModal';

const PointMapSearch = () => {
  const dispatch = useDispatch();
  const { aggregatedOptions, provider, dataOptions } = useSelector((state: RootState) => state.projectConfig);
  const [placeValue, setPlaceValue] = useState('');
  const [showLocatorModal, setShowLocatorModal] = useState(false);
  const [locatorResult, setLocatorResults] = useState<LocatorResult[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const mapApi = MapApi();

  const data = {
    locationMap: dataOptions,
    highcharts: aggregatedOptions?.data?.csv
  };

  useEffect(() => {
    dispatch(redrawProjectAction({ noAnimation: true }));
  }, []);

  const onChange = (params: InputChangeParams) => {
    setPlaceValue(params.val as string);
  };

  const searchForLocation = () => {
    if (!placeValue) {
      setErrorMessage("It looks like you didn't enter a search term. Please enter a place name to continue");
      return;
    }

    mapApi.getLatLong(placeValue, (latlongResults: LocatorResult[] | false) => {
      if (!latlongResults) {
        setErrorMessage(
          `Your search for '${placeValue}' did not match any places. Be more specific or try another location.`
        );
        return;
      }
      setShowLocatorModal(true);
      setLocatorResults(latlongResults);
      setErrorMessage(undefined);
    });
  };

  const closeLocatorModal = () => {
    setShowLocatorModal(false);
    setLocatorResults([]);
  };

  const deletePoint = (index: number) => dispatch(handlerMap[provider].deletePoint({ index }));
  const addPoint = (location: LocatorResult) => {
    dispatch(handlerMap[provider].addPoint({ location }));
    setPlaceValue('');
  };

  const points = extractPoints(provider, data[provider]) ?? [];

  return (
    <>
      <LocatorModal
        showLocatorModal={showLocatorModal}
        closeLocatorModal={closeLocatorModal}
        addPoint={addPoint}
        locatorResults={locatorResult}
      />
      <Accordion headerText="Add/remove data points" useInternalSelect={true} defaultSelection={true}>
        <div className="text-sm text-ev-navy-blue">Address/place:</div>

        <div className="flex mb-4 w-full">
          <InputWidget
            className="rounded-r-none w-full"
            onChange={onChange}
            option={{}}
            value={placeValue}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === 'Enter') searchForLocation();
            }}
          />
          <PrimaryButton text="Search" className="rounded rounded-l-none" onClick={searchForLocation} />
        </div>

        {errorMessage && <div className="text-sm text-ev-dark-red">{errorMessage}</div>}

        {points.map((point, i) => {
          return (
            <div
              className="flex items-center justify-between bg-white mb-1 px-2 py-1 font-bold w-full rounded"
              key={`point_${i}`}
            >
              {point || 'Untitled'}
              <IconButton
                className="w-8 h-8 rounded"
                buttonColor={ButtonColor.Transparent}
                onClick={() => deletePoint(i)}
                icon="fa fa-trash"
              />
            </div>
          );
        })}
      </Accordion>
    </>
  );
};

export default PointMapSearch;
