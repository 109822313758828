import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useHistory } from 'react-router-dom';
import paths from 'paths';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import Modal from 'shared/modal/Modal';
import { setTeamInvitationsAction } from '../actions/teamInvitationsAction';

const UsersLimitModal = () => {
  const { isUsersLimitModalOpen } = useSelector((state: RootState) => state.teamInvitationsPage);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleOnClose = () => {
    dispatch(setTeamInvitationsAction({ isUsersLimitModalOpen: false }));
  };

  const handleOnConfirm = () => {
    handleOnClose();
    history.push(paths.profileSubscription);
  };

  return (
    <Modal
      title="Users limit reached"
      width="w-7/12 max-w-xl"
      bg="bg-ev-yellow-1"
      onClose={handleOnClose}
      isOpen={isUsersLimitModalOpen}
    >
      <p className="mb-4">Your team exceeds the limits of the current subscription plan.</p>
      <hr />
      <div className="mt-8 flex gap-4">
        <PrimaryButton onClick={handleOnConfirm} text="Upgrade now" />
        <PrimaryButton onClick={handleOnClose} text="No thanks" buttonColor={ButtonColor.BabyBlue} />
      </div>
    </Modal>
  );
};

export default UsersLimitModal;
