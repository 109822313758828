import { SubscriptionPlans } from '../../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../../types';

const Streamgraph: TemplateProps = {
  title: 'Stream graph',
  description: '',
  constructor: '',
  thumbnail: 'streamgraph.png',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'streamgraph'
    },
    plotOptions: {
      series: {
        label: {
          enabled: true
        }
      }
    },
    yAxis: {
      visible: false,
      startOnTick: false,
      endOnTick: false
    },
    xAxis: {
      lineWidth: 0,
      crosshair: true
    },
    legend: {
      enabled: false
    }
  },
  dataValidator: false,
  plugins: ['streamgraph'],
  sampleSet: 'streamgraph',
  advancedParent: 'Streamgraph',
  basic: {
    order: 29,
    thumbnail: 'stream_graph.svg',
    icon: 'StreamGraph'
  },
  parseData: 'streamgraph',
  parent: 'More',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Pro)
};

export default Streamgraph;
