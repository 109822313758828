import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAction } from 'redux/actions/projectConfig';
import { setSeparatorTab } from 'redux/reducers/wizardReducer';
import { RootState } from 'redux/store';
import LinkDataModal from 'shared/wizard/components/linkingData/LinkDataModal';
import { SectionRoot } from '../../editor/generic/SectionRoot';
import { MarkersSection } from '../../editor/generic/locationMap/MarkersSection';
import PointMapSearch from '../editor/PointMapSearch';
import { setDataMap } from '../utils/dataGridHelper';
import {
  getAllowProps,
  getIsLiveDataSource,
  isUsingChartType,
  onSeriesEnter,
  onSeriesLeave
} from '../utils/seriesHelper';
import { SetDataParams, onUpload } from './../utils/dragAndDropHelper';
import CategoriesTab from './CategoriesTab';
import ExportOptions from './ExportOptions';
import ImportOptions from './ImportOptions';
import PageDimmer from './PageDimmer';
import PasteTooltip from './PasteTooltip';
import SeriesSection from './SeriesSection';
import WordcloudInput from './WordcloudInput';

type DataLeftSectionProps = {
  isWordcloud: boolean;
  children?: React.ReactNode;
  isTable?: boolean;
  inEditor?: boolean;
};

const DataLeftSection = (props: DataLeftSectionProps) => {
  const dispatch = useDispatch();
  const [showLinkDataModal, setShowLinkDataModal] = useState(false);
  const { seriesAssigns, aggregatedOptions, dataConfig, isPastingActive, type, provider } = useSelector(
    (state: RootState) => state.projectConfig
  );
  const { isMap, isThemeEditor } = useSelector((state: RootState) => state.chartEditorPage);
  const { displaySeparatorTab } = useSelector((state: RootState) => state.wizardConfig);
  const { allowAddSeries, seriesTypes } = getAllowProps(aggregatedOptions, props.isTable, provider);

  const isPointMap = seriesTypes.includes('mappoint');
  const isLocationMap = provider === 'locationMap';
  const isBubbleMap = seriesTypes.includes('mapbubble');
  const isPackedBubble = isUsingChartType(aggregatedOptions, 'packedbubble');
  const isChart = type === 'chart';
  const isLiveDataSource = getIsLiveDataSource(aggregatedOptions, dataConfig);
  const getIndexForAssignRawOptions = isPointMap ? 1 : 0;

  const onMouseLeave = useCallback(() => {
    onSeriesLeave(dispatch);
  }, []);

  const onMouseEnter = useCallback((index: number[], series: number[]) => {
    onSeriesEnter(dispatch, index, series);
  }, []);

  const toggleIsPastingActive = (toggle?: boolean) => {
    dispatch(
      setAction({
        isPastingActive: toggle ?? !isPastingActive
      })
    );
  };

  const closeModal = () => {
    setShowLinkDataModal(false);
  };

  const setData = (params: SetDataParams) => {
    dispatch(setDataMap[type](params));
  };

  const onHasData = (options: unknown, dataType: string) => {
    closeModal();
    setData({ options, dataType, cb: closeModal });
  };

  const onClickUpload = () => onUpload(setData, isMap, () => dispatch(setSeparatorTab({ display: true })));

  return (
    <div className={`grid gap-2 ${props.inEditor ? 'ev-sm:w-[332px]' : 'ev-md:mr-2'}`}>
      <LinkDataModal isOpen={showLinkDataModal} onClose={closeModal} onHasData={onHasData} isChart={isChart} />
      {isPointMap && <PointMapSearch />}
      {isLocationMap && (
        <SectionRoot>
          <MarkersSection openedByDefault={true} />
        </SectionRoot>
      )}
      {isPastingActive && (
        <>
          <PasteTooltip onClick={() => toggleIsPastingActive()} />
          <PageDimmer onClick={() => toggleIsPastingActive()} />
        </>
      )}

      {!isLiveDataSource && !props.isWordcloud && (
        <>
          <CategoriesTab
            setActiveColumns={onMouseEnter}
            assignOption={seriesAssigns[getIndexForAssignRawOptions]}
            onMouseLeave={onMouseLeave}
            seriesAssignsIndex={getIndexForAssignRawOptions}
          />
          <SeriesSection
            setActiveColumns={onMouseEnter}
            onMouseLeave={onMouseLeave}
            allowAddSeries={allowAddSeries}
            isAlternativeMapping={isMap && (isBubbleMap || isPointMap)}
            type={type}
            seriesTypes={seriesTypes}
          />
        </>
      )}

      {props.isWordcloud && <WordcloudInput />}

      {props.inEditor && <hr className="border-ev-dark-purple my-4" />}

      <div className="flex flex-col gap-2 min-w-0">
        {!props.isWordcloud && !isLocationMap && (
          <ImportOptions
            isChart={isChart}
            isMap={isMap}
            togglePasting={toggleIsPastingActive}
            setShowLinkDataModal={setShowLinkDataModal}
            isPackedBubble={isPackedBubble}
            onClickUpload={onClickUpload}
            aggregatedOptions={aggregatedOptions}
            dataConfig={dataConfig}
            isLiveDataSource={isLiveDataSource}
            displaySeparatorTab={displaySeparatorTab}
          />
        )}

        {props.inEditor && !isThemeEditor && <ExportOptions />}
      </div>
    </div>
  );
};

export default DataLeftSection;
