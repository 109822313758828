import config from '../config';
const ajax = {};

let url = `${config?.backend.hostname}`;

if (`${config?.backend.port}`) {
  url += ':' + `${config?.backend.port}`;
}

let token = false;

const responseToErrorJson = (r) => {
  let response = r.responseText;

  try {
    response = JSON.parse(response);
  } catch (e) {
    // eslint-disable no-empty
    // Simply return response as-is
  }

  return {
    status: r.status,
    response: response,
    toString: () => {
      return r.status + ': ' + r.responseText;
    }
  };
};

const cookieNamespace = 'everviz_session';
const cookieMonster = {};

cookieMonster.get = () => {
  let cookie;
  document.cookie.split(';').some((pair) => {
    pair = pair.split('=');
    if (pair.length > 1 && pair[0].trim() === cookieNamespace) {
      cookie = pair[1].trim();
      return true;
    }
    return false;
  });
  return cookie;
};

cookieMonster.args = location.hostname.includes('everviz')
  ? 'Secure; SameSite=Lax; HostOnly=False; Domain=everviz.com;'
  : 'Secure; SameSite=Lax; HostOnly=False;';

cookieMonster.set = (value, expireTime) => {
  if (!expireTime) {
    expireTime = new Date(Date.now() + 24 * 60 * 60 * 1000); // Tomorrow
  }

  document.cookie = `${cookieNamespace}=${value}; ${cookieMonster.args} path=/; expires=${expireTime.toString()}`;
};

cookieMonster.delete = () => {
  document.cookie = `${cookieNamespace}=; ${cookieMonster.args} path=/; expires=${new Date(0).toString()}`;
};

ajax.getURL = () => {
  return url;
};

ajax.setURL = (u) => {
  url = u;
};

ajax.ajax = (props) => {
  let properties = Object.assign(
    {
      url: false,
      type: 'get',
      dataType: 'json'
    },
    props
  );

  const isExternal = properties.url.indexOf('https://') > -1 || properties.url.indexOf('http://') > -1;

  return new Promise((resolve, reject) => {
    let r = new XMLHttpRequest();
    if (!properties.url) {
      return reject('no url specified');
    }
    r.open(properties.type, isExternal ? properties.url : url + properties.url, true);

    if (!properties.stopHeaders) {
      if (!properties.uploadFile) {
        r.setRequestHeader('Content-Type', 'application/json');
      }

      if (token) {
        r.setRequestHeader('X-Auth-Token', token);
      }
    }

    if (properties.headers) {
      Object.keys(properties.headers).forEach((key) => {
        r.setRequestHeader(key, properties.headers[key]);
      });
    }

    r.onreadystatechange = () => {
      if (r.readyState === 4) {
        let json = false;

        if (r.status === 200) {
          if (properties.dataType === 'json') {
            try {
              json = JSON.parse(r.responseText);
            } catch (e) {
              return reject({
                status: 200,
                message: 'unexpected return: ' + e
              });
            }
          } else json = r.responseText;

          if (json.error) {
            return reject({
              status: 200,
              message: json.message
            });
          }
          return resolve(json);
        }

        try {
          json = JSON.parse(r.responseText);
        } catch (e) {
          return reject(responseToErrorJson(r));
        }

        return reject(responseToErrorJson(r));
      }
    };

    if (properties.data) {
      try {
        properties.data = JSON.stringify(properties.data);
      } catch (e) {
        // Do nothing
      }
    }

    if (properties.uploadFile) {
      r.send(props.data);
    } else {
      r.send(properties.data);
    }
  });
};

ajax.download = (props, blobType = 'application/pdf', fileName = 'invoice.pdf') => {
  let properties = Object.assign(
    {
      url: false,
      type: 'get'
    },
    props
  );

  return new Promise((resolve, reject) => {
    let r = new XMLHttpRequest();
    if (!properties.url) {
      return reject('no url specified');
    }
    r.open(properties.type, url + properties.url, true);
    r.setRequestHeader('Content-Type', 'application/json');

    if (token) {
      r.setRequestHeader('X-Auth-Token', token);
    }

    r.responseType = 'arraybuffer';

    if (properties.headers) {
      Object.keys(properties.headers).forEach((key) => {
        r.setRequestHeader(key, properties.headers[key]);
      });
    }

    r.onload = function () {
      let arrayBuffer = r.response;

      if (arrayBuffer) {
        let byteArray = new Uint8Array(arrayBuffer);
        let blob = new Blob([byteArray], {
          type: blobType
        });

        let link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        link.click();
        resolve();
      }
    };

    if (properties.data) {
      try {
        properties.data = JSON.stringify(properties.data);
      } catch (e) {
        // Do nothing
      }
    }

    r.send(properties.data);
  });
};

ajax.isLoggedIn = () => {
  if (!token) {
    return new Promise((resolve) => {
      return resolve({
        loggedIn: false
      });
    });
  }

  // TODO: Check session expiry etc here before doing a get

  return ajax.ajax({
    url: '/info/loggedin/'
  });
};

ajax.login = (username, password, options = {}) => {
  return ajax
    .ajax({
      url: '/login',
      type: 'post',
      data: {
        username: username,
        password: password,
        ...options
      }
    })
    .then((result) => {
      if (result.token) {
        token = result.token;
        // TODO Temporary
        cookieMonster.set(token); // TODO add exipreTime
        return {
          announcements: result.announcements,
          user_id: result.user_id
        };
      } else {
        throw 'Did not get a login token';
      }
    });
};

ajax.logout = () => {
  return ajax
    .ajax({
      url: '/logout',
      type: 'post'
    })
    .then((result) => {
      if (result.ok) {
        token = false;
        cookieMonster.delete();
      } else {
        throw 'Unable to log out - maybe not logged in to begin with';
      }
    });
};

ajax.unpackCookie = () => {
  if (!document) {
    return;
  }

  token = cookieMonster.get();
};

ajax.assignToken = (newToken) => {
  token = newToken;
  cookieMonster.set(token);
};

// ???
ajax.unpackCookie();

export default ajax;
