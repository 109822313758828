import React from 'react';
import paths from 'paths';
import { projectsPlaceholders } from 'shared/utils/projectsPlaceholders';
import Card from 'shared/Card';

type PackageCardProps = {
  chart: any;
  packageUuid: string;
};

export default function PackageCard(props: PackageCardProps) {
  const { chart, packageUuid } = props;
  const placeholder = projectsPlaceholders[0];

  const goToPackageEditor = () => {
    return paths.editPackage.replace(':packageuuid', packageUuid).replace(':projectid', chart.uuid);
  };

  return (
    <Card
      title={chart.name}
      thumbnail={chart.thumbnail_url}
      hideHoverDescriptor={false}
      highlighted={false}
      href={({ ...ps }) => (
        <a {...ps} href={goToPackageEditor()}>
          {ps.children}
        </a>
      )}
      placeholder={placeholder}
    />
  );
}
