import React, { useEffect } from 'react';
import SideContainer from '../../../shared/message/SideContainer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import ChannelSectionContent from '../../../shared/wizard/components/publishChannel/ChannelSectionContent';
import PermissionModal from 'shared/modal/PermissionModal';
import { checkCreatePermission, checkPermission } from '../../../utils/profileHelper';
import useToggle from '../../../shared/utils/useToggle';
import { onPublish } from '../../../shared/editor/publishHelper';
import HighchartsPreview from '../../../shared/wizard/data/HighchartsPreview';
import { loadChartAction } from '../../ChartEditorPage/actions/chartEditor';
import { loadTableAction } from 'pages/TableEditorPage/actions/tableEditor';
import { loadLayoutAction } from 'pages/LayoutEditorPage/actions/layoutEditor';

type QuickExportProps = {
  isOpen: boolean;
  onClose: () => void;
  header: string;
};

const QuickExport = ({ isOpen, onClose, header }: QuickExportProps) => {
  const { type } = useSelector((state: RootState) => state.projectConfig);
  const [showPermissionModal, toggleShowPermissionModal] = useToggle();
  const hasPublishPermission = checkPermission('publish');
  const hasCreatePermission = checkCreatePermission(type);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      if (type === 'chart') {
        dispatch(loadChartAction({}));
      } else if (type === 'table') {
        dispatch(loadTableAction({}));
      } else {
        dispatch(loadLayoutAction({}));
      }
    }
  }, [isOpen]);

  const handlePublish = () => {
    if (!hasPublishPermission || !hasCreatePermission) {
      toggleShowPermissionModal(true);
    } else {
      onPublish(type, dispatch);
    }
  };

  return (
    <SideContainer isOpen={isOpen} header={header} onClose={onClose}>
      <PermissionModal isOpen={showPermissionModal} onClose={toggleShowPermissionModal} />
      <div className="bg-ev-grey p-4 rounded">
        <ChannelSectionContent onPublish={handlePublish} />
      </div>

      {isOpen && (
        <div className="hidden">
          <HighchartsPreview />
        </div>
      )}
    </SideContainer>
  );
};

export default QuickExport;
