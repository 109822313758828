import { merge } from 'highcharts';
import EditorPublish from 'pages/ChartEditorPage/components/EditorPublish';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { useParams } from 'react-router-dom';
import { closeWizardAction, resetProjectConfigAction } from 'redux/actions/projectConfig';
import { RootState } from 'redux/store';
import OptionsPanelContainer from 'shared/editor/generic/OptionsPanelContainer';
import ProjectContainer from 'shared/editor/generic/ProjectContainer';
import WorkspaceBar from 'shared/editor/generic/WorkspaceBar';
import SmallScreenMessage from 'shared/message/SmallScreenMessage';
import OptionsPanel, { PanelOptionProps } from '../../shared/editor/generic/OptionsPanel';
import Wizard from '../../shared/editor/generic/Wizard';
import CMSModal from '../../shared/modal/CMSModal';
import { useIsMobile } from '../../shared/utils/mediaHelper';
import GlobalModals from './../../shared/wizard/editor/GlobalModals';
import {
  closePublishModalAction,
  initEditorAction,
  loadLayoutAction,
  resetStateAction,
  setAction,
  toggleCMSModalAction
} from './actions/layoutEditor';
import LayoutComponent from './containers/LayoutComponent';
import CustomizeOptions from './meta/CustomizeOptions';
import panelOptions from './meta/PanelOptions';
import WizardOptions from './meta/wizard/WizardOptions';

export default function LayoutEditorPage() {
  const dispatch = useDispatch();
  const params = useParams<any>();
  const { themeOptions, customizedOptions, changeMade, showWizard } = useSelector(
    (state: RootState) => state.projectConfig
  );
  const { storyId, cmsModalOpen } = useSelector((state: RootState) => state.layoutEditorPage);
  const { team } = useSelector((state: RootState) => state.profile);
  const aggregatedOptions = merge<any>(merge({}, themeOptions.options), merge({}, customizedOptions));
  const closeModalMap = {
    wizard: closeWizardAction,
    publish: closePublishModalAction
  };

  useEffect(() => {
    Highcharts.setOptions({
      stockTools: {
        gui: {
          enabled: false
        }
      }
    });

    let newLayout = true;
    const storyId = params.storyid && parseInt(params.storyid, 10);
    if (storyId) {
      dispatch(loadLayoutAction({ team, storyId }));
      newLayout = false;
      dispatch(setAction({ storyId }));
    }

    dispatch(initEditorAction({ newLayout }));
    dispatch(setAction({ cmsModalOpen: location.search.includes('cms') }));

    return () => {
      if (location.pathname.match('(.edit.)([-0-9]+)') === null) {
        dispatch(resetStateAction());
        dispatch(resetProjectConfigAction());
      }
    };
  }, []);

  useEffect(() => {
    if (team.id && storyId) {
      dispatch(loadLayoutAction({ team, storyId }));
    }
  }, [team]);

  useEffect(() => {
    const animation = aggregatedOptions.layout?.animation;
    if (animation !== undefined) {
      Highcharts.setOptions({
        plotOptions: {
          series: { animation }
        },
        chart: { animation }
      });
    }
  }, [aggregatedOptions]);

  const closeModal = (type: string) => {
    dispatch(closeModalMap[type as keyof typeof closeModalMap]());
  };

  const check = () => {
    return !location.pathname.includes('layout') && changeMade
      ? 'Leaving this page will result in unsaved changes being deleted. Are you sure you want to continue?'
      : true;
  };

  const closeCMSModal = () => {
    dispatch(toggleCMSModalAction({ toggle: false }));
  };

  const isMobile = useIsMobile();

  return (
    <div className="story flex flex-wrap">
      <Prompt when={true} message={check} />

      <CMSModal open={cmsModalOpen} close={closeCMSModal} />
      {showWizard && (
        <>
          <Wizard close={closeModal} wizardOptions={WizardOptions} isMap={false} />
          <SmallScreenMessage />
        </>
      )}

      {!showWizard && (
        <>
          {isMobile && <EditorPublish />}
          <WorkspaceBar panelOptions={panelOptions} />

          <div className="flex w-full flex-col ev-sm:flex-row">
            <OptionsPanelContainer>
              <OptionsPanel
                options={aggregatedOptions}
                panelOptions={panelOptions as unknown as PanelOptionProps[]}
                customizeOptions={CustomizeOptions}
              />
            </OptionsPanelContainer>
            <ProjectContainer>
              <LayoutComponent />
            </ProjectContainer>
          </div>
          <SmallScreenMessage />
        </>
      )}
      <GlobalModals />
    </div>
  );
}
