import { redrawProjectAction } from 'pages/ChartEditorPage/actions/chartEditor';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAction } from 'redux/actions/projectConfig';
import { setSeparatorTab } from 'redux/reducers/wizardReducer';
import { RootState } from 'redux/store';
import AlertMessage from 'shared/message/AlertMessage';
import DataLeftSection from '../components/DataLeftSection';
import ChartPreview from '../data/ChartPreview';
import DataGrid from '../data/DataGrid';
import WizardProjectPreview from '../data/WizardProjectPreview';
import { setDataMap } from '../utils/dataGridHelper';
import { SetDataParams, handleOnDrop, onDragOver } from '../utils/dragAndDropHelper';
import { getIsLiveDataSource, isUsingChartType } from '../utils/seriesHelper';
import WizardContainer from './WizardContainer';
import WizardLeftContainer from './WizardLeftContainer';
import LiveDataMessage from 'shared/message/LiveDataMessage';
import WizardRightContainer from './WizardRightContainer';

const ImportData = () => {
  const dispatch = useDispatch();
  const containerRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const {
    activeColumns,
    aggregatedOptions,
    isPastingActive,
    mapCodeErrors,
    mapExtraColumns,
    type,
    provider,
    dataConfig
  } = useSelector((state: RootState) => state.projectConfig);
  const { isMap } = useSelector((state: RootState) => state.chartEditorPage);
  const isWordcloud = isUsingChartType(aggregatedOptions, 'wordcloud');
  const isLiveDataSource = getIsLiveDataSource(aggregatedOptions, dataConfig);
  const isChart = type === 'chart';
  const isTable = type === 'table';

  useEffect(() => {
    if (isChart) dispatch(redrawProjectAction({ noAnimation: true }));
  }, []);

  const toggleIsPastingActive = (toggle?: boolean) => {
    dispatch(
      setAction({
        isPastingActive: toggle ?? !isPastingActive
      })
    );
  };

  const setData = (params: SetDataParams) => {
    dispatch(setDataMap[type](params));
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    handleOnDrop(e, setData, isMap, () => {
      toggleIsPastingActive(false);
      dispatch(setSeparatorTab({ display: true }));
    });
  };

  const mapErrorsCount = (mapCodeErrors ?? []).length;
  const mapExtraColumnsCount = (mapExtraColumns ?? []).length;
  const singularExtraCount = mapExtraColumnsCount === 1;
  const singularErrorCount = mapErrorsCount === 1;

  const isLocationMap = provider === 'locationMap';
  const disableDataGrid = isWordcloud || isLocationMap;

  return (
    <WizardContainer>
      <WizardLeftContainer
        text={
          isLocationMap
            ? 'Add markers to your map'
            : 'This is the data structure for this project type. Import your own data or edit the datasheet here.'
        }
        header={isLocationMap ? 'Markers' : 'Data'}
        useWhiteBackground={true}
      >
        <DataLeftSection isWordcloud={isWordcloud} isTable={isTable} />
      </WizardLeftContainer>
      <WizardRightContainer showToolbar={true}>
        {isLocationMap ? (
          <WizardProjectPreview showBottomBar={true} />
        ) : (
          <div
            className="relative everviz-datagrid ev-sm-max:h-full ev-sm-max:overflow-hidden ev-sm:min-h-[500px]"
            onDrop={onDrop}
            onDragOver={onDragOver}
            ref={containerRef}
          >
            {isLiveDataSource && <LiveDataMessage />}
            {!disableDataGrid && <DataGrid togglePasting={toggleIsPastingActive} isTable={isTable} />}
            <ChartPreview
              activeSeries={activeColumns.series}
              isTable={isTable}
              isPastingActive={isPastingActive}
              large={disableDataGrid}
              parent={containerRef}
            />
          </div>
        )}

        <div className="mt-4 flex flex-col gap-1">
          {Boolean(mapErrorsCount) && (
            <AlertMessage
              type="warning"
              hideTag={true}
              dismissable={false}
              text={
                <>
                  We could not match {mapErrorsCount} {singularErrorCount ? 'entry' : 'entries'} from your dataset.{' '}
                  {singularErrorCount ? 'This has' : 'These have'} not been used in the map.
                </>
              }
            />
          )}

          {Boolean(mapExtraColumnsCount) && (
            <AlertMessage
              type="warning"
              hideTag={true}
              dismissable={false}
              text={
                <>
                  Your dataset did not include values for {mapExtraColumnsCount}
                  {singularExtraCount ? ' entry' : ' entries'} from the map.{' '}
                  {singularExtraCount ? 'This has' : 'These have'} been added to the bottom of the table.
                </>
              }
            />
          )}
        </div>
      </WizardRightContainer>
    </WizardContainer>
  );
};

export default ImportData;
