import React, { MouseEvent, useEffect, useState } from 'react';
import ProfilePageSectionContainer from 'shared/profile/ProfilePageSectionContainer';
import Subheader from 'shared/profile/Subheader';
import Table from 'shared/table/Table';
import {
  deleteInvitationsAction,
  setTeamInvitationsAction,
  setInvitationsAction,
  getInvitationsAction,
  resendInvitationsAction
} from '../actions/teamInvitationsAction';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Modal from 'shared/modal/Modal';
import TeamInvitationsForm from './TeamInvitationsForm';
import config from '../../../config';
import { invitationsTableColumns } from '../meta/tableColumnsData';
import { InvitationsSectionProps, InvitationsTableRowsProps } from '../types/teamPageModels';

const InvitationsSection = ({ team, subscription, groups, hasPermission, division }: InvitationsSectionProps) => {
  const {
    invitations,
    page,
    pageCount,
    pageSize,
    dataReturned,
    modalIsOpen,
    emailText,
    groupId,
    inviteError,
    priceTotal,
    unitPrice
  } = useSelector((state: RootState) => state.teamInvitationsPage);

  const [invitationsTableRows, setInvitationsTableRows] = useState<InvitationsTableRowsProps>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Array.isArray(invitations)) {
      setInvitationsTableRows(
        invitations.map((invitation) => ({
          id: invitation.id,
          email: invitation.email,
          created: invitation.created,
          expire_time: invitation.expire_time
        }))
      );
    }
  }, [invitations]);

  useEffect(() => {
    if (team) {
      dispatch(getInvitationsAction({ team, page, pageSize }));
    }
  }, [team]);

  const handleInvitationsSave = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    dispatch(
      setInvitationsAction({ team, division, emailText, groupId: groupId || groups?.[0]?.id, config, page, pageSize })
    );
  };

  const handleEmailTextUpdate = ({ val }: { val: string }) => {
    dispatch(
      setTeamInvitationsAction({
        emailText: val
      })
    );
  };

  const handleCloseModal = () => {
    dispatch(
      setTeamInvitationsAction({
        modalIsOpen: false
      })
    );
  };

  const handleActiveGroupUpdate = ({ val }: { val: string }) => {
    dispatch(
      setTeamInvitationsAction({
        groupId: val
      })
    );
  };

  const onSelectPage = (updatedPage: number) => {
    dispatch(getInvitationsAction({ team, page: updatedPage, pageSize }));
  };

  const editActions = [
    {
      text: 'Resend invitation',
      action: (rowData: any) => {
        dispatch(resendInvitationsAction({ team, invitations: [rowData] }));
      }
    },
    {
      text: 'Delete invitation',
      action: (rowData: any) => {
        dispatch(deleteInvitationsAction({ team, invitations: [rowData], config, page, pageSize }));
      }
    }
  ];

  return (
    <ProfilePageSectionContainer className="mt-12">
      <Modal
        title="Invite team members"
        width="w-7/12 max-w-xl"
        bg="bg-ev-yellow-1"
        onClose={handleCloseModal}
        isOpen={modalIsOpen}
      >
        <TeamInvitationsForm
          value={emailText}
          onSave={handleInvitationsSave}
          onChange={handleEmailTextUpdate}
          onClose={handleCloseModal}
          groups={groups}
          groupId={String(groupId)}
          updateActiveGroup={handleActiveGroupUpdate}
          inviteError={inviteError}
          priceTotal={priceTotal / 100}
          unitPrice={unitPrice / 100}
          subscription={subscription}
          teamName={(team as any)?.name}
        />
      </Modal>
      <Subheader>Invitations</Subheader>
      <Table
        columns={invitationsTableColumns}
        rows={invitationsTableRows}
        selectPage={onSelectPage}
        page={page}
        pageSize={pageSize}
        pageCount={pageCount}
        dataReturned={dataReturned}
        hasEditPermission={hasPermission}
        editActions={editActions}
      />
    </ProfilePageSectionContainer>
  );
};

export default InvitationsSection;
