import { SubscriptionPlans } from '../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from './../types';

const OHLC: TemplateProps = {
  title: 'OHLC',
  description: '',
  constructor: 'StockChart',
  thumbnail: 'opilip.svg',
  icon: '',
  sampleSets: ['candlestick'],
  validator: false,
  config: {
    chart: {
      type: 'ohlc',
      polar: false
    },
    plotOptions: {
      series: {
        lineWidth: 1,
        pointPadding: 0.2,
        groupPadding: 0.2
      }
    },
    rangeSelector: {
      enabled: true
    }
  },
  advancedParent: 'OHLC',
  defaultDataType: 'stock-ohlc',
  sampleSet: 'ohlc',
  dataValidator: false,
  basic: {
    order: 16,
    thumbnail: 'open-high-low-close.svg',
    icon: 'OHLC'
  },
  parent: 'Stock',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Pro)
};

export default OHLC;
