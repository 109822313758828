import classNames from 'classnames';
import React from 'react';
import StarIcon from 'static/icons/star.svg';
import { DivMouseHandler } from '../../types/commonPropTypes';

type ListOptionsProps = {
  title: string;
  selected: boolean;
  onClick: DivMouseHandler;
  onDoubleClick?: DivMouseHandler;
  className?: string;
  iconClassName?: string;
  icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  children?: React.ReactNode;
  titleClassName?: string;
  locked?: boolean;
};

export default function ListOption(props: ListOptionsProps) {
  const { icon: Icon } = props;
  const classes = classNames(
    'group flex flex-col items-center m-4 cursor-pointer relative template-container ev-sm-max:max-w-[100px]',
    props.className,
    {
      active: props.selected
    }
  );

  const titleClasses = classNames('mt-[10px] font-base text-center text-ev-navy-blue-2', props.titleClassName, {
    ['no-underline font-bold text-ev-navy-blue-2']: props.selected,
    ['font-medium	underline group-hover:text-ev-baby-blue-2']: !props.selected
  });

  return (
    <div
      className={classes}
      onClick={props.onClick}
      onDoubleClick={props.onDoubleClick}
      data-testid="template-selector"
    >
      <div className={`chart-template-thumbnail relative flex ${props.iconClassName}`}>
        {Icon && <Icon />}
        {props.locked && (
          <div className="absolute bottom-0 right-0 bg-ev-navy-blue rounded-full p-1">
            <StarIcon width={17} height={16} />
          </div>
        )}
      </div>

      <div className={titleClasses}>{props.title}</div>
    </div>
  );
}
