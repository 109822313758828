import React from 'react';
import { useSelector } from 'react-redux';
import Table from '../containers/Table';
import { getAggregatedOptions } from '../selectors/tableEditor';
import './../../../../node_modules/react-quill/dist/quill.snow.css';
import ContextMenu from './ContextMenu';
import CreateTable from './CreateTable';
import ExportMenu from './ExportMenu';
import TableExtras from './TableExtras';
import Text from './Text';
import { getIsLiveDataSource } from './../../../shared/wizard/utils/seriesHelper';

export default function TableComponent(props) {
  const { customizedOptions, dataConfig } = useSelector((state) => state.projectConfig);
  const { resolution } = useSelector((state) => state.layoutEditorPage);
  const { bufferDataOptions, isGSheet, showContextMenu } = useSelector((state) => state.tableEditorPage);
  const aggregatedOptions = useSelector((state) => getAggregatedOptions(state.projectConfig));
  const { exporting } = aggregatedOptions ?? {};
  const isLiveDataSource = getIsLiveDataSource(aggregatedOptions, dataConfig);

  const exportEnabled = aggregatedOptions?.exporting?.enabled;
  const canvasStyle = aggregatedOptions.canvas ? aggregatedOptions.canvas.style : null;
  let headerStyle = aggregatedOptions.header ? aggregatedOptions.header.style : null;
  const logoStyle = aggregatedOptions.logo ? aggregatedOptions.logo.style : null;

  const hasExtras =
    aggregatedOptions.table &&
    (aggregatedOptions.table.pagination?.enabled || aggregatedOptions.table.searchable?.enabled);

  const extrasPosition = aggregatedOptions.table?.extra?.position ?? 'top';
  const isExtrasBottom = extrasPosition === 'bottom';

  return (
    <div
      className={
        'canvas-container overflow-auto ev-sm-max:h-full ev-sm:h-[500px]' +
        (aggregatedOptions && aggregatedOptions.layout ? aggregatedOptions.layout.type : '')
      }
      style={resolution}
    >
      <Text
        type="title"
        placeholder="[Insert Title Here]"
        containerClass="upper"
        inputClass={`inline-component ${exportEnabled ? 'mr-[50px]' : ''}`}
      >
        {exporting?.enabled && <ExportMenu options={exporting} aggregatedOptions={aggregatedOptions} />}
      </Text>
      <Text type="subtitle" inputClass="subtitle" containerClass="subtitle" />

      {!isExtrasBottom && hasExtras && <TableExtras aggregatedOptions={aggregatedOptions} isBottom={false} />}

      <div className={`canvas ${isExtrasBottom ? 'pb-0' : 'pt-0'}`} id="canvas" style={canvasStyle}>
        <div className={'section '}>
          {bufferDataOptions.length > 0 && (
            <Table
              headerStyle={headerStyle}
              aggregatedOptions={aggregatedOptions}
              hasExtras={hasExtras}
              isLiveDataSource={isLiveDataSource}
            />
          )}
          {bufferDataOptions.length === 0 && (
            <div className="create-table">
              <div className="create-table-text"> Create table</div>
              <CreateTable setData={props.setData} customizedOptions={customizedOptions} />
            </div>
          )}
        </div>

        {bufferDataOptions.length > 0 && !isGSheet && showContextMenu && <ContextMenu />}
      </div>

      {isExtrasBottom && hasExtras && <TableExtras aggregatedOptions={aggregatedOptions} isBottom={true} />}

      <Text type="caption" inputClass="subtitle" containerClass="caption" />

      {aggregatedOptions?.logo?.url && aggregatedOptions.logo.url !== 'undefined' && (
        <div
          className={'logo-container ' + aggregatedOptions.logo.position}
          style={{ ...logoStyle, width: aggregatedOptions.logo.width, minWidth: aggregatedOptions.logo.minWidth }}
        >
          <img src={aggregatedOptions.logo.url} />
        </div>
      )}
    </div>
  );
}
