import { SubscriptionPlans } from '../../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../../types';

const Windrose: TemplateProps = {
  title: 'Wind rose',
  description: '',
  constructor: '',
  thumbnail: 'windrose.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      polar: true,
      type: 'column'
    },
    pane: {
      size: '85%'
    },
    xAxis: {
      tickmarkPlacement: 'on'
    },
    yAxis: {
      min: 0,
      endOnTick: false,
      showLastLabel: true,
      reversedStacks: false
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        shadow: false,
        groupPadding: 0,
        pointPlacement: 'on'
      }
    }
  },
  sampleSet: 'windrose',
  dataValidator: false,
  advancedParent: 'Wind rose',
  basic: {
    order: 20,
    thumbnail: 'windrose.svg',
    icon: 'WindRose'
  },
  parent: 'Wind rose',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Pro)
};

export default Windrose;
