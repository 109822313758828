import { updateCustomizedAction } from 'pages/ChartEditorPage/actions/chartEditor';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ButtonColor, ButtonType } from 'shared/buttons/types/ButtonModels';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import TextButton from 'shared/buttons/TextButton';
import { InputChangeParams } from 'shared/types/commonPropTypes';
import InputWidget from 'shared/widgets/InputWidget';

type LiveSourceSectionProps = {
  aggregatedOptions: any;
  setShowLinkDataModal: (toggle: boolean) => void;
  dataConfig?: any;
};

const LiveSourceSection = ({ aggregatedOptions, setShowLinkDataModal, dataConfig }: LiveSourceSectionProps) => {
  const dispatch = useDispatch();
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { type } = useSelector((state: RootState) => state.projectConfig);
  const isTable = type === 'table';

  const getValue = () => {
    if (isTable) return dataConfig?.googleSpreadsheetKey ?? aggregatedOptions.data.googleSpreadsheetKey;
    return (
      aggregatedOptions.data.csvURL ??
      aggregatedOptions.data.columnsURL ??
      aggregatedOptions.data.rowsURL ??
      aggregatedOptions.data.googleSpreadsheetKey
    );
  };

  useEffect(() => {
    setLink(getValue());
  }, []);

  useEffect(() => {
    setLink(getValue());
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, [aggregatedOptions]);

  const getType = () => {
    if (dataConfig && 'googleSpreadsheetKey' in dataConfig) return 'googleSpreadsheetKey';
    if ('csvURL' in aggregatedOptions.data) return 'csvURL';
    if ('columnsURL' in aggregatedOptions.data) return 'columnsURL';
    if ('rowsURL' in aggregatedOptions.data) return 'rowsURL';
    if ('googleSpreadsheetKey' in aggregatedOptions.data) return 'googleSpreadsheetKey';
  };

  const onChange = (value: InputChangeParams) => {
    setLink(value.val as string);
  };

  const loadModal = () => {
    setShowLinkDataModal(true);
  };

  const sync = () => {
    setIsLoading(true);
    const type = getType();
    dispatch(
      updateCustomizedAction({
        optionProps: {
          id: `data--${type}`
        },
        val: link
      })
    );
  };

  return (
    <div className="pb-4 w-full">
      <div className="py-2 pr-4 relative text-base font-bold">Data linked from:</div>
      <div className="py-2">
        <InputWidget onChange={onChange} option={{}} value={link} />
      </div>

      <div className="flex">
        <PrimaryButton
          onClick={sync}
          useLoader={{
            default: {
              text: 'Refresh',
              icon: 'rotate'
            },
            loading: {
              text: 'Refreshing',
              check: isLoading
            }
          }}
          buttonColor={ButtonColor.NavyBlue}
          buttonType={ButtonType.Info}
          className="text-sm"
        />
        <TextButton onClick={loadModal} text={'Link new data'} className="ml-auto pt-2" />
      </div>
    </div>
  );
};

export default LiveSourceSection;
