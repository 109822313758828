import { put, takeLatest, all, call } from 'redux-saga/effects';
import { setTeamInvitationsAction, getInvitationsAction, getPlanDetailsAction } from '../actions/teamInvitationsAction';
import actionTypes from '../../../redux/actions/action-types';

import {
  getTeamInvitesWithTeamid,
  postTeamInvitationUsingTeamid,
  delTeamInvitationUsingTeamidAndInviteid,
  postTeamInvitationResendUsingTeamid,
  getTeamSubscriptionSummaryWithTeamid
} from '../../../api/cloudApiGenerated';
import { snackBar } from 'editor/editors/highed.init';

export function* getInvitations(params) {
  let { team, page, pageSize } = params.data;

  yield put(
    setTeamInvitationsAction({
      dataReturned: false
    })
  );

  try {
    let { data: invitations, pageCount } = yield call(getTeamInvitesWithTeamid, team.id, page, pageSize);
    yield put(
      setTeamInvitationsAction({
        invitations,
        inviteError: '',
        page,
        pageCount,
        dataReturned: true
      })
    );
  } catch (error) {
    yield put(
      setTeamInvitationsAction({
        error: 'loading team invites: ' + error.message || error.toString()
      })
    );
  }
}

export function* getPlanDetails(params) {
  let { team, config } = params.data;

  if (!config.billing.enabled) return;

  try {
    let data = yield call(getTeamSubscriptionSummaryWithTeamid, team.id);
    yield put(
      setTeamInvitationsAction({
        unitPrice: data.unitPrice,
        priceTotal: data.priceTotal
      })
    );
  } catch (error) {
    yield put(
      setTeamInvitationsAction({
        error: 'loading sub details: ' + error.message || error.toString()
      })
    );
  }
}

export function* resendInvitations(params) {
  let { team, invitations } = params.data;

  let invitesToResend = [];
  invitations.forEach((invitation) => {
    if (invitation)
      invitesToResend.push(call(postTeamInvitationResendUsingTeamid, team.id, { email: invitation.email }));
  });

  try {
    yield all(invitesToResend);
    snackBar(`${invitesToResend.length > 1 ? 'The invitations have' : 'An invitation has'} been sent successfully.`);
  } catch (error) {
    console.error(error);
  }
}

export function* setInvitations(params) {
  let { team, division, emailText, groupId, config, page, pageSize } = params.data;

  if (groupId === '') {
    yield put(
      setTeamInvitationsAction({
        inviteError: 'Please select a group before continuing.'
      })
    );
    return;
  }

  const trim = emailText.replace(/\s/g, '');
  const emails = trim.split(',');

  let transactions = [];
  emails.forEach((email) => {
    transactions.push(
      call(postTeamInvitationUsingTeamid, team.id, {
        email,
        target_group: groupId,
        division: division.id
      })
    );
  });

  try {
    yield all(transactions);

    yield put(getInvitationsAction({ team, page, pageSize }));
    yield put(getPlanDetailsAction({ team, config }));

    yield put(setTeamInvitationsAction({ groupId: '', emailText: '', modalIsOpen: false }));
    snackBar(`${transactions.length > 1 ? 'The invitations have' : 'An invitation has'} been sent successfully.`);
  } catch (e) {
    yield put(
      setTeamInvitationsAction({
        error: 'sending invite: ' + e.message && e.message.join(' '),
        inviteError: ''
      })
    );
  }
}

export function* deleteInvitation(params) {
  let { team, inviteId, config } = params.data;

  try {
    yield call(delTeamInvitationUsingTeamidAndInviteid, team.id, inviteId);

    yield put(getInvitationsAction({ team }));
    yield put(getPlanDetailsAction({ team, config }));
    yield put(
      setTeamInvitationsAction({
        inviteId: '',
        showCancelInvitation: false
      })
    );
  } catch (error) {
    yield put(
      setTeamInvitationsAction({
        error: 'cancelling invite: ' + error.message && error.message.join(' ')
      })
    );
  }
}

export function* deleteInvitations(params) {
  let { team, invitations, config, page, pageSize } = params.data;

  let invitationsToDelete = [];
  invitations.forEach((invite) => {
    if (invite) invitationsToDelete.push(call(delTeamInvitationUsingTeamidAndInviteid, team.id, invite.id));
  });

  try {
    yield all(invitationsToDelete);
    yield put(getInvitationsAction({ team, page, pageSize }));
    yield put(getPlanDetailsAction({ team, config }));
    yield put(
      setTeamInvitationsAction({
        inviteId: '',
        showCancelInvitation: false
      })
    );
  } catch (error) {
    yield put(
      setTeamInvitationsAction({
        error: 'cancelling invite: ' + error.response.message && error.response.message.join(' ')
      })
    );
  }
}

/** Watch functions */
export function* watchGetInvitations() {
  yield takeLatest(actionTypes.teamInvitationsPage.getInvitations, getInvitations);
}
export function* watchSetInvitations() {
  yield takeLatest(actionTypes.teamInvitationsPage.setInvitations, setInvitations);
}
export function* watchResendInvitations() {
  yield takeLatest(actionTypes.teamInvitationsPage.resendInvitations, resendInvitations);
}
export function* watchDeleteInvitation() {
  yield takeLatest(actionTypes.teamInvitationsPage.deleteInvitation, deleteInvitation);
}
export function* watchDeleteInvitations() {
  yield takeLatest(actionTypes.teamInvitationsPage.deleteInvitations, deleteInvitations);
}
export function* watchGetPlanDetails() {
  yield takeLatest(actionTypes.teamInvitationsPage.getPlanDetails, getPlanDetails);
}

export default function* rootSaga() {
  yield all([
    watchGetInvitations(),
    watchSetInvitations(),
    watchDeleteInvitations(),
    watchDeleteInvitation(),
    watchResendInvitations()
  ]);
}
