import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getProjectConfig } from 'redux/selectors/projectConfig';
import CopyInput from 'shared/inputs/CopyInput';
import RadioGroup from 'shared/inputs/RadioGroup';

type EmbedOptionsProps = {
  active: boolean;
  lastPublishTime: string;
};

export default function EmbedOptions(props: EmbedOptionsProps) {
  const [checkedValue, setCheckedValue] = useState('Script (Recommended)');
  const { embedDetails, type } = useSelector(getProjectConfig);

  let injectCode = embedDetails?.injectCode || '';
  let iframeCode = embedDetails?.iframeCode || '';
  const embed = embedDetails?.iframeURL || '';
  const tabIndex = props.active ? 0 : -1;

  const lastPublish = new Date(props.lastPublishTime);
  const cutoffDate = new Date('2021-06-20 00:00:00');
  if (type === 'chart' && lastPublish && lastPublish < cutoffDate) {
    injectCode = injectCode.replace(/everviz-/g, 'highcharts-');
    iframeCode = iframeCode.replace(/everviz-/g, 'highcharts-');
  }

  const options = [
    {
      name: 'Script (Recommended)',
      value: 'Script (Recommended)',
      activeElement: <CopyInput value={injectCode} tabIndex={tabIndex} className="w-full" />
    },
    {
      name: 'iFrame',
      value: 'iFrame',
      activeElement: <CopyInput value={iframeCode} tabIndex={tabIndex} className="w-full" />
    },
    {
      name: 'Embed link',
      value: 'Embed link',
      activeElement: <CopyInput value={embed} tabIndex={tabIndex} className="w-full" />
    }
  ];

  const onSelectRadioGroup = (val: string) => {
    setCheckedValue(val);
  };

  return (
    <div>
      <RadioGroup
        options={options}
        checked={checkedValue}
        groupName="newOrExistingTeam"
        onSelect={onSelectRadioGroup}
        type={'radio-list'}
        tabIndex={tabIndex}
        extraClass="pr-4"
        selectedClassName="pr-4"
      />
    </div>
  );
}
