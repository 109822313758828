import { merge } from 'editor/core/highcharts-editor';
import { cloneDeep, isArray } from 'lodash';
import paths from 'paths';
import qs from 'qs';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { useHistory } from 'react-router-dom';
import { closeWizardAction } from 'redux/actions/projectConfig';
import SignupModal from 'shared/modal/SignupModal';
import { usePrevious } from 'shared/utils/usePrevious';
import SmallScreenMessage from '../../shared/message/SmallScreenMessage';
import CMSModal from '../../shared/modal/CMSModal';
import GlobalModals from './../../shared/wizard/editor/GlobalModals';
import {
  closeCMSModalAction,
  initEditorAction,
  saveChartAction,
  setAction,
  showAnnotationInMenuAction,
  updateCustomizedAction
} from './actions/chartEditor';
import ChartEditor from './components/ChartEditor';
import HighchartsAnnotations from './components/HighchartsAnnotations';
import { resetReduxState } from 'shared/utils/editorHelper';
import ChartWizard from './../../shared/wizard/editor/ChartWizard';
import { useChart } from './meta/highchartsHelper';

export default function Editor(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { customizedOptions, aggregatedOptions, changeMade, inPackagesMode } = useSelector(
    (state) => state.projectConfig
  );
  const { team, user } = useSelector((state) => state.profile);
  const { resolution } = useSelector((state) => state.layoutEditorPage);
  const prevStatus = usePrevious({ user });
  const chart = useChart();

  const { editorConfig, cmsModalOpen, isMap, unsavedChangesText, editorChartConfig } = useSelector(
    (state) => state.chartEditorPage
  );

  const { showWizard, loadingEditor } = useSelector((state) => state.projectConfig);
  const { anonymous } = props;

  useEffect(() => {
    if (localStorage.getItem('editorTeam') !== null) {
      const editorTeam = JSON.parse(localStorage.getItem('editorTeam'));
      if (editorTeam.id !== team.id) {
        alert('Your team has been temporarily changed to: ' + editorTeam.name);
        set({
          localTeam: editorTeam
        });
      }
    }

    const params = props.match.params;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    init(query, params, history);

    return () => {
      localStorage.removeItem('editorTeam');
      set({
        chartType: null,
        localTeam: null
      });
      if (window?.Everviz?.evervizEditor?.destroy) window.Everviz.evervizEditor.destroy();

      // Reset redux state
      resetReduxState(dispatch);
    };
  }, []);

  useEffect(() => {
    // Used in plotbands helper
    window.EvervizWizard = {
      selectPlotBand: (index, axis, isBand, option) => {
        showAnnotationInMenu('highlight', 0, option);
      },
      updatePlotBands: (rawOption, options, isBand) => {
        let customized = cloneDeep(customizedOptions);
        const type = isBand ? 'plotBands' : 'plotLines';

        if (!isBand) {
          options.value = options.from;
          delete options.from;
          delete options.to;
        }

        let plotBands = [];
        const customizedAxis = customized[rawOption.axisType];
        const axis = isArray(customizedAxis) ? customizedAxis[0] : customizedAxis;
        const index = axis[type].findIndex((val) => val.id === rawOption.option.id);

        if (customizedAxis) {
          plotBands = (isArray(customizedAxis) ? customizedAxis[0][type] : customizedAxis[type]) || [];
        }
        if (plotBands[index]) {
          plotBands[index] = merge(plotBands[index], options);
        }

        if (isArray(customizedAxis)) {
          updateCustomizedProp(`${rawOption.axisType}[0]`, customizedAxis[0], null, null);
        } else updateCustomizedProp(rawOption.axisType, customizedAxis, null, null);
      }
    };
  }, [chart]);

  useEffect(() => {
    const loggedOut = user === undefined && prevStatus?.user !== undefined;
    if (loggedOut) history.push(paths.frontPage);
  }, [user]);

  const closeWizard = () => dispatch(closeWizardAction());
  const set = (params) => dispatch(setAction(params));
  const init = (query, params, history) => dispatch(initEditorAction({ query, urlParams: params, history }));
  const closeCMSModal = () => dispatch(closeCMSModalAction());
  const saveChart = (reload, callback, body, goToPath) =>
    dispatch(saveChartAction({ reload, callback, body, goToPath, history }));
  const showAnnotationInMenu = (type, index, annotation) =>
    dispatch(showAnnotationInMenuAction({ type, index, annotation }));
  const updateCustomizedProp = (optionProps, val, extraModulesToLoad, index, skipEmit) =>
    dispatch(updateCustomizedAction({ optionProps, val, extraModulesToLoad, index, skipEmit }));

  const checkUnsavedChanges = () => changeMade;
  const check = (location) => {
    return (location.pathname === '/' || (location.pathname === '/profile/charts' && location.search !== '?wizard')) &&
      checkUnsavedChanges()
      ? unsavedChangesText
      : true;
  };
  const createAccountCallback = (shouldRedirect = true) => {
    if (shouldRedirect) {
      saveChart(true, null, editorChartConfig, paths.profileChartsWizard);
    } else {
      saveChart(true, null, editorChartConfig);
    }
  };

  return (
    <div className="hc-editor overflow-auto block">
      <Helmet>
        <title> everviz </title>
      </Helmet>

      <SignupModal cb={(shouldRedirect) => createAccountCallback(shouldRedirect)} headerText="Save project" />
      <CMSModal open={cmsModalOpen} close={closeCMSModal} />
      {!inPackagesMode && <Prompt when={true} message={check} />}

      <HighchartsAnnotations />

      {!loadingEditor && !showWizard && (
        <>
          <ChartEditor
            aggregatedOptions={aggregatedOptions}
            history={history}
            resolution={resolution}
            showWizard={showWizard}
            chart={chart}
            isMap={isMap}
          />
          <SmallScreenMessage />
        </>
      )}

      {!loadingEditor && showWizard && (
        <>
          <ChartWizard closeChartWizard={closeWizard} editorConfig={editorConfig} anonymous={anonymous} />
          <SmallScreenMessage />
        </>
      )}

      <GlobalModals />
    </div>
  );
}
