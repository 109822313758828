import { SubscriptionPlans } from '../../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../../types';

const Bubble: TemplateProps = {
  title: 'Bubble chart',
  description: '',
  constructor: '',
  thumbnail: 'usyfyw.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'bubble',
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true
        },
        lineWidth: 0
      }
    }
  },
  sampleSet: 'bubble',
  dataValidator: false,
  advancedParent: 'Bubble chart',
  basic: {
    order: 18,
    thumbnail: 'bubble_chart.svg',
    icon: 'Bubble'
  },
  parent: 'Bubble chart',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Pro)
};

export default Bubble;
