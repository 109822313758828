import { cloneDeep, isArray } from 'lodash';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actionTypes from '../../../../redux/actions/action-types';
import { setAction as setProjectConfigAction } from '../../../../redux/actions/projectConfig';
import { updateAggregated } from '../ChartEditor';
import { GenericPayload } from '../ChartEditorPointMap';
import { LocationMapContainerRef } from '@visual-elements/location-map';
import { loadRelevantModules } from 'pages/ChartEditorPage/utils/chartEditorHelper';
import { setAction as setChartAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { getTemplateData } from '../ChartEditorTemplates';
import bbox from '@turf/bbox';
import { FeatureCollection } from '@turf/helpers';
import { LocationMapDataFeatures } from './types';
import { staticMarkerPresets } from './LocationMapMarkerPresetStore';
import { MarkerPreset } from './LocationMapMarker';
import { defaultLocationMapCustomOptions } from '../../meta/DefaultOptions';
import {
  LocationMapCustomizedOptions,
  LocationMapState,
  LocationMapViewStateOptions,
  ProjectConfigLocationMapProps
} from '../../../Editor/reducers/locationMapConfigTypes';
import { PaddingOptions } from 'maplibre-gl';

type LoadProjectLocationMapPayload = GenericPayload & {
  data: {
    project: any;
  };
};

type StoreLocationMapRefPayload = GenericPayload & {
  data: {
    primaryMapRef: LocationMapContainerRef;
    secondaryMapRef: LocationMapContainerRef;
  };
};

type FitMapToBoundingBoxPayload = GenericPayload & {
  data: { bbox: [number, number, number, number] };
};

export function* storeLocationMapRef(params: StoreLocationMapRefPayload) {
  const { locationMapOptions }: ProjectConfigLocationMapProps = yield select((state) => state.projectConfig);
  const newLocationMapOptions = cloneDeep(locationMapOptions);
  newLocationMapOptions.editorMapRef = params.data.primaryMapRef;
  newLocationMapOptions.previewMapRef = params.data.secondaryMapRef;
  yield put(
    setProjectConfigAction({
      locationMapOptions: newLocationMapOptions
    })
  );
}

export function* loadProjectLocationMap(params: LoadProjectLocationMapPayload) {
  const { project: projectData } = params.data;

  yield put(
    setProjectConfigAction({
      provider: 'locationMap'
    })
  );

  const pluginConfig = loadRelevantModules(projectData);

  let templateOptions = [{}];
  let templateMeta = [] as any;

  if (projectData.template) {
    if (isArray(projectData.template)) templateOptions = projectData.template;
    else templateOptions = [projectData.template];
  }

  if (projectData.settings?.template) {
    templateMeta = [projectData.settings?.template[0]];
  }

  let templateDataSettings = {};

  if (projectData.settings?.templateDataSettings) {
    templateDataSettings = projectData.settings.templateDataSettings;
  }

  let customizedOptions: LocationMapCustomizedOptions;
  if (projectData.options) customizedOptions = projectData.options;
  else customizedOptions = defaultLocationMapCustomOptions;

  let themeOptions = {} as any;
  if (typeof projectData.theme !== 'undefined') {
    themeOptions = projectData.theme;
  }

  const dataOptions: LocationMapDataFeatures = projectData.settings.dataProvider;
  const markerPresets = getMarkerPresets();

  const locationMapOptions: LocationMapState = {
    editorMapRef: null,
    previewMapRef: null,
    layerOptions: projectData.settings.layerOptions,
    markerMetadata: projectData.settings.markerMetadata ?? {},
    markerPresets: markerPresets,
    viewStateOptions:
      projectData.settings.viewStateOptions ??
      ({
        editorViewState: {
          center: [9, 22],
          bearing: 0,
          pitch: 0
        },
        exportViewState: {
          center: [9, 22],
          bearing: 0,
          pitch: 0
        },
        viewIsLocked: false,
        viewBoxAspectRatio: 1,
        showViewBox: true
      } as LocationMapViewStateOptions)
  };

  yield put(
    setProjectConfigAction({
      customizedOptions,
      templateOptions,
      themeOptions: (themeOptions ?? {}).options ?? {},
      showWizard: false,
      templateDataSettings,
      pluginConfig,
      plugins: projectData?.settings?.plugins ?? {},
      cssModules: projectData?.settings?.plugins?.cssModules ?? [],
      dataOptions,
      templateMeta,
      locationMapOptions: locationMapOptions,
      icons: projectData.icons
    })
  );

  yield call(updateAggregated, true);

  let chosenWizardTemplate = false;
  if (templateMeta) {
    chosenWizardTemplate = yield call(getTemplateData, {
      data: {
        template: templateMeta
      }
    });
  }

  const projectConfigOptions = { loadingEditor: false };

  yield all([
    put(setProjectConfigAction(projectConfigOptions)),
    put(
      setChartAction({
        chosenWizardTemplate,
        constr: 'Map',
        isMap: true
      })
    )
  ]);
}

// Temporary function until we fetch from api instead
export function getMarkerPresets(): MarkerPreset[] {
  return staticMarkerPresets;
}

export function* fitMapToBoundingBox(params: FitMapToBoundingBoxPayload) {
  try {
    const { locationMapOptions }: ProjectConfigLocationMapProps = yield select((state) => state.projectConfig);
    const map = locationMapOptions.editorMapRef?.mapRef?.getMap();
    if (map) {
      map.fitBounds(params.data.bbox, { maxZoom: 17 });
    }
  } catch (e) {
    console.log(e);
  }
}

export function* fitMapToFeatures() {
  try {
    const { locationMapOptions, aggregatedOptions }: ProjectConfigLocationMapProps = yield select(
      (state) => state.projectConfig
    );

    const primaryMapRef = locationMapOptions.editorMapRef?.mapRef?.getMap();
    const secondaryMapRef = locationMapOptions.previewMapRef?.mapRef?.getMap();
    if (!primaryMapRef || !secondaryMapRef) return;

    // Set the height and width of the secondary map to the size of the reference points, so that
    // the fitbounds method will center the features within the bounding box in everviz
    secondaryMapRef.transform.height = aggregatedOptions.viewState.referenceHeight;
    secondaryMapRef.transform.width = aggregatedOptions.viewState.referenceWidth;

    const featureCollection: FeatureCollection = { features: [], type: 'FeatureCollection' };

    for (const marker of aggregatedOptions.markers) {
      if (marker.data.type === 'static') {
        // There is a typing issue between turf and the GeoJson typing library that we use
        // Should look into it, but not urgent
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-expect-error
        featureCollection.features.push(marker.data.content);
      }
    }

    if (featureCollection.features.length === 0) return;
    const featureBbox = bbox(featureCollection);

    const defPadY = 24 * Math.log10(secondaryMapRef.transform.height);
    const defPadX = 24 * Math.log10(secondaryMapRef.transform.width);
    secondaryMapRef.fitBounds(
      // Same typing issue here
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      featureBbox,
      { animate: false, maxZoom: featureCollection.features.length > 1 ? 24 : 11, essential: true }
    );

    const elements = locationMapOptions.previewMapRef?.mapRef?.getElements();
    const padding: PaddingOptions = { top: 0, bottom: 0, left: 0, right: 0 };

    if (elements) {
      const canvasContainer = secondaryMapRef.getCanvasContainer();
      const canvasRect = canvasContainer.getBoundingClientRect();

      elements.forEach((element) => {
        const targetRect = element.getBoundingClientRect();
        if (canvasRect.left - targetRect.left > padding.left) {
          padding.left = canvasRect.left - targetRect.left;
        }
        if (canvasRect.top - targetRect.top > padding.top) {
          padding.top = canvasRect.top - targetRect.top;
        }
        if (targetRect.right - canvasRect.right > padding.right) {
          padding.right = targetRect.right - canvasRect.right;
        }
        if (canvasRect.bottom - targetRect.bottom > padding.bottom) {
          padding.bottom = canvasRect.bottom - targetRect.bottom;
        }
      });
    }

    padding.left += defPadX;
    padding.right += defPadX;
    padding.top += defPadY;
    padding.bottom += defPadY;
    secondaryMapRef.fitBounds(
      // Same typing issue here
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      featureBbox,
      {
        padding: padding,
        maxZoom: featureCollection.features.length > 1 ? 24 : 11,
        bearing: locationMapOptions.viewStateOptions.editorViewState!.bearing,
        pitch: locationMapOptions.viewStateOptions.editorViewState!.pitch,
        animate: false,
        essential: true
      }
    );
    primaryMapRef.flyTo({
      center: secondaryMapRef.getCenter(),
      bearing: secondaryMapRef.getBearing(),
      zoom: secondaryMapRef.getZoom(),
      pitch: secondaryMapRef.getPitch(),
      animate: true,
      essential: true
    });
    secondaryMapRef.resize(); // Resize the secondary map so that it returns to the original size
  } catch (e) {
    console.log(e);
  }
}

export function* watchFitMapToBoundingBox() {
  yield takeEvery(actionTypes.locationMap.fitMapToBoundingBox, fitMapToBoundingBox);
}

export function* watchFitMapToFeatures() {
  yield takeEvery(actionTypes.locationMap.fitMapToFeatures, fitMapToFeatures);
}

export function* watchStoreLocationMapRef() {
  yield takeEvery(actionTypes.locationMap.storeLocationMapRef, storeLocationMapRef);
}

export default function* rootSaga() {
  yield all([watchStoreLocationMapRef(), watchFitMapToFeatures(), watchFitMapToBoundingBox()]);
}
