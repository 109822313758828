import { all, call, put, takeLatest } from 'redux-saga/effects';
import { onLoggedIn } from 'utils/profileHelper';
import { handleGoogleTagManagerPurchaseEvent } from 'shared/utils/googleTagManagerHelper';
import { useCaptcha } from 'utils/signupHelper';
import {
  getTeamBillingAdjustmentsWithTeamid,
  getTeamBillingInfoWithTeamid,
  getTeamInvoiceWithTeamidAndInvoice,
  postTeamSubscribeUsingTeamidAndPlanid
} from '../../../api/cloudApiGenerated';
import config from '../../../config';
import actionTypes from '../../../redux/actions/action-types';
import { setPlansPageAction } from '../actions/plansPage';
import { subscriptionPlans } from '../meta/subscriptionPlans';

export function* getBillingDetails(params) {
  const { team } = params.data;
  try {
    const { data } = yield call(getTeamBillingInfoWithTeamid, team.id);
    yield put(
      setPlansPageAction({
        billingDetails: data[0]
      })
    );
  } catch (error) {
    setPlansPageAction({ error });
  }
}

export function* updatePlan(params) {
  const { team, planCode } = params.data;

  const action = 'subscription';
  const captchaResponse = yield call(useCaptcha, config, action);
  const body = {
    captchaResponse,
    action
  };

  try {
    yield put(setPlansPageAction({ savingPlan: true }));
    yield call(() => postTeamSubscribeUsingTeamidAndPlanid(team.id, planCode, body));
    yield put(setPlansPageAction({ savingPlan: false, successScreenText: 'Thank you for upgrading your plan!' }));
    onLoggedIn();

    if (planCode) {
      const planDetails = subscriptionPlans[planCode];
      handleGoogleTagManagerPurchaseEvent('purchase', planDetails);
    }
  } catch (error) {
    setPlansPageAction({ error });
  }
}

export function* getAdjustments(params) {
  const { team, adjustments } = params.data;
  if (adjustments.length) return;

  try {
    let data = yield call(getTeamBillingAdjustmentsWithTeamid, team.id);
    yield put(
      setPlansPageAction({
        dataReturned: true,
        adjustments: data.data
      })
    );
  } catch (error) {
    setPlansPageAction({ error });
  }
}

export function* downloadInvoice(params) {
  const { team, invoiceNumber } = params.data;

  try {
    yield call(getTeamInvoiceWithTeamidAndInvoice, team.id, invoiceNumber);
  } catch (error) {
    setPlansPageAction({ error });
  }
}

/** Watch functions */
export function* watchUpdatePlan() {
  yield takeLatest(actionTypes.plansPage.updatePlan, updatePlan);
}
export function* watchGetBillingDetails() {
  yield takeLatest(actionTypes.plansPage.getBillingDetails, getBillingDetails);
}
export function* watchGetAdjustments() {
  yield takeLatest(actionTypes.plansPage.getAdjustments, getAdjustments);
}
export function* watchDownloadInvoice() {
  yield takeLatest(actionTypes.plansPage.downloadInvoice, downloadInvoice);
}

export default function* rootSaga() {
  yield all([watchUpdatePlan(), watchGetBillingDetails(), watchGetAdjustments(), watchDownloadInvoice()]);
}
