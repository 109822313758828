import { cloneDeep, get, isArray, isPlainObject, set, unset } from 'lodash';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { isNum, merge, setAttr } from '../../../editor/core/highcharts-editor';
import { dataLabelFormatOptions } from '../../../editor/meta/highed.format.options';
import actionTypes from '../../../redux/actions/action-types';
import { setAction as setProjectConfigAction } from '../../../redux/actions/projectConfig';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import { extractDataLabelOptions } from '../../../shared/widgets/utils/dataLabelsHelper';
import { setAction as setChartAction } from '../actions/chartEditor';
import { getChartConfig } from '../selectors/chartEditor';
import { updateAggregated } from './ChartEditor';
import { loadTextData } from './ChartEditorData';
import { handleOptionalArrayCustomized, setOption } from '../utils/chartEditorCustomizeHelper';

export function* setCustomizedOptions(params) {
  let { customizedOptions, skipEmit } = params.data;

  yield put(
    setProjectConfigAction({
      customizedOptions
    })
  );

  if (!skipEmit) {
    yield call(updateAggregated, true);
  }
}

export function* setCustomCode(params) {
  let { customCode: newCode, skipEmit } = params.data;
  let customCode = false;
  let customCodeStr = '';

  if (!newCode) {
    customCode = false;
    customCodeStr = '';
  }

  try {
    // eval('(var options = {};' + newCode + ')');
    customCode = new Function(
      'options',
      'chart',
      [
        'if (options.yAxis && options.yAxis.length === 1) options.yAxis = options.yAxis[0];',
        'if (options.xAxis && options.xAxis.length === 1) options.xAxis = options.xAxis[0];',
        'if (options.zAxis && options.zAxis.length === 1) options.zAxis = options.zAxis[0];',
        'if (!options.series || options.series.length === 0) return;',
        'var encodedUrl = "";'
        /*    this.chartThemes.getCustomCode()*/ // TODO Add back in
      ].join('') + newCode
    );
    customCodeStr = newCode;

    yield put(
      setChartAction({
        customCode,
        customCodeStr
      })
    );
  } catch (e) {
    yield put(
      setChartAction({
        customCode: false
      })
    );
    //  this.customCode = false;
    //  this.customCodeStr = newCode;
    //  return isFn(errFn) && errFn(e);
  }

  if (!skipEmit) {
    yield call(updateAggregated, true);
  }
}
export function* deleteCustomized(params) {
  let { optionProps } = params.data;

  const config = yield select(getProjectConfig);
  let newCustomizedOptions = cloneDeep(config.customizedOptions) ?? {};
  unset(newCustomizedOptions, optionProps.id ?? optionProps);

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      changeMade: true
    })
  );

  yield call(updateAggregated, false);
}

export function* togglePlugins(pluginsToToggle, toggle) {
  const { plugins: oldPlugins } = yield select((state) => state.projectConfig);
  const plugins = cloneDeep(oldPlugins);

  pluginsToToggle.forEach((p) => {
    if (toggle) plugins[p] = true;
    else delete plugins[p];
  });

  yield put(
    setProjectConfigAction({
      plugins
    })
  );
}

export function* updateCustomized(params) {
  let { optionProps, val, skipEmit } = params.data;
  const config = yield select(getProjectConfig);
  let newCustomizedOptions = merge({}, config.customizedOptions) ?? {};

  if (isArray(optionProps.id)) {
    optionProps.id.forEach((id) => {
      setOption(newCustomizedOptions, id, val);
    });
  } else {
    if (optionProps.isOptionalArray) {
      handleOptionalArrayCustomized(optionProps, newCustomizedOptions, val);
    } else {
      setOption(newCustomizedOptions, optionProps.id || optionProps, val);
    }
  }

  if (optionProps && optionProps.clearFieldsWhenSet) {
    optionProps.clearFieldsWhenSet.forEach((field) => {
      setAttr(newCustomizedOptions[parent], field, null);
    });
  }

  if (optionProps?.plugins) {
    yield call(togglePlugins, optionProps.plugins, val);
  }

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      changeMade: true
    })
  );

  if (!skipEmit) {
    yield call(updateAggregated, false);
  }
}

export function* updateCustomizedBulk(params) {
  let { options } = params.data;
  const config = yield select(getProjectConfig);
  let newCustomizedOptions = merge({}, config.customizedOptions) ?? {};
  newCustomizedOptions = merge(newCustomizedOptions, options);
  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      changeMade: true
    })
  );

  yield call(updateAggregated, false);
}

export function* addItemArray(params) {
  let { key } = params.data;
  const config = yield select(getProjectConfig);
  let newCustomizedOptions = merge({}, config.customizedOptions) ?? {};
  const addingAxis = ['xAxis', 'yAxis'].includes(key);
  const addingDataLabel = key.indexOf('dataLabels') > -1;
  let newElement = { everviz: 1 };

  let opt = get(newCustomizedOptions, key);
  if (!opt) {
    if (addingAxis) {
      // We always start with one axis object so if nothing exists
      // and user has clicked plus button, we should create two axis objects
      set(newCustomizedOptions, key, [cloneDeep(newElement), cloneDeep(newElement)]);
    } else {
      if (addingDataLabel) newElement.enabled = true;
      set(newCustomizedOptions, key, [newElement]);
    }
  } else {
    if (isPlainObject(opt)) {
      // Is an object, convert to an array and push the new value
      set(newCustomizedOptions, key, [opt]);
      opt = get(newCustomizedOptions, key);
    }

    if (addingDataLabel) newElement.enabled = true;
    opt.push(newElement);
  }

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      changeMade: true
    })
  );

  yield call(updateAggregated, false);
}

export function* deleteItemArray(params) {
  let { index, key } = params.data;
  const config = yield select(getProjectConfig);
  let newCustomizedOptions = merge({}, config.customizedOptions) ?? {};
  let removedIndexKey = key;
  const optionalArrayTypes = ['xAxis', 'yAxis'];

  const splitKey = key.split('.');
  const lastPropHasIndex = splitKey[splitKey.length - 1].indexOf('[') > -1;
  if (lastPropHasIndex) {
    // Remove the index from the last key
    removedIndexKey = key.split('[');
    removedIndexKey.splice(removedIndexKey.length - 1, 1);
    removedIndexKey = removedIndexKey.join('[');
  }

  const deletingAxis = optionalArrayTypes.includes(removedIndexKey);

  const opt = get(newCustomizedOptions, removedIndexKey);
  if (isPlainObject(opt)) {
    unset(newCustomizedOptions, removedIndexKey);
  } else if (opt) {
    opt.splice(index, 1);
    if (deletingAxis && opt.length === 1) set(newCustomizedOptions, removedIndexKey, opt[0]);
    else if (!opt.length) unset(newCustomizedOptions, removedIndexKey);
  } else {
    // Probably an optional array type that hasnt deleted properly.
    optionalArrayTypes.forEach((type) => (removedIndexKey = removedIndexKey.replace(type, `${type}[0]`)));
    unset(newCustomizedOptions, removedIndexKey);
  }

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      changeMade: true
    })
  );

  yield call(updateAggregated, false);
}

export function* changeDataLabelDecimal(newValue, group, detailIndex) {
  const { aggregatedOptions } = yield select(getProjectConfig);

  let format =
    extractDataLabelOptions(
      aggregatedOptions,
      {
        ...group,
        rawKey: 'format'
      },
      detailIndex
    ) ?? dataLabelFormatOptions[0].value;

  const regex = /{.*?}/g;
  const found = (format ?? '').match(regex);
  let decValue = '';

  if (isNum(newValue)) {
    decValue = ':.' + newValue + 'f';
  }

  if (!found || !found?.length) return format;
  const floatRegex = /:\.[0-9]f*/;

  found.forEach((val) => {
    const floatMatch = val.match(floatRegex);
    if (floatMatch) format = format.replace(val, val.replace(floatMatch[0], decValue));
    else format = format.replace(val, val.replace('}', decValue + '}'));
  });

  return format;
}

export function* updateDataLabel(params) {
  let { option, value, detailIndex } = params.data;
  const config = yield select(getProjectConfig);
  let newCustomizedOptions = cloneDeep(config.customizedOptions) ?? {};

  const rawOptionId = option.id.replace('plotOptions.series.', '');
  if (option.suffix) value += option.suffix;

  if (option.isDecimalValue) {
    value = yield call(changeDataLabelDecimal, value, option, detailIndex);
  }

  if (detailIndex === 'all') {
    // Remove all series datalabel props for this incoming one
    newCustomizedOptions.series.forEach((series) => {
      unset(series, rawOptionId);
    });
    setOption(newCustomizedOptions, option.id, value);
  } else {
    // In series option
    setOption(newCustomizedOptions, `series[${detailIndex}].${rawOptionId}`, value);
  }

  yield put(
    setProjectConfigAction({
      customizedOptions: newCustomizedOptions,
      changeMade: true
    })
  );

  yield call(updateAggregated, false);
}

export function* updateDataRelatedProp(params) {
  let { optionProps, val, skipEmit } = params.data;
  const { templateDataSettings } = yield select(getProjectConfig);
  let newTemplateDataSettings = cloneDeep(templateDataSettings) ?? {};

  const seriesIndex = 0;
  if (!newTemplateDataSettings[seriesIndex]) {
    newTemplateDataSettings[seriesIndex] = {
      data: []
    };
  }

  for (let i = 0; i < val.length; i++) {
    if (!newTemplateDataSettings[seriesIndex].data[i]) {
      newTemplateDataSettings[seriesIndex].data[i] = {};
    }
    if (val[i] !== undefined) {
      set(newTemplateDataSettings[seriesIndex].data[i], optionProps.id, val[i]);
    }
  }

  yield put(
    setProjectConfigAction({
      templateDataSettings: newTemplateDataSettings,
      changeMade: true
    })
  );

  if (!skipEmit) {
    yield call(updateAggregated, false);
  }
}

// This is really only used for the wordcloud 'amount' field.
export function* updateBasedOnDataProp(params) {
  let { val } = params.data;

  const chartConfig = yield select(getChartConfig);
  let textValue = cloneDeep(
    chartConfig.dataTextValue || {
      text: ''
    }
  );

  textValue.amount = val;

  yield put(
    setChartAction({
      dataTextValue: textValue
    })
  );

  yield call(loadTextData);
}

/** Watch functions */
export function* watchSetCustomizedOptions() {
  yield takeEvery(actionTypes.chartEditor.setCustomizedOptions, setCustomizedOptions);
}
export function* watchSetCustomCode() {
  yield takeEvery(actionTypes.chartEditor.setCustomCode, setCustomCode);
}
export function* watchAddItemArray() {
  yield takeEvery(actionTypes.chartEditor.addItemArray, addItemArray);
}
export function* watchDeleteItemArray() {
  yield takeEvery(actionTypes.chartEditor.deleteItemArray, deleteItemArray);
}
export function* watchUpdateCustomized() {
  yield takeEvery(actionTypes.chartEditor.updateCustomized, updateCustomized);
}
export function* watchUpdateCustomizedBulk() {
  yield takeEvery(actionTypes.chartEditor.updateCustomizedBulk, updateCustomizedBulk);
}
export function* watchUpdateDataLabel() {
  yield takeEvery(actionTypes.chartEditor.updateDataLabel, updateDataLabel);
}
export function* watchUpdateDataRelatedProp() {
  yield takeEvery(actionTypes.chartEditor.updateDataRelatedProp, updateDataRelatedProp);
}

export function* watchUpdateBasedOnDataProp() {
  yield takeEvery(actionTypes.chartEditor.updateBasedOnDataProp, updateBasedOnDataProp);
}

export function* watchDeleteCustomized() {
  yield takeEvery(actionTypes.chartEditor.deleteCustomized, deleteCustomized);
}

export default function* rootSaga() {
  yield all([
    watchSetCustomizedOptions(),
    watchSetCustomCode(),
    watchAddItemArray(),
    watchUpdateCustomized(),
    watchUpdateCustomizedBulk(),
    watchDeleteItemArray(),
    watchUpdateDataLabel(),
    watchUpdateDataRelatedProp(),
    watchUpdateBasedOnDataProp(),
    watchDeleteCustomized()
  ]);
}
