export default {
  modalIsOpen: false,
  invitations: [],
  form: {},
  emailText: '',
  inviteError: '',
  resend: false,
  showCancelInvitation: false,
  currentInvitation: '',
  groupId: '',
  priceTotal: null,
  unitPrice: null,
  page: 1,
  pageSize: 10,
  pageCount: 1,
  dataReturned: false,
  activeDivision: null,
  isUsersLimitModalOpen: false
};
