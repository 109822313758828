import { Map } from 'maplibre-gl';
import React from 'react';
import { useSelector } from 'react-redux';
import { ProjectConfigLocationMapProps } from '../../../pages/Editor/reducers/locationMapConfigTypes';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import IconButton from '../../buttons/IconButton';

export type LocationMapNavigationControlsProps = {
  map: Map | undefined;
};

export const LocationMapNavigationControls = ({ map }: LocationMapNavigationControlsProps) => {
  return (
    <>
      <IconButton icon={'fa fa-solid fa-plus text-lg'} tooltipText="Zoom in" onClick={() => map?.zoomIn()} />
      <IconButton icon={'fa fa-solid fa-minus text-lg'} tooltipText="Zoom out" onClick={() => map?.zoomOut()} />
      <IconButton
        icon={'fa fa-solid fa-compass text-lg'}
        tooltipText="Reset orientation to north"
        onClick={() => map?.easeTo({ bearing: 0 })}
      />
      <IconButton
        icon={'fa fa-solid fa-angle-90 text-lg'}
        tooltipText="Reset map angle"
        onClick={() => map?.easeTo({ pitch: 0 })}
      />
    </>
  );
};
export default function LocationMapBottomBar() {
  const { locationMapOptions }: ProjectConfigLocationMapProps = useSelector(getProjectConfig);
  const map = locationMapOptions.editorMapRef?.mapRef?.getMap();

  return (
    <div className="w-full h-full flex justify-center">
      <div
        className="relative flex content-center gap-x-3 items-center pl-8 pr-8 border-b-r bg-white "
        style={{ borderBottomRightRadius: '20px', borderBottomLeftRadius: '20px' }}
      >
        <div className="absolute w-8 h-8 top-0 -left-8 bg-white" />
        <div className="absolute w-8 h-8 top-0 -left-8 bg-ev-grey" style={{ borderTopRightRadius: '30px' }} />
        <LocationMapNavigationControls map={map} />
        <div className="absolute w-8 h-8 top-0 -right-8 bg-white" />
        <div className="absolute w-8 h-8 top-0 -right-8 bg-ev-grey" style={{ borderTopLeftRadius: '30px' }} />
      </div>
    </div>
  );
}
