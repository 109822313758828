import { cloneDeep } from 'lodash';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actionTypes from 'redux/actions/action-types';
import { getProjectConfig } from 'redux/selectors/projectConfig';
import { setAction as setProjectConfigAction } from '../../../../redux/actions/projectConfig';
import { updateAggregated } from '../ChartEditor';
import { GenericPayload } from '../ChartEditorPointMap';
import { MapResolution, MapViewState, ViewStateProps } from '@visual-elements/location-map';
import { ProjectConfigLocationMapProps } from '../../../Editor/reducers/locationMapConfigTypes';

type SetViewBoxAspectRatioPayload = GenericPayload & {
  data: { aspectRatio: number };
};

export function* setVievBoxAspectRatio(params: SetViewBoxAspectRatioPayload) {
  try {
    const { locationMapOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newLocationMapOptions = cloneDeep(locationMapOptions);

    newLocationMapOptions.viewStateOptions.viewBoxAspectRatio = params.data.aspectRatio;

    yield put(
      setProjectConfigAction({
        locationMapOptions: newLocationMapOptions
      })
    );
    yield call(updateAggregated);
  } catch (err) {
    console.log(err);
  }
}

type UpdateReferencePointResolutionPayload = GenericPayload & {
  data: MapResolution;
};

export function* updateReferencePointResolution(params: UpdateReferencePointResolutionPayload) {
  try {
    const { locationMapOptions, customizedOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newCustomizedOptions = cloneDeep(customizedOptions);

    newCustomizedOptions.viewState = {
      ...newCustomizedOptions.viewState,
      referenceHeight: params.data.height,
      referenceWidth: params.data.width,
      zoom: locationMapOptions.editorMapRef?.mapRef?.getMap()?.getZoom() ?? 1
    };

    locationMapOptions.previewMapRef?.mapRef?.getMap()?.resize();

    yield put(
      setProjectConfigAction({
        customizedOptions: newCustomizedOptions
      })
    );
    yield call(updateAggregated);
  } catch (err) {
    console.log(err);
  }
}

type UpdateMapViewStatePayload = GenericPayload & {
  data: { viewState: MapViewState; resolution: MapResolution };
};

export function* updateViewState(params: UpdateMapViewStatePayload) {
  try {
    const { locationMapOptions, customizedOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newLocationMapOptions = cloneDeep(locationMapOptions);
    const newCustomizedOptions = cloneDeep(customizedOptions);

    const map = newLocationMapOptions.editorMapRef?.mapRef?.getMap();

    if (!map) throw new Error('Map must be defined if it has reported viewstate');

    const { height, width } = map.getContainer().getBoundingClientRect();

    const heightScale = height / (params.data.resolution.height ?? height);
    const widthScale = width / (params.data.resolution.width ?? width);
    const calculatedZoomLevel = params.data.viewState.zoom - Math.log2(Math.min(heightScale, widthScale));

    const newViewState: ViewStateProps = {
      bearing: params.data.viewState.bearing,
      center: params.data.viewState.center,
      pitch: params.data.viewState.pitch,
      referenceHeight: params.data.resolution.height,
      referenceWidth: params.data.resolution.width,
      zoom: calculatedZoomLevel
    };

    newLocationMapOptions.viewStateOptions.editorViewState = {
      bearing: params.data.viewState.bearing,
      center: params.data.viewState.center,
      pitch: params.data.viewState.pitch
    };

    if (!locationMapOptions.viewStateOptions.viewIsLocked) {
      newCustomizedOptions.viewState = newViewState;

      newLocationMapOptions.previewMapRef?.mapRef?.getMap()?.jumpTo({ ...newViewState, zoom: calculatedZoomLevel });
    }

    yield put(
      setProjectConfigAction({
        locationMapOptions: newLocationMapOptions,
        customizedOptions: newCustomizedOptions
      })
    );
    yield call(updateAggregated);
  } catch (err) {
    console.log(err);
  }
}

export function* watchUpdateReferencePointResolution() {
  yield takeEvery(actionTypes.locationMap.updateReferencePointResolution, updateReferencePointResolution);
}

export function* watchToggleViewBox() {
  yield takeEvery(actionTypes.locationMap.toggleViewBox, setVievBoxAspectRatio);
}

export function* watchUpdateViewState() {
  yield takeEvery(actionTypes.locationMap.updateViewState, updateViewState);
}

export default function* rootSaga() {
  yield all([watchUpdateViewState(), watchToggleViewBox(), watchUpdateReferencePointResolution()]);
}
