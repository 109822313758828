import { cloneDeep } from 'lodash';
import { assignThemeAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { assignThemeAction as assignLayoutThemeAction } from 'pages/LayoutEditorPage/actions/layoutEditor';
import { assignThemeAction as assignTableThemeAction } from 'pages/TableEditorPage/actions/tableEditor';
import paths from 'paths';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { assignCompanyThemeAction, getThemesAction } from 'redux/actions/projectConfig';
import { RootState } from 'redux/store';
import Accordion from 'shared/Accordion';
import { TypeProps } from 'shared/types/chooseTheme';
import { generateQueryString } from 'shared/utils/editorHelper';
import { getDefaultThemes } from 'shared/utils/themeHelper';
import { checkPlanPermission } from 'utils/profileHelper';
import Card from '../../../editor/ui/highed.card';
import { getEditorConfig } from '../../utils/editorHelper';
import PanelContainer from './PanelContainer';
import UpsellCard from './UpsellCard';

export type ThemeProp = {
  onClick: (theme: object) => void;
  id: number;
};

type ThemesProp = {
  active: boolean;
};

const assignThemeMap = {
  chart: assignThemeAction,
  layout: assignLayoutThemeAction,
  table: assignTableThemeAction
};

export default function Themes(props: ThemesProp) {
  const dispatch = useDispatch();
  const [showDefaults, setShowDefaults] = useState(true);
  const [selected, setSelected] = useState<boolean | number>(false);
  const { themeMeta, companyThemes, provider, type, tab, aggregatedOptions, cssModules } = useSelector<any, any>(
    (state: RootState) => state.projectConfig
  );
  const { isMap } = useSelector((state: RootState) => state.chartEditorPage);
  const history = useHistory();
  const { themes } = useSelector((state: RootState) => state.layoutEditorPage);
  const profile = useSelector((state: RootState) => state.profile);
  const { team, division } = profile;
  const [openSection, setOpenSection] = useState(themes.length ? 'custom' : 'default');
  const defaultThemes = getDefaultThemes(type, provider);
  const hasThemesPlanPermission = checkPlanPermission('custom_themes', profile);
  const isLocationMap = provider === 'locationMap';

  useEffect(() => {
    dispatch(getThemesAction({ team, division, type }));
    const anonymous = !team;
    getEditorConfig(team, division, anonymous).then((data) => {
      if (!isLocationMap && data?.length) {
        setShowDefaults(!data[0].hide_default_themes);
      }
    });

    if (themeMeta && Object.keys(themeMeta).length > 1) {
      setSelected(themeMeta.id);
    } else {
      setSelected(defaultThemes[0].id);
    }
  }, []);

  const changeTheme = (theme: ThemeProp) => {
    dispatch(assignThemeMap[type as TypeProps]({ team: team ?? {}, theme }));
  };
  const changeCompanyTheme = (selected: ThemeProp) => {
    dispatch(assignCompanyThemeAction({ selected }));
  };

  const toggleSection = (type: string) => setOpenSection(openSection === type ? '' : type);

  const onClickUpsell = () => {
    if (hasThemesPlanPermission) {
      const agg: any = cloneDeep(aggregatedOptions ?? {});
      if (agg.data) delete agg.data;
      if (agg.navigation) delete agg.navigation;
      const queryString = generateQueryString(qs, tab, type, false, isMap);
      const options = {
        pathname: paths.newTheme,
        search: queryString,
        state: {
          defaultThemeDetails: {
            options: agg,
            plugins: {
              cssModules
            }
          }
        }
      };
      history.push(options);
    } else window.open(paths.profileSubscription, '_blank');
  };

  return (
    <PanelContainer active={props.active} className="pt-6">
      <div className="flex flex-col gap-2 pb-4 ev-sm:pb-0">
        <Accordion
          headerText={'Your themes'}
          onClick={() => toggleSection('custom')}
          selected={openSection === 'custom'}
          childrenClasses={
            'relative flex flex-col rounded py-2 ev-sm:h-[calc(100vh-340px)] ev-sm:overflow-auto px-6 mr-2'
          }
        >
          <div className="project-grid-themes grid gap-4 grid-flow-row grid-cols-2">
            <UpsellCard
              title="YOUR THEME"
              text="Create a new theme"
              disabled={!hasThemesPlanPermission}
              onClick={onClickUpsell}
            />
            {(companyThemes ?? []).map((theme: ThemeProp, i: number) => {
              return (
                <div key={`custom_theme_${i}`}>
                  <Card
                    {...theme}
                    className={theme.id === selected ? 'active' : ''}
                    onClick={() => {
                      setSelected(theme.id);
                      changeCompanyTheme(theme);
                    }}
                  />
                </div>
              );
            })}

            {themes.map((theme: ThemeProp, i: number) => {
              theme.onClick = () => {
                setSelected(theme.id);
                changeTheme(theme);
              };
              return (
                <div key={`custom_theme_${i}`}>
                  <Card {...theme} className={theme.id === selected ? 'active' : ''} />
                </div>
              );
            })}
          </div>
        </Accordion>
        {showDefaults && (
          <Accordion
            headerText={'Standard themes'}
            onClick={() => toggleSection('default')}
            selected={openSection === 'default'}
            childrenClasses={
              'relative flex flex-col rounded py-2 ev-sm:h-[calc(100vh-340px)] ev-sm:overflow-auto px-6 mr-2'
            }
          >
            <div className="project-grid-themes grid gap-4 grid-flow-row grid-cols-2">
              {defaultThemes.map((theme, i) => {
                const clickableTheme = theme as unknown as ThemeProp;
                clickableTheme.onClick = () => {
                  setSelected(clickableTheme.id);
                  changeTheme(clickableTheme);
                };
                return (
                  <div key={`default_theme_${i}`}>
                    <Card {...theme} className={clickableTheme.id === selected ? 'active' : ''} />
                  </div>
                );
              })}
            </div>
          </Accordion>
        )}
      </div>
    </PanelContainer>
  );
}
