import { SubscriptionPlans } from '../../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../../types';

const Waterfall: TemplateProps = {
  title: 'Waterfall',
  description: '',
  constructor: '',
  thumbnail: 'waterfall.png',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'waterfall',
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    }
  },
  sampleSet: 'waterfall',
  advancedParent: 'Waterfall',
  dataValidator: false,
  basic: {
    order: 23,
    thumbnail: 'waterfall.svg',
    icon: 'Waterfall'
  },
  parent: 'More',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Pro)
};

export default Waterfall;
