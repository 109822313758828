import { LocationMapContainerRef, LocationMapEntryPoint } from '@visual-elements/location-map';
import {
  endAddMarkerWorkflowAction,
  inlineUpdateLocationMapMarkerAction,
  storeLocationMapRefAction,
  updateViewStateAction
} from 'pages/ChartEditorPage/actions/locationMap';
import React, { useCallback, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ProjectConfigLocationMapProps } from '../../../pages/Editor/reducers/locationMapConfigTypes';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import { ScreenDimmer } from '../../editor/generic/ScreenDimmer';
import { ViewBox } from '../../editor/generic/locationMap/ViewBox';
import { LocationMapMappingOptions, mapToLocationMapProps } from '../utils/locationMapMapper';

export type LocationMapPreviewProps = {
  // aspectRatio: number;
};

const LocationMapPreview = () => {
  const { aggregatedOptions, urlParam, tab, provider, showWizard, locationMapOptions }: ProjectConfigLocationMapProps =
    useSelector(getProjectConfig);
  if (provider !== 'locationMap') throw new Error('Provider must be location map');

  const dispatch = useDispatch();

  const { resolution } = useSelector((state: RootState) => state.layoutEditorPage);
  const manualResolutionIsSet = resolution !== undefined && (resolution.height !== null || resolution.width !== null);

  const editorMapRef = useRef<LocationMapContainerRef | null>(null);
  const previewMapRef = useRef<LocationMapContainerRef | null>(null);
  const viewBoxRef = useRef<HTMLDivElement>(null);

  const isDataTab = showWizard ? urlParam === 'data' : tab === 'data';
  const isPublishTab = showWizard ? urlParam === 'publish' : tab === 'publish';
  // const isThemeTab = showWizard ? urlParam === 'themes' : tab === 'themes';
  // const isCustomizeTab = showWizard ? urlParam === 'customize' : tab === 'customize';

  const handleOnPreviewMapRefSet = useCallback((locationMapContainerRef: LocationMapContainerRef | null) => {
    if (locationMapContainerRef) {
      previewMapRef.current = locationMapContainerRef;

      if (editorMapRef.current) {
        dispatch(
          storeLocationMapRefAction({ secondaryMapRef: previewMapRef.current, primaryMapRef: editorMapRef.current })
        );
      }
    }
  }, []);

  const [mapContainer, setMapContainer] = useState<HTMLElement | undefined>();

  const handleOnEditorMapRefSet = useCallback((locationMapContainerRef: LocationMapContainerRef | null) => {
    if (locationMapContainerRef) {
      editorMapRef.current = locationMapContainerRef;
      editorMapRef.current.on('mapDefined', (mapInstance) => {
        setMapContainer(mapInstance.getContainer());
      });

      if (previewMapRef) {
        dispatch(
          storeLocationMapRefAction({ secondaryMapRef: previewMapRef.current, primaryMapRef: editorMapRef.current })
        );
      }

      editorMapRef.current.on('markerIconChange', (id, data) => {
        dispatch(inlineUpdateLocationMapMarkerAction({ payload: data, id: id, target: 'icon' }));
      });
      editorMapRef.current.on('markerLabelChange', (id, data) => {
        dispatch(inlineUpdateLocationMapMarkerAction({ payload: data, id: id, target: 'label' }));
      });
      editorMapRef.current.on('viewStateChange', (data) => {
        const viewBoxSize = viewBoxRef.current?.getBoundingClientRect();
        dispatch(
          updateViewStateAction({
            viewState: data,
            resolution: { height: viewBoxSize?.height ?? 0, width: viewBoxSize?.width ?? 0 }
          })
        );
      });
    }
  }, []);

  const { viewBoxAspectRatio, showViewBox } = { ...locationMapOptions.viewStateOptions };

  const previewMapMappingOptions: LocationMapMappingOptions = {
    disableText: false,
    editorMode: {},
    reuseMaps: false,
    disableControls: false,
    resolution: { type: 'fill' },
    interactive: { override: true, value: false },
    viewState: { override: false }
  };
  const previewMapProps = mapToLocationMapProps(aggregatedOptions, previewMapMappingOptions);

  const editorMappingOptions: LocationMapMappingOptions = {
    disableText: isDataTab,
    editorMode: {
      enableFeatureEditing: !isPublishTab,
      disableAnimations: !isPublishTab,
      enableViewstateReporting: true,
      disableWaitForLoadingFinishined: true
    },
    reuseMaps: true,
    disableControls: false,
    resolution: { type: 'fill' },
    interactive: { override: true, value: true },
    viewState: locationMapOptions.viewStateOptions.editorViewState
      ? { override: true, value: locationMapOptions.viewStateOptions.editorViewState }
      : { override: false }
  };
  const editorMapProps = mapToLocationMapProps(aggregatedOptions, editorMappingOptions);

  const { isHighlighted, type } = { ...locationMapOptions.highlightOptions };

  const doc = document.querySelector('#app .page-container .wizard-popup, #app .page-container .left-panel');
  const highlightMessage = type === 'marker' ? 'Click anywhere on the map to add a marker to it.' : '';

  const showPreviewMap = isPublishTab || manualResolutionIsSet;
  return (
    <div
      className="flex flex-col-reverse w-full h-full relative"
      style={{
        zIndex: isHighlighted ? 100001 : 0
      }}
    >
      {showViewBox &&
        mapContainer &&
        createPortal(
          <ViewBox ref={viewBoxRef} locationMapRef={editorMapRef.current} aspectRatio={viewBoxAspectRatio} />,
          mapContainer
        )}

      <LocationMapEntryPoint
        {...previewMapProps}
        containerStyle={{
          position: 'absolute',
          left: 0,
          top: 0,
          opacity: showPreviewMap ? 1 : 0
        }}
        ref={handleOnPreviewMapRefSet}
      />

      <LocationMapEntryPoint
        {...editorMapProps}
        containerStyle={{
          position: 'absolute',
          left: 0,
          top: 0,
          opacity: showPreviewMap ? 0 : 1,
          zIndex: showPreviewMap ? -1 : 0
        }}
        ref={handleOnEditorMapRefSet}
      />

      {isHighlighted &&
        doc &&
        createPortal(
          <ScreenDimmer
            opacity={0.5}
            zIndex={999}
            onClick={() => dispatch(endAddMarkerWorkflowAction({ successful: false }))}
          />,
          doc
        )}
      {isHighlighted && doc && (
        <div className=" bg-ev-navy-blue-2 text-white z-[10002] p-2 mt-2">
          <div className=" text-white">{highlightMessage}</div>
        </div>
      )}
    </div>
  );
};

export default LocationMapPreview;
