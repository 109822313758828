import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleUnpublishModalAction } from 'redux/actions/projectConfig';
import { RootState } from 'redux/store';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor, ButtonType } from 'shared/buttons/types/ButtonModels';
import CopyInput from 'shared/inputs/CopyInput';
import CustomSelect, { SelectItem } from 'shared/inputs/CustomSelect';
import { editorStyles } from 'shared/utils/selectStylesHelper';
import { liveVideoOptions } from 'shared/wizard/meta/VideoOptions';

type LiveVideoProps = {
  onPublish?: () => void;
};

const LiveVideoChannel = (props: LiveVideoProps) => {
  const { onPublish } = props;
  const dispatch = useDispatch();
  const [liveVideoFormat, setLiveVideoFormat] = React.useState<SelectItem>(liveVideoOptions[0].item);
  const { published, publishLoading, embedDetails, inPackagesMode } = useSelector(
    (state: RootState) => state.projectConfig
  );
  const embed = embedDetails?.iframeURL || 'https://api.everviz.com/embed/';

  const onUnpublish = () => dispatch(toggleUnpublishModalAction(true));
  return (
    <>
      <p className="text-sm mb-4 text-black">Publish project to generate an embed code for your CMS.</p>
      {onPublish && !!published && !inPackagesMode && (
        <PrimaryButton
          text="Unpublish"
          onClick={onUnpublish}
          buttonColor={ButtonColor.ErrorRed}
          buttonType={ButtonType.Info}
        />
      )}
      {onPublish && !published && !inPackagesMode && (
        <PrimaryButton
          text="Publish now"
          useLoader={{
            default: {
              text: 'Publish'
            },
            loading: {
              text: 'Publish',
              check: publishLoading
            }
          }}
          onClick={props.onPublish}
          buttonColor={ButtonColor.NavyBlue}
          buttonType={ButtonType.Info}
        />
      )}
      <CustomSelect
        label="Format"
        options={Object.values(liveVideoOptions).map((opt) => opt.item)}
        onChange={(selected) => setLiveVideoFormat(selected as SelectItem)}
        styles={editorStyles}
        value={liveVideoFormat}
        containerClasses="mt-6"
      />
      <div className={`mt-4 ${!published ? 'opacity-60 pointer-events-none' : ''}`}>
        <CopyInput value={embed} tabIndex={published ? 0 : -1} className="w-full" />
      </div>
    </>
  );
};

export default LiveVideoChannel;
