import Templates from 'pages/ChartEditorPage/meta/templates/Templates';

export const getDataForGoogleErrors = (error: string) => {
  switch (error) {
    case 'nonPublic':
      return {
        link: 'https://help.everviz.com/article/900-google-sheet-settings/',
        text: 'The sheet needs to be publically available.'
      };
    case 'badUrl':
      return {
        link: 'https://help.everviz.com/article/802-google-spreadsheet-import',
        text: 'Please enter a valid spreadsheet URL.'
      };
  }
};

export const shouldDisplayDataServerOptionForType = (type: keyof typeof Templates) => {
  return type === 'Line' || type === 'Bar' || type === 'Column' || type === 'Stock';
};
