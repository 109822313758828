import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfilePageContainer from 'shared/profile/ProfilePageContainer';
import ProfilePageSectionContainer from 'shared/profile/ProfilePageSectionContainer';
import { setAction as setProjectConfigAction } from 'redux/actions/projectConfig';
import { getTeamPackagesAction } from './actions/packagesPage';
import { RootState } from 'redux/store';
import { isArray } from 'lodash';
import { Package } from './types';
import PackageSection from './components/PackageSection';

const PackagesPage = () => {
  const dispatch = useDispatch();
  const { packages } = useSelector((state: RootState) => state.packagesPage);

  useEffect(() => {
    dispatch(getTeamPackagesAction());

    dispatch(
      setProjectConfigAction({
        inPackagesMode: true
      })
    );
  }, []);

  return (
    <ProfilePageContainer
      title="Packages"
      header={'Packages'}
      description={'The packages page features ready-made projects designed for various major events.'}
    >
      <ProfilePageSectionContainer>
        {isArray(packages) &&
          packages.map((packageDetails: Package, index) => {
            return (
              <PackageSection
                packageDetails={packageDetails}
                index={index}
                key={`package-${packageDetails.uuid}-${index}`}
              />
            );
          })}

        {!isArray(packages) && (
          <div>
            You have no package. Email&nbsp;
            <a title="email sales" href="mailto:sales@everviz.com">
              sales@everviz.com
            </a>
            &nbsp;for more information
          </div>
        )}
      </ProfilePageSectionContainer>
    </ProfilePageContainer>
  );
};

export default PackagesPage;
