import { SubscriptionPlans } from '../../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../../types';

const Heatmap: TemplateProps = {
  title: 'Heatmap',
  description: '',
  constructor: '',
  thumbnail: 'heatmap.png',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'heatmap'
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        lineWidth: 0,
        minSize: 4,
        maxSize: 60,
        marker: {
          enabled: true
        }
      }
    },
    tooltip: {
      pointFormat: '{point.xLabel}, {point.yLabel}: {point.value}'
    },
    colorAxis: {
      minColor: '#FFFFFF',
      maxColor: '#FF495E',
      stops: [
        [0, '#FFFFFF'],
        [1, '#FF495E']
      ]
    }
  },
  plugins: ['heatmap'],
  dataValidator: false,
  advancedParent: 'Heatmap',
  sampleSet: 'heatmap',
  basic: {
    order: 28,
    thumbnail: 'heat_map.svg',
    icon: 'Heatmap'
  },
  parseData: 'heatmap',
  parent: 'More',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Pro)
};

export default Heatmap;
