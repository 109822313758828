import { checkSeriesAxisCompat } from 'pages/ChartEditorPage/meta/CustomizeOptions';
import {
  customAxisDashStyleOptions,
  handleXAxisGridLineWidthChange,
  handleYAxisGridLineWidthChange,
  handleXAxisGridLineStyleSelection,
  handleYAxisGridLineStyleSelection,
  handleSymbol
} from '../utils/customizeEditorHelper';

export default {
  //################### FONT ###########################
  'option.text.font': [
    {
      id: 'font',
      pid: 'option.text.font',
      inputClassName: 'bg-ev-grey text-ev-navy-blue-4',
      options: [
        {
          // Theme font family
          id: 'chart.style',
          pid: 'font.globalFont',
          aliasKey: [
            'highcharts.all.chart.style.fontFamily',
            'everviz.all.title.style.fontFamily',
            'everviz.all.subtitle.style.fontFamily',
            'everviz.all.caption.style.fontFamily',
            'table.all.header.style.fontFamily',
            'table.all.table.style.fontFamily'
          ],
          nestedValue: ['chart.style', 'title.style.fontFamily'],
          type: 'fontpicker',
          backgroundColor: '#f7f8f8',
          simple: true
        },
        {
          //Global text color
          id: [
            'subtitle.style.color',
            'title.style.color',
            'caption.style.color',
            'yAxis.title.style.color',
            'yAxis.labels.style.color',
            'xAxis.title.style.color',
            'xAxis.labels.style.color',
            'credits.style.color',
            'legend.itemStyle.color',
            'colorAxis.labels.style.color',
            'pagination.text.style.color',
            'plotOptions.series.dataLabels.style.color'
          ],
          aliasKey: [
            'all.subtitle.style.color',
            'all.title.style.color',
            'all.caption.style.color',
            'all.legend.itemStyle.color',

            'chart.all.yAxis.title.style.color',
            'chart.all.yAxis.labels.style.color',
            'chart.all.xAxis.title.style.color',
            'chart.all.xAxis.labels.style.color',
            'chart.all.plotOptions.series.dataLabels.style.color',

            'all.credits.style.color',

            'everviz.all.subtitle.style.color',
            'everviz.all.title.style.color',
            'everviz.all.caption.style.color',
            'everviz.all.credits.style.color',
            'table.all.rows.textColor',
            'table.all.table.style.color',
            'table.all.header.style.color',
            'map.all.colorAxis.labels.style.color',
            'all.table.pagination.text.style.color',
            'table.all.table.searchable.style.color'
          ],
          pid: 'textStyle.globalTextColor',
          type: 'color',
          default: '#000000'
        },
        {
          id: 'everviz.text.dynamicFonts',
          pid: 'everviz.text.dynamicFonts',
          aliasKey: 'highcharts.all.everviz.text.dynamicFonts',
          type: 'checkbox',
          default: false,
          projectType: ['chart', 'map']
        }
      ]
    }
  ],
  //################### COLOR PALETTE ###########################
  'option.text.colorPalette': [
    {
      id: 'colorPalette',
      pid: 'option.text.colorPalette',
      projectType: ['chart'],
      options: [
        {
          // Theme color palette
          id: 'colors',
          aliasKey: ['chart.all.colors', 'map.all.everviz.colors'],
          pid: 'colorPalette',
          type: 'array<color>',
          custom: {
            showAsRows: true,
            isSortable: true,
            defaultItemValue: '#000000'
          }
        }
      ]
    }
  ],
  //################### TABLE SPECIFIC ###########################
  'option.text.tableSpecific': [
    {
      id: 'tableSpecific',
      pid: 'option.text.tableSpecific',
      projectType: ['table'],
      inputClassName: 'bg-ev-grey text-ev-navy-blue-4',
      options: [
        {
          pid: 'header.style.backgroundColor',
          id: 'header.style.backgroundColor',
          aliasKey: ['table.all.header.style.backgroundColor'],
          type: 'color',
          default: 'transparent'
        },
        {
          pid: 'table.style.backgroundColor',
          id: 'table.style.backgroundColor',
          type: 'color',
          aliasKey: ['table.all.table.style.backgroundColor'],
          default: 'transparent'
        },
        {
          pid: 'rows.stripesColor',
          aliasKey: ['table.all.rows.stripesColor', 'table.all.table.extra.style.backgroundColor'],
          id: 'rows.stripesColor',
          type: 'color'
        },
        {
          pid: 'rows.textColor',
          aliasKey: ['table.all.rows.textColor'],
          id: 'rows.textColor',
          type: 'color',
          default: '#000000'
        }
      ]
    }
  ],

  //################### MAP SPECIFIC ###########################
  'option.text.mapSpecific': [
    {
      id: 'colorPalette',
      pid: 'option.text.colorPalette',
      projectType: ['map'],
      inputClassName: 'bg-ev-grey text-ev-navy-blue-4',
      options: [
        {
          // Theme color palette
          id: 'everviz.colors',
          aliasKey: ['chart.all.colors', 'map.all.everviz.colors'],
          pid: 'colorPalette',
          type: 'array<color>',
          custom: {
            showAsRows: true,
            isSortable: true
          }
        },
        {
          pid: 'plotOptions.map.nullColor',
          id: 'plotOptions.map.nullColor',
          type: 'color',
          aliasKey: ['map.all.plotOptions.map.nullColor'],
          default: 'transparent'
        }
      ]
    }
  ],
  //################### TEXT STYLE ###########################
  'option.text.textStyle': [
    {
      id: 'textStyle',
      pid: 'option.text.textStyle',
      options: [
        {
          //Title text
          id: 'title.text',
          pid: 'textStyle.title',
          type: 'richtextgroup',
          default: {
            text: 'Chart title'
          },
          richtext: {
            rawId: 'title'
          },
          inline: {
            highchartsId: 'title.alignOptions',
            source: '.highcharts-container .highcharts-title'
          },
          nestedValue: ['chart.style.fontFamily'], // Currently only used in fontpicker widget
          aliasKey: 'all.title.text'
        },
        {
          // Subtitle text
          id: 'subtitle.text',
          pid: 'textStyle.subtitle',
          type: 'richtextgroup',
          richtext: {
            rawId: 'subtitle'
          },
          inline: {
            highchartsId: 'subtitle.alignOptions',
            source: '.highcharts-container .highcharts-subtitle'
          },
          nestedValue: ['chart.style.fontFamily'],
          aliasKey: 'all.subtitle.text'
        },
        {
          // Caption text
          id: 'caption.text',
          pid: 'textStyle.caption',
          type: 'richtextgroup',
          richtext: {
            rawId: 'caption'
          },
          nestedValue: ['chart.style.fontFamily'],
          inline: {
            highchartsId: 'caption.alignOptions',
            source: '.highcharts-container .highcharts-caption'
          },
          className: 'caption-text bg-ev-grey',
          aliasKey: 'all.caption.text'
        },
        {
          pid: 'textStyle.searchable.labelText',
          id: 'table.searchable.labelText',
          default: 'Search in table',
          inputClassName: 'bg-ev-grey',
          className: 'bg-white',
          type: 'string',
          projectType: ['table'],
          aliasKey: 'table.all.table.searchable.labelText'
        },
        {
          // Legend placement
          id: 'legend.align',
          pid: 'textStyle.legendPlacement',
          type: 'placementalignwidget',
          default: 'topLeft',
          aliasKey: ['highcharts.all.legend.align'],
          projectType: ['chart', 'map']
        },
        {
          id: 'xAxis[0].title.text',
          pid: 'textStyle.xAxis.title',
          type: 'richtextgroup',
          projectType: ['chart'],
          richtext: {
            rawId: 'xAxis.title'
          },
          mapDisabled: true,
          blacklist: checkSeriesAxisCompat,
          inline: {
            highchartsId: ['xAxis[0].axisTitle.styles', 'xAxis[0].axisTitle.textStr'],
            source: '.highcharts-container .highcharts-xaxis .highcharts-axis-title',
            isAxis: true
          },
          nestedValue: ['chart.style.fontFamily'],
          aliasKey: 'chart.all.xAxis.title.text'
        },
        {
          id: 'yAxis[0].title.text',
          pid: 'textStyle.yAxis.title',
          type: 'richtextgroup',
          projectType: ['chart'],
          richtext: {
            rawId: 'yAxis.title'
          },
          mapDisabled: true,
          blacklist: checkSeriesAxisCompat,
          inline: {
            highchartsId: ['yAxis[0].axisTitle.styles', 'yAxis[0].axisTitle.textStr'],
            source: '.highcharts-container .highcharts-yaxis .highcharts-axis-title',
            isAxis: true
          },
          nestedValue: ['chart.style.fontFamily'],
          aliasKey: 'chart.all.yAxis.title.text'
        }
      ]
    }
  ],
  //################### CANVAS ###########################
  'option.text.canvas': [
    {
      id: 'canvas',
      pid: 'option.text.canvas',
      inputClassName: 'bg-ev-grey text-ev-navy-blue-4',
      options: [
        {
          id: 'chart.backgroundColor',
          pid: 'canvas.backgroundColor',
          type: 'color',
          default: '#ffffff',
          aliasKey: [
            'highcharts.all.chart.backgroundColor',
            'everviz.all.canvas.style.backgroundColor',
            'everviz.all.title.style.backgroundColor',
            'everviz.all.subtitle.style.backgroundColor',
            'everviz.all.caption.style.backgroundColor'
          ],
          nestedValue: ['chart.backgroundColor', 'title.style.backgroundColor']
        },
        {
          id: 'chart.borderColor',
          pid: 'canvas.borderColor',
          type: 'color',
          default: '#ffffff',
          aliasKey: 'all.chart.borderColor',
          projectType: ['chart', 'map'],
          custom: {
            showGradientColorTab: true
          }
        },
        {
          id: 'chart.borderWidth',
          pid: 'canvas.border',
          type: 'number',
          default: '0',
          aliasKey: 'all.chart.borderWidth',
          projectType: ['chart', 'map']
        }
      ]
    }
  ],
  //################### AXIS #############################
  'option.cat.axes': [
    {
      id: 'axis',
      pid: 'option.cat.axes',
      projectType: ['chart'],
      options: [
        {
          type: 'accordion',
          text: 'option.subcat.xAxis',
          pid: 'option.subcat.xAxis',
          inputClassName: 'bg-white text-ev-navy-blue-4',
          options: [
            {
              id: 'xAxis[0].visible',
              pid: 'xAxis.enabled',
              type: 'checkbox',
              default: true,
              aliasKey: ['chart.all.xAxis.visible'],
              isOptionalArray: true
            },
            {
              id: 'xAxis[0].lineWidth',
              pid: 'xAxis.lineWidth',
              type: 'number',
              default: '1',
              aliasKey: ['chart.all.xAxis.lineWidth'],
              custom: {
                minValue: 0,
                maxValue: 10
              },
              isOptionalArray: true
            },
            {
              id: 'xAxis[0].lineColor',
              pid: 'xAxis.lineColor',
              type: 'color',
              default: '#333333',
              aliasKey: ['chart.all.xAxis.lineColor', 'chart.all.xAxis.tickColor', 'chart.all.xAxis.gridLineColor'],
              isOptionalArray: true
            },
            {
              id: 'xAxis[0].tickWidth',
              pid: 'xAxis.tickWidth',
              type: 'checkbox',
              default: true,
              aliasKey: ['chart.all.xAxis.tickWidth'],
              isOptionalArray: true
            },
            {
              id: 'xAxis[0].labels.style',
              type: 'fontstyler',
              pid: 'xAxis.labels.style',
              aliasKey: ['chart.all.xAxis.labels.style'],
              default: { color: '#666666', cursor: 'pointer', fontSize: '11px' },
              custom: {
                fields: {
                  bold: 1,
                  italic: 1,
                  color: 1,
                  fontSize: 1
                }
              },
              isOptionalArray: true
            },
            {
              id: 'xAxis[0].gridLineDashStyle',
              pid: 'xAxis.gridLineDashStyle',
              type: 'select<string>',
              values: customAxisDashStyleOptions,
              default: 'None',
              aliasKey: ['chart.all.xAxis.gridLineDashStyle'],
              onOptionsChange: handleXAxisGridLineStyleSelection,
              backgroundColor: 'white',
              isOptionalArray: true
            },
            {
              id: 'xAxis[0].gridLineWidth',
              pid: 'xAxis.gridLineWidth',
              type: 'number',
              default: '0',
              aliasKey: ['chart.all.xAxis.gridLineWidth'],
              onOptionsChange: handleXAxisGridLineWidthChange,
              custom: {
                minValue: 0,
                maxValue: 10
              },
              isOptionalArray: true
            }
          ]
        },
        {
          type: 'accordion',
          text: 'option.subcat.yAxis',
          pid: 'option.subcat.yAxis',
          inputClassName: 'bg-white text-ev-navy-blue-4',
          options: [
            {
              id: 'yAxis[0].visible',
              pid: 'yAxis.enabled',
              type: 'checkbox',
              default: true,
              aliasKey: ['chart.all.yAxis.visible'],
              isOptionalArray: true
            },
            {
              id: 'yAxis[0].lineWidth',
              pid: 'yAxis.lineWidth',
              type: 'number',
              default: '0',
              aliasKey: ['chart.all.yAxis.lineWidth'],
              custom: {
                minValue: 0,
                maxValue: 10
              },
              isOptionalArray: true
            },
            {
              id: 'yAxis[0].lineColor',
              pid: 'yAxis.lineColor',
              type: 'color',
              default: '#333333',
              aliasKey: ['chart.all.yAxis.lineColor', 'chart.all.yAxis.tickColor', 'chart.all.yAxis.gridLineColor'],
              isOptionalArray: true
            },
            {
              id: 'yAxis[0].tickWidth',
              pid: 'yAxis.tickWidth',
              type: 'checkbox',
              default: false,
              aliasKey: ['chart.all.yAxis.tickWidth'],
              isOptionalArray: true
            },
            {
              id: 'yAxis[0].labels.style',
              type: 'fontstyler',
              pid: 'yAxis.labels.style',
              aliasKey: ['chart.all.yAxis.labels.style'],
              default: { color: '#666666', cursor: 'pointer', fontSize: '11px' },
              custom: {
                fields: {
                  bold: 1,
                  italic: 1,
                  color: 1,
                  fontSize: 1
                }
              },
              isOptionalArray: true
            },
            {
              id: 'yAxis[0].gridLineDashStyle',
              pid: 'yAxis.gridLineDashStyle',
              type: 'select<string>',
              values: customAxisDashStyleOptions,
              default: 'Solid',
              aliasKey: ['chart.all.yAxis.gridLineDashStyle'],
              onOptionsChange: handleYAxisGridLineStyleSelection,
              backgroundColor: 'white',
              isOptionalArray: true
            },
            {
              id: 'yAxis[0].gridLineWidth',
              pid: 'yAxis.gridLineWidth',
              type: 'number',
              default: '1',
              aliasKey: ['chart.all.yAxis.gridLineWidth'],
              onOptionsChange: handleYAxisGridLineWidthChange,
              custom: {
                minValue: 0,
                maxValue: 10
              },
              isOptionalArray: true
            }
          ]
        }
      ]
    }
  ],
  //################ EXPORT MENU #######################
  'option.text.exporting': [
    {
      id: 'exporting',
      pid: 'option.text.exporting',
      projectType: ['table'],
      options: [
        {
          id: 'exporting.buttons.contextButton.enabled',
          pid: 'exporting.buttons.contextButton.enabled',
          type: 'checkbox',
          default: true,
          aliasKey: ['all.exporting.buttons.contextButton.enabled'],
          postFields: [
            {
              options: [
                {
                  optionNeeded: [true],
                  className: 'bg-white',
                  id: 'exporting.buttons.contextButton.text',
                  pid: 'exporting.buttons.contextButton.text',
                  type: 'string',
                  aliasKey: ['all.exporting.buttons.contextButton.text']
                }
              ]
            },
            {
              optionNeeded: [true],
              className: 'bg-white',
              options: [
                {
                  id: 'exporting.buttons.contextButton.symbol',
                  pid: 'exporting.buttons.contextButton.symbol',
                  type: 'checkbox',
                  onOptionsChange: handleSymbol,
                  aliasKey: ['all.exporting.buttons.contextButton.symbol'],
                  refersTo: {
                    all: {
                      true: {
                        set: {
                          'exporting.buttons.contextButton.symbol': 'menu'
                        }
                      },
                      false: {
                        set: {
                          'exporting.buttons.contextButton.symbol': null
                        }
                      }
                    }
                  }
                }
              ]
            },
            {
              optionNeeded: [true],
              className: 'bg-white',
              options: [
                {
                  pid: 'exporting.style.backgroundColor',
                  id: 'exporting.style.backgroundColor',
                  aliasKey: ['all.exporting.style.backgroundColor'],
                  type: 'color',
                  default: '#F7F8F8'
                }
              ]
            },
            {
              optionNeeded: [true],
              className: 'bg-white',
              options: [
                {
                  pid: 'exporting.style.color',
                  id: 'exporting.style.color',
                  aliasKey: ['all.exporting.style.color'],
                  type: 'color',
                  default: '#28277E'
                }
              ]
            },
            {
              id: 'exporting.localization',
              pid: 'exporting.localization',
              type: 'accordion',
              className: 'bg-white',
              optionNeeded: [true],
              options: [
                {
                  id: 'exporting.lang.contextButtonTitle',
                  pid: 'exporting.lang.contextButtonTitle',
                  aliasKey: [
                    'all.exporting.lang.contextButtonTitle',
                    'chart.all.lang.contextButtonTitle',
                    'map.all.lang.contextButtonTitle'
                  ],
                  type: 'string',
                  default: 'Table context menu',
                  inputClassName: 'bg-ev-grey text-ev-navy-blue-4'
                },
                {
                  id: 'exporting.lang.downloadPNG',
                  pid: 'exporting.lang.downloadPNG',
                  aliasKey: ['all.exporting.lang.downloadPNG', 'all.lang.downloadPNG'],
                  type: 'string',
                  default: 'Download PNG image',
                  inputClassName: 'bg-ev-grey text-ev-navy-blue-4'
                },
                {
                  id: 'exporting.lang.downloadJPEG',
                  pid: 'exporting.lang.downloadJPEG',
                  aliasKey: ['all.exporting.lang.downloadJPEG', 'all.lang.downloadJPEG'],
                  type: 'string',
                  default: 'Download JPEG image',
                  inputClassName: 'bg-ev-grey text-ev-navy-blue-4'
                },
                {
                  id: 'exporting.lang.downloadPDF',
                  pid: 'exporting.lang.downloadPDF',
                  aliasKey: ['all.exporting.lang.downloadPDF', 'all.lang.downloadPDF'],
                  type: 'string',
                  default: 'Download PDF document',
                  inputClassName: 'bg-ev-grey text-ev-navy-blue-4'
                },
                {
                  id: 'exporting.lang.downloadCSV',
                  pid: 'exporting.lang.downloadCSV',
                  aliasKey: ['all.exporting.lang.downloadCSV', 'all.lang.downloadCSV'],
                  type: 'string',
                  default: 'Download CSV',
                  inputClassName: 'bg-ev-grey text-ev-navy-blue-4'
                },
                {
                  id: 'exporting.lang.downloadXLS',
                  pid: 'exporting.lang.downloadXLS',
                  aliasKey: ['all.exporting.lang.downloadXLS', 'all.lang.downloadXLS'],
                  type: 'string',
                  default: 'Download XLS',
                  inputClassName: 'bg-ev-grey text-ev-navy-blue-4'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 'exporting',
      pid: 'option.text.exporting',
      projectType: ['chart', 'map'],
      options: [
        {
          id: 'exporting.buttons.contextButton.enabled',
          pid: 'exporting.buttons.contextButton.enabled',
          type: 'checkbox',
          default: true,
          aliasKey: ['all.exporting.buttons.contextButton.enabled'],
          postFields: [
            {
              optionNeeded: [true],
              className: 'context-button-text',
              options: [
                {
                  id: 'exporting.buttons.contextButton.text',
                  pid: 'exporting.buttons.contextButton.text',
                  type: 'string',
                  inputClassName: 'bg-white',
                  aliasKey: ['all.exporting.buttons.contextButton.text']
                }
              ]
            },
            {
              optionNeeded: [true],
              className: 'bg-white',
              options: [
                {
                  id: 'exporting.buttons.contextButton.symbol',
                  pid: 'exporting.buttons.contextButton.symbol',
                  type: 'checkbox',
                  onOptionsChange: handleSymbol,
                  aliasKey: ['all.exporting.buttons.contextButton.symbol'],
                  refersTo: {
                    all: {
                      true: {
                        set: {
                          'exporting.buttons.contextButton.symbol': 'menu'
                        }
                      },
                      false: {
                        set: {
                          'exporting.buttons.contextButton.symbol': null
                        }
                      }
                    }
                  }
                }
              ]
            },
            {
              id: 'exporting.localization',
              pid: 'exporting.localization',
              type: 'accordion',
              optionNeeded: [true],
              className: 'bg-white',
              inputClassName: 'bg-ev-grey text-ev-navy-blue-4',
              options: [
                {
                  id: 'exporting.lang.contextButtonTitle',
                  pid: 'exporting.lang.contextButtonTitle',
                  aliasKey: [
                    'all.exporting.lang.contextButtonTitle',
                    'chart.all.lang.contextButtonTitle',
                    'map.all.lang.contextButtonTitle'
                  ],
                  type: 'string',
                  default: 'Table context menu',
                  inputClassName: 'bg-ev-grey text-ev-navy-blue-4'
                },
                {
                  id: 'lang.viewFullscreen',
                  pid: 'lang.viewFullscreen',
                  aliasKey: ['all.lang.viewFullscreen'],
                  type: 'string',
                  default: 'View in full screen',
                  inputClassName: 'bg-ev-grey text-ev-navy-blue-4'
                },
                {
                  id: 'lang.printChart',
                  pid: 'lang.printChart',
                  aliasKey: ['all.lang.printChart'],
                  type: 'string',
                  default: 'Print chart',
                  inputClassName: 'bg-ev-grey text-ev-navy-blue-4'
                },
                {
                  id: 'exporting.lang.downloadPNG',
                  pid: 'exporting.lang.downloadPNG',
                  aliasKey: ['all.exporting.lang.downloadPNG', 'all.lang.downloadPNG'],
                  type: 'string',
                  default: 'Download PNG image',
                  inputClassName: 'bg-ev-grey text-ev-navy-blue-4'
                },
                {
                  id: 'exporting.lang.downloadJPEG',
                  pid: 'exporting.lang.downloadJPEG',
                  aliasKey: ['all.exporting.lang.downloadJPEG', 'all.lang.downloadJPEG'],
                  type: 'string',
                  default: 'Download JPEG image',
                  inputClassName: 'bg-ev-grey text-ev-navy-blue-4'
                },
                {
                  id: 'exporting.lang.downloadPDF',
                  pid: 'exporting.lang.downloadPDF',
                  aliasKey: ['all.exporting.lang.downloadPDF', 'all.lang.downloadPDF'],
                  type: 'string',
                  default: 'Download PDF document',
                  inputClassName: 'bg-ev-grey text-ev-navy-blue-4'
                },
                {
                  id: 'exporting.lang.downloadCSV',
                  pid: 'exporting.lang.downloadCSV',
                  aliasKey: ['all.exporting.lang.downloadCSV', 'all.lang.downloadCSV'],
                  type: 'string',
                  default: 'Download CSV 111',
                  inputClassName: 'bg-ev-grey text-ev-navy-blue-4'
                },
                {
                  id: 'exporting.lang.downloadXLS',
                  pid: 'exporting.lang.downloadXLS',
                  aliasKey: ['all.exporting.lang.downloadXLS', 'all.lang.downloadXLS'],
                  type: 'string',
                  default: 'Download XLS',
                  inputClassName: 'bg-ev-grey text-ev-navy-blue-4'
                },
                {
                  id: 'lang.downloadSVG',
                  pid: 'lang.downloadSVG',
                  aliasKey: ['all.lang.downloadSVG'],
                  type: 'string',
                  default: 'Download SVG vector image',
                  inputClassName: 'bg-ev-grey text-ev-navy-blue-4'
                },
                {
                  id: 'lang.viewData',
                  pid: 'lang.viewData',
                  aliasKey: ['all.lang.viewData'],
                  type: 'string',
                  default: 'View data table',
                  inputClassName: 'bg-ev-grey text-ev-navy-blue-4'
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
