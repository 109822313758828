import { snackBar } from 'editor/editors/highed.init';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  delTeamChartUsingTeamidAndChartid,
  getChartsTagsWithTeamid,
  getDivisionChartsDeletedWithDivisionid,
  getDivisionChartsSearchWithDivisionid,
  getDivisionChartsWithDivisionid,
  getTeamChartsDeletedWithTeamid,
  getTeamChartsSearchWithTeamid,
  getTeamChartsWithTeamid,
  getTeamUsersWithTeamid,
  postTeamChartDupetothemeUsingTeamidAndChartid,
  postTeamChartDuplicateUsingTeamidAndChartid,
  postTeamChartmetaUsingTeamidAndChartid,
  postTeamChartRecoverUsingTeamidAndChartid,
  postTeamChartTothemeUsingTeamidAndChartid,
  postTeamStoryDuplicateUsingTeamidAndStoryid,
  postTeamTableDuplicateUsingTeamidAndTableid,
  delTeamTagUsingTeamidAndTagid
} from '../../../api/cloudApiGenerated';
import actionTypes from '../../../redux/actions/action-types';
import { setAction as setProfileAction } from '../../../redux/actions/profile';
import { getProfileConfig } from '../../../redux/selectors/profile';
import { getChartsAction, setAction, getTagsAction, setSelectFilterAction } from '../actions/projectsPage';

export function* getCharts() {
  const { page, pageSize } = yield select((state) => state.projectsPage);
  const { division, team } = yield select((state) => state.profile);
  yield put(
    setAction({
      page,
      pageSize,
      charts: [null]
    })
  );
  let { query, selectCreatorValue, selectSortValue, selectValue } = yield select((state) => state.projectsPage);

  const mapQueryToEndpoint = function () {
    selectValue = (selectValue || []).map((a) => a.value);
    let isValidSearch = query?.length > 0 || selectValue.length !== 0 || selectCreatorValue !== undefined;
    const orderBy = [
      [0, 'latest'],
      [1, 'created'],
      [2, 'name']
    ].find((x) => x[0] === selectSortValue.value)[1];
    const passedParams = [query, selectValue.toString(), selectCreatorValue?.id ?? '', orderBy, page, pageSize];

    // Event was dispatched from deleted projects page
    if (!window.location.pathname.includes('profile/charts/deleted')) {
      if (division?.id !== null) {
        return isValidSearch
          ? getDivisionChartsSearchWithDivisionid(division.id, ...passedParams)
          : getDivisionChartsWithDivisionid(division.id, orderBy, page, pageSize);
      }
      return isValidSearch
        ? getTeamChartsSearchWithTeamid(team.id, ...passedParams)
        : getTeamChartsWithTeamid(team.id, orderBy, page, pageSize);
    }

    if (division?.id !== null) {
      return getDivisionChartsDeletedWithDivisionid(division.id, page, pageSize);
    }
    return getTeamChartsDeletedWithTeamid(team.id, page, pageSize);
  };

  const response = yield call(mapQueryToEndpoint);

  if (response.data) {
    //Remove html tags from old titles
    response.data.forEach((element) => (element.name = (element.name ?? '').replace(/<[^>]+>/g, '')));

    yield put(
      setAction({
        page,
        pageSize,
        charts: response.data,
        pageCount: response.pageCount
      })
    );
  }
}

export function* getTags() {
  let team = yield select((state) => state.profile.team);
  const data = yield call(getChartsTagsWithTeamid, team.id);
  yield put(
    setAction({
      tags: data.data
    })
  );
}

export function* deleteTag(params) {
  const { team, tag } = params.data;
  try {
    const { selectValue } = yield select((state) => state.projectsPage);
    const filteredTags = selectValue.filter((obj) => obj.value !== tag.value);

    yield call(delTeamTagUsingTeamidAndTagid, team.id, tag.value);
    yield put(getTagsAction());
    yield put(setSelectFilterAction({ selectValue: filteredTags }));
    yield put(getChartsAction());
    snackBar('Tag deleted');
  } catch (error) {
    yield put(setAction({ error: 'deleting tag: ' + error.toString() }));
  }
}

export function* setChartMeta(params) {
  const { team, chart, data } = params.data;
  data.tags = (data?.tags ?? []).map((tag) => ({ id: tag.value, name: tag.label }));
  const currentCharts = yield select((state) => state.projectsPage.charts);
  yield put(
    setAction({
      charts: currentCharts.map((x) => (x.id === chart.id ? { ...x, ...data } : x))
    })
  );
  yield call(postTeamChartmetaUsingTeamidAndChartid, team.id, chart.id, { ...data, division: data.division.id });
  yield call(getTags, { data: { team } });
  yield put(getChartsAction());
}

export function* getCreators() {
  let team = yield select((state) => state.profile.team);

  try {
    const teamUsers = yield call(getTeamUsersWithTeamid, team.id, '');
    const creators = teamUsers.data.map((creator) => ({
      ...creator,
      value: creator.id,
      label: creator.username
    }));
    yield put(
      setAction({
        creators
      })
    );
  } catch (error) {
    //TODO do something with the error message
  }
}

export function* deleteChart(params) {
  const { team, chart } = params.data;
  const config = yield select(getProfileConfig);
  try {
    yield call(delTeamChartUsingTeamidAndChartid, team.id, chart.id);
    chart.deleted = true;
    yield put(
      setAction({
        chart
      })
    );
    yield put(
      setProfileAction({
        chartsCreated: config.chartsCreated - 1
      })
    );
    snackBar('Chart deleted');
    yield put(getChartsAction());
  } catch (error) {
    yield put(
      setAction({
        error: 'deleting chart: ' + error.toString()
      })
    );
  }
}

export function* recoverChart(params) {
  const { team, chart } = params.data;
  const config = yield select(getProfileConfig);

  try {
    yield call(postTeamChartRecoverUsingTeamidAndChartid, team.id, chart.id);
    chart.deleted = false;
    yield put(
      setAction({
        chart
      })
    );

    yield put(
      setProfileAction({
        chartsCreated: config.chartsCreated + 1
      })
    );

    snackBar('Chart recovered');
    yield put(getChartsAction());
  } catch (error) {
    if (error && error.response && error.response.message && error.response.message[0] === 'Could not create chart') {
      snackBar('Sorry, you have run out of projects');
    } else {
      yield put(
        setAction({
          error: 'recovering chart: ' + error.toString()
        })
      );
    }
  }
}

export function* convertToTheme(params) {
  const { team, chart } = params.data;

  //this.props.setLoading(true);
  try {
    yield call(postTeamChartTothemeUsingTeamidAndChartid, team.id, chart.id);
    //this.props.setLoading(false);
    snackBar('Converted to theme');
    yield put(getChartsAction());
  } catch (error) {
    yield put(
      setAction({
        error: 'converting chart: ' + error.toString()
      })
    );
  }
}

export function* duplicateToTheme(params) {
  const { team, chart } = params.data;

  //this.props.setLoading(true);
  try {
    yield call(postTeamChartDupetothemeUsingTeamidAndChartid, team.id, chart.id);
    //this.props.setLoading(false);
    snackBar('Duplicated to theme');
    yield put(getChartsAction());
  } catch (error) {
    yield put(
      setAction({
        error: 'converting chart: ' + error.toString()
      })
    );
  }
}

export function* duplicateChart(params) {
  const { team, chart } = params.data;
  const config = yield select(getProfileConfig);
  //this.props.setLoading(true);
  try {
    if (chart.chart_type === 4) yield call(postTeamStoryDuplicateUsingTeamidAndStoryid, team.id, chart.id, chart);
    else if (chart.chart_type === 5) yield call(postTeamTableDuplicateUsingTeamidAndTableid, team.id, chart.id, chart);
    else yield call(postTeamChartDuplicateUsingTeamidAndChartid, team.id, chart.id);
    //this.props.setLoading(false);

    yield put(
      setProfileAction({
        chartsCreated: config.chartsCreated + 1
      })
    );

    snackBar('Project duplicated');
    yield put(getChartsAction());
  } catch (error) {
    if (error && error.response && error.response.message && error.response.message[0] === 'Could not create chart') {
      snackBar('Sorry, you have run out of projects');
    } else {
      yield put(
        setAction({
          error: 'duplicate project: ' + error.toString()
        })
      );
    }
  }
}

export function pageUpdate() {
  window.scrollTo(0, 0);
  // Not immediately seeing any better ways to go about
  // scrolling the projects view in the general case.
  // It would be ideal to just find the closest scroll context

  // To any degree I'm undecided to which extent
  // `ProjectsView`s should be separated  .
  const $modalContainer = document.querySelector('.modal__container.overflow-y-auto');
  if ($modalContainer) {
    $modalContainer.scrollTo(0, 0);
  }
}

/** Watch functions */
export function* watchSetChartMeta() {
  yield takeEvery(actionTypes.projectsPage.setChartMeta, setChartMeta);
}
export function* watchSetSelectFilter() {
  yield takeEvery(actionTypes.projectsPage.setSelectFilter, getCharts);
}
export function* watchGetCharts() {
  yield takeEvery(actionTypes.projectsPage.getCharts, getCharts);
}
export function* watchGetTags() {
  yield takeEvery(actionTypes.projectsPage.getTags, getTags);
}
export function* watchDeleteTag() {
  yield takeEvery(actionTypes.projectsPage.deleteTag, deleteTag);
}
export function* watchGetCreators() {
  yield takeEvery(actionTypes.projectsPage.getCreators, getCreators);
}
export function* watchDeleteChart() {
  yield takeEvery(actionTypes.projectsPage.deleteChart, deleteChart);
}
export function* watchRecoverChart() {
  yield takeEvery(actionTypes.projectsPage.recoverChart, recoverChart);
}
export function* watchConvertToTheme() {
  yield takeEvery(actionTypes.projectsPage.convertToTheme, convertToTheme);
}
export function* watchDuplicateToTheme() {
  yield takeEvery(actionTypes.projectsPage.duplicateToTheme, duplicateToTheme);
}
export function* watchDuplicateChart() {
  yield takeEvery(actionTypes.projectsPage.duplicateChart, duplicateChart);
}
export function* watchPageUpdate() {
  yield takeEvery(actionTypes.projectsPage.pageUpdate, pageUpdate);
}

export default function* rootSaga() {
  yield all([
    watchGetCharts(),
    watchSetChartMeta(),
    watchSetSelectFilter(),
    watchGetTags(),
    watchDeleteTag(),
    watchGetCreators(),
    watchDeleteChart(),
    watchRecoverChart(),
    watchConvertToTheme(),
    watchDuplicateToTheme(),
    watchDuplicateChart(),
    watchPageUpdate()
  ]);
}
