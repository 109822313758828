import { SubscriptionPlans } from '../../../../../SubscriptionPage/types/plansModels';
import { getLockedForPlans } from '../../../../../SubscriptionPage/utils/plansHelper';
import { TemplateProps } from '../../types';

const PolarArea: TemplateProps = {
  title: 'Polar area',
  description: '',
  constructor: '',
  thumbnail: 'oqajux.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'area',
      polar: true
    }
  },
  sampleSet: 'polar',
  advancedParent: 'Polar area',
  dataValidator: false,
  basic: {
    order: 19,
    thumbnail: 'polar.svg',
    icon: 'PolarArea'
  },
  parent: 'Polar area',
  lockedForPlans: getLockedForPlans(SubscriptionPlans.Pro)
};

export default PolarArea;
