/* eslint-disable @typescript-eslint/no-var-requires, no-process-env */
import { composeWithDevTools } from '@redux-devtools/extension';
import * as Sentry from '@sentry/react';
import { applyMiddleware, createStore } from 'redux';
import middleware, { init } from '../middleware/middleware';
import rootReducer from './reducers/root';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export function initMiddleware() {
  init();
}

const middlewares = [middleware];

// IMPORTANT: Uncomment these lines when @redux-devtools keeps on crashing, or when working with state between redirections

/* if (process.env.NODE_ENV === 'development') {
  const reduxLogger = require('redux-logger');
  const logger = reduxLogger.createLogger({ collapsed: true });
  middlewares.push(logger);
} */

const store = createStore(
  rootReducer,
  composeWithDevTools({ trace: true, traceLimit: 10 })(applyMiddleware(...middlewares), sentryReduxEnhancer)
);

// expose store when run in Cypress
if ((window as any).Cypress) {
  (window as any).store = store;
}

export type RootState = ReturnType<typeof store.getState>;
export default store;
