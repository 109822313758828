import { connect } from 'react-redux';
import { hideGeoJSONModalAction, setGeoJSONMapAction } from '../../../pages/ChartEditorPage/actions/chartEditor.js';
import { setAction } from '../../../pages/LayoutEditorPage/actions/layoutEditor.js';
import GeoJSONModal from '../../../shared/wizard/editor/GeoJSONModal';
import { checkPermission } from '../../../utils/profileHelper';

const mapStateToProps = (state) => {
  return {
    charts: state.projectsPage.charts || [],
    team: state.profile.team,
    division: state.profile.division,
    paths: state.app.paths || {},
    showPublishPopup: state.layoutEditorPage.showPublishPopup,
    showWizard: state.projectConfig.showWizard,
    resolution: state.layoutEditorPage.resolution,
    dataOptions: state.projectConfig.dataOptions,
    templateOptions: state.projectConfig.templateOptions,
    customizedOptions: state.projectConfig.customizedOptions,
    themeOptions: state.projectConfig.themeOptions,
    themes: state.layoutEditorPage.themes,
    showGeoJSONModal: state.chartEditorPage.showGeoJSONModal,
    geoJSONMapData: state.chartEditorPage.geoJSONMapData,
    checkPermission: (permission) => checkPermission(permission, state.profile)
  };
};

const mapDispatchToProps = (dispatch) => ({
  set: (params) => dispatch(setAction(params)),
  hideGeoJSONModalAction: () => dispatch(hideGeoJSONModalAction()),
  setGeoJSONMap: (map, code, name) => dispatch(setGeoJSONMapAction({ map, code, name }))
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GeoJSONModal);
