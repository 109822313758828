import { isArray } from 'lodash';
import {
  addChartSeriesAction,
  deleteChartSeriesAction,
  updateChartSeriesAction
} from 'pages/ChartEditorPage/actions/chartEditor';
import { focusLocationMapMarkerAction } from 'pages/ChartEditorPage/actions/locationMap';
import { ProviderTypes } from 'pages/ChartEditorPage/meta/templates/types';
import {
  addAssignRawOptionAction,
  deleteAssignRawOptionAction,
  updateAssignRawOptionAction
} from 'pages/TableEditorPage/actions/tableEditor';
import { Dispatch } from 'react';
import { Action } from 'redux';
import { setAction } from 'redux/actions/projectConfig';
import { SeriesAssign } from 'shared/types/commonPropTypes';

const disabledChartTypes = [
  'tilemap',
  'pie',
  'item',
  'dependencywheel',
  'sankey',
  'wordcloud',
  'vector',
  'boxplot',
  'timeline',
  'heatmap',
  'packedbubble',
  'funnel',
  'solidgauge',
  'map',
  'treemap'
];

type SeriesEnterType = (dispatch: Dispatch<any>, index: number[], series: number[]) => void;

export const getSeriesType = (aggregatedOptions: any): string[] => {
  return (aggregatedOptions.series ?? []).map((serie: { type?: string }) => {
    if (serie.type) return serie.type;
    if (aggregatedOptions.chart?.type) return aggregatedOptions.chart.type;
    if (aggregatedOptions.chart?.map) return 'map';
    return 'line';
  });
};
export const getAllowProps = (
  aggregatedOptions: Record<string, unknown>,
  isTable?: boolean,
  provider?: ProviderTypes
) => {
  if (isTable) return { allowAddSeries: true, seriesTypes: [] };

  const seriesTypes = getSeriesType(aggregatedOptions);
  let allowAddSeries = true;

  seriesTypes.forEach((type: string) => {
    if (disabledChartTypes.includes(type)) allowAddSeries = false;
  });

  if (provider === 'locationMap') allowAddSeries = false;

  return { allowAddSeries, seriesTypes };
};

export const getIsLiveDataSource = (
  aggregatedOptions: any,
  dataConfig: {
    googleSpreadsheetKey: string;
  }
) => {
  if (dataConfig?.googleSpreadsheetKey) return true;
  return (
    aggregatedOptions.data &&
    (aggregatedOptions.data.csvURL ??
      aggregatedOptions.data.rowsURL ??
      aggregatedOptions.data.columnsURL ??
      aggregatedOptions.data.googleSpreadsheetKey)
  );
};

export const isUsingChartType = (aggregatedOptions: any, type: string) => {
  return (
    aggregatedOptions?.chart?.type === type ||
    (isArray(aggregatedOptions?.series) &&
      aggregatedOptions.series.some((series: { type: string }) => series.type === type))
  );
};

export const getMappedValues = (assignOptions: Record<string, SeriesAssign>, isAdvanced: boolean) => {
  const columns: string[] = [];
  const series: number[] = [];
  Object.values(assignOptions).forEach((value) => {
    if ((value.isData || (isAdvanced && value.isExtra)) && !value.hidden) {
      if (value.rawValue) {
        columns.push(value.value);
        series.push(value.rawValue?.[0] ?? null);
      }
    }
  });
  return { columns, series };
};

let mouseLeaveTimeout: NodeJS.Timeout;

export const onSeriesLeave = (dispatch: Dispatch<any>) => {
  mouseLeaveTimeout = setTimeout(() => {
    dispatch(
      setAction({
        activeColumns: {
          columns: [],
          series: []
        }
      })
    );
  }, 100);
};

export const onSeriesEnter: SeriesEnterType = (dispatch, index, series) => {
  if (mouseLeaveTimeout) clearTimeout(mouseLeaveTimeout);
  dispatch(
    setAction({
      activeColumns: {
        columns: index,
        series: series
      }
    })
  );
};

const onEnterMapMarker: SeriesEnterType = (dispatch, index) => {
  // TODO -> We need a better way of handling the ids for the markers
  dispatch(focusLocationMapMarkerAction({ id: index[0] + 1 }));
};

const onLeaveMapMarker = (dispatch: Dispatch<any>) => {
  dispatch(focusLocationMapMarkerAction({ id: '' }));
};

export const deleteSeriesTypes: Record<string, (data: unknown) => Action> = {
  chart: deleteChartSeriesAction,
  table: deleteAssignRawOptionAction
};

export const addSeriesTypes: Record<string, (data: unknown) => Action> = {
  chart: addChartSeriesAction,
  table: addAssignRawOptionAction
};

export const updateSeriesTypes: Record<string, (data: unknown) => Action> = {
  chart: updateChartSeriesAction,
  table: updateAssignRawOptionAction
};

export const highlightEnterMap: Record<ProviderTypes, SeriesEnterType> = {
  highcharts: onSeriesEnter,
  locationMap: onEnterMapMarker
};

export const highlightLeaveMap: Record<ProviderTypes, (dispatch: Dispatch<any>) => void> = {
  highcharts: onSeriesLeave,
  locationMap: onLeaveMapMarker
};
